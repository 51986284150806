import React, { useState } from 'react';
import Cookies from 'js-cookie'; // Import the js-cookie library
import { Link, useNavigate } from 'react-router-dom';
import { useChipValues } from './ChipValuesContext';

function Footer() {

    const navigate = useNavigate();
    const { updateChipValue } = useChipValues();
    const handleLogout = () => {
        Cookies.remove('userName')
        Cookies.remove('fullName')
        Cookies.remove('id')
        // setUserName('');
        window.location.href ='/login'
        // window.location.reload();
        // navigate('/login');
    }

    const defaultChipValues = {
        Name1: 100,
        Value1: 100,
        Name2: 200,
        Value2: 200,
        Name3: 500,
        Value3: 500,
        Name4: 1000,
        Value4: 1000,
        Name5: 5000,
        Value5: 5000,
        Name6: 10000,
        Value6: 10000,
    };

    const [chipValues, setChipValues] = useState(defaultChipValues);
    // const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event, name) => {
        setChipValues({ ...chipValues, [name]: event.target.value });
        updateChipValue(name, event.target.value);
    };

    const updateStacksbtn = () => {
        // const btnDiv = document.getElementById('button_change')
        // const btn = btnDiv.querySelector('button')
        // //console.log(btn)
        // Your logic to handle the "Update Chip Setting" button click
        // You can access the updated chipValues state here
        // Set loading state to true
        // setIsLoading(true);
        // Display the spinner for a specific duration (in milliseconds)
        $('#success').modal('show');
        $('#successmes').html('Chip Updated')
        setTimeout(() => {
            // setIsLoading(false); // Set loading state back to false after the timeout
            $('#success').modal('hide');
        }, 2000);
        //console.log('Updated Chip Values:', chipValues);
    };

    return (
        <>
            {/* {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>} */}
            {/*footerlinks*/}
            <footer style={{}}>
                <ul className="menu-links">
                    <li className="item">
                        <Link to={"/inPlay"}>
                            <img src="/images/inplay.png" />
                            <span>Inplay</span>                                               </Link>
                    </li>
                    <li className="item">
                        <a
                            data-toggle="modal"
                            data-target="#chipsetting"
                            data-backdrop="static"
                            data-keyboard="false"
                        >
                            <img src="/images/edit-stake.png" />
                            <span>Edit stake</span>
                        </a>
                    </li>
                    <li className="item">
                        <Link to={"/"} className="site_title  active">
                            <img src="/images/home.png" />
                        </Link>
                    </li>
                    <li className="item">
                        <Link to={"/betHistory"}>
                            <img src="/images/history.png" />
                            <span>Bet History</span>
                        </Link>
                    </li>
                    <li className="item">
                        <Link to={"/login"} onClick={handleLogout}>
                            <img src="/images/logout.png" />
                            <span>Logout</span>
                        </Link>
                    </li>
                </ul>
            </footer>
            {/*footerlinks*/}
            <div
                id="chipsetting"
                className="modal fade"
                role="dialog"
                style={{ display: "none" }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header mod-header">
                            <button type="button" className="close" data-dismiss="modal">
                                ×
                            </button>
                            <h4 className="modal-title">Chip Setting</h4>
                        </div>
                        <div className="modal-body">
                            <div id="" />
                            <form className="form-inline">
                                {/* Render your input fields */}
                                {/* For each input field, bind the value and onChange event */}
                                {Object.keys(defaultChipValues).map((key) => (
                                    <div key={key} className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                            <label htmlFor={key}>{`Chips ${key}:`}</label>
                                            <br />
                                            <input
                                                type="number"
                                                name={key}
                                                className="form-control"
                                                id={key}
                                                value={chipValues[key]}
                                                onChange={(e) => handleInputChange(e, key)}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className="modal-footer">
                                    <div className="text-center" id="button_change">
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            data-dismiss="modal"
                                            onClick={updateStacksbtn}
                                        >
                                            Update Chip Setting
                                        </button>
                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer;