import React, { useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';

function ChangePassword() {

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };


  const userId = decryptCookie('id');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordError, setPasswordError] = useState('');


  const changePassWord = async () => {
    try {
      // Validate that new password and retype password match
      if (newPassword !== retypePassword) {
        setPasswordError('New Passwords does not match.');
        return;
      }

      const response = await fetch('https://api.s2bet.in/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: parseInt(userId),
          OldPassword: oldPassword,
          NewPassword: newPassword,
        }),
      });

      const result = await response.json();

      if (result.isSuccess) {
        setPasswordError(result.message);
        setOldPassword('')
        setNewPassword('')
        setRetypePassword('')
      } else {
        setPasswordError(result.message);
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="row">
            <div className="col-md-12">
              <div className="title_new_at">Change Password</div>
            </div>
            <div className="col-md-12" />
            <br />
            <br />
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div id="divLoading" />
              <div id="PassUserMsg">
                <span id="passerror" style={{ color: "red" }}>
                  {passwordError}
                </span>
              </div>
              <form
                action=""
                className="form-horizontal form-label-left"
                method="post"
                acceptCharset="utf-8"
              >
                <div className="item form-group">
                  <label
                    className="control-label col-md-3 col-sm-3 col-xs-12"
                    htmlFor="firstname"
                  >
                    Old Password
                    <span className="required">*</span>{" "}
                  </label>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <input
                      type="password"
                      name="old_password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      className="form-control col-md-7 col-xs-12"
                      placeholder="Old Password"
                      label=""
                      required="required"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="item form-group">
                  <label
                    className="control-label col-md-3 col-sm-3 col-xs-12"
                    htmlFor="firstname"
                  >
                    New Password
                    <span className="required">*</span>{" "}
                  </label>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <input
                      type="password"
                      name="newpassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control col-md-7 col-xs-12"
                      placeholder="New Password"
                      label=""
                      required="required"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="item form-group">
                  <label
                    className="control-label col-md-3 col-sm-3 col-xs-12"
                    htmlFor="firstname"
                  >
                    Retype New Password <span className="required">*</span>{" "}
                  </label>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <input
                      type="password"
                      name="Renewpassword"
                      value={retypePassword}
                      onChange={(e) => setRetypePassword(e.target.value)}
                      className="form-control col-md-7 col-xs-12"
                      placeholder="Retype Password"
                      label=""
                      required="required"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="ln_solid" />
                <div className="form-group">
                  <div className="col-md-6 col-md-offset-3">
                    <button type="reset" className="btn btn-primary">
                      Reset
                    </button>
                    <button type="button" onClick={changePassWord} className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
