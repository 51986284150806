import React, { useEffect, useState } from 'react'
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useNavigate, Link } from 'react-router-dom';

function Runningmarketanalysis() {

    // Decrypt the encrypted cookie value
    const decryptCookie = (cookieName) => {
        const encryptedCookieValue = Cookies.get(cookieName);
        if (encryptedCookieValue) {
            const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return '';
    };

    const navigate = useNavigate();
    const userId = decryptCookie('id')

    const [data, setData] = useState([])

    useEffect(() => {
        fetchBet()
    }, [userId])

    const fetchBet = async () => {
        try {
            const response = await fetch(`https://api.s2bet.in/GetBetHistory/${userId}`);
            const result = await response.json();
            //console.log("Bet result : " + JSON.stringify(result))

            // Use a Map to keep track of unique eventNames
            const uniqueEventNames = new Map();

            // Filter the data and keep only one entry per eventName
            const filteredData = result.data.filter(item => {
                if (item.isSettlement === 2) {
                    const eventName = item.eventName;
                    if (!uniqueEventNames.has(eventName)) {
                        uniqueEventNames.set(eventName, true);
                        return true;
                    }
                }
                return false;
            });

            //console.log("Filtered Data : " + JSON.stringify(filteredData))

            setData(filteredData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleNavigate = (eid, gameId, marketId) => {
        //console.log("Handle " + eid + " " + gameId)
        navigate(`/EVENT/${eid}/${gameId}/${marketId}`);
    }






    return (
        <>
            <div className="main_container" id="sticky">
                <div className="right_col" role="main" style={{}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title_new_at">My Market</div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div id="divLoading" />
                            {/*Loading class */}
                            <div className="table-responsive" id="filterdata">
                                <table
                                    className="table table-striped jambo_table bulk_action"
                                    id="analysisList"
                                >
                                    <thead>
                                        <tr className="headings">
                                            <th className="darkpurplecolor">S.No.</th>
                                            <th className="lightgreencolor">Match Name</th>
                                            <th className="darkpurplecolor">Sport Name</th>
                                            <th className="lightgreencolor">Match Status</th>
                                            <th className="darkpurplecolor">Profit</th>
                                            <th className="lightgreencolor">Loss</th>

                                        </tr>
                                    </thead>
                                    <tbody id="">

                                        {data.map((item, index) => (
                                            <tr key={item.id} >
                                                <td>{index + 1}</td>
                                                <td >
                                                    <Link onClick={(e) => {
                                                        e.preventDefault();
                                                        handleNavigate(item.sportId, item.eventId, item.marketId);
                                                    }}>
                                                        {item.eventName}
                                                    </Link>
                                                </td>
                                                <td>{item.sportId == 1 ? "Soccer" : item.sportId == 2 ? "Tennis" : item.sportId == 4 ? "Cricket" : ""}</td>
                                                <td>{item.isSettlement == 1 ? "Close" : item.isSettlement == 2 ? "Open" : ""}</td>
                                                <td className="red">
                                                    <span className="pull-right"></span>
                                                </td>
                                                <td className="green">
                                                    <span className="pull-right"></span>{" "}
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /page content */}
                {/*footerlinks*/}
                <Footer />
                {/*commanpopup*/}
                {/*commanpopup*/}
            </div>


        </>
    )
}

export default Runningmarketanalysis;