import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
// import Dashboard from './Dashboard';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setisError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const saltRounds = 10;

  const handleRoute = () => {
    navigate("/")
    window.location.reload();
    // return redirect("/");
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    // Set loading state to true
    setIsLoading(true);

    try {
      // Get user's IP address
      const responseIP = await fetch("https://api.ipify.org/?format=json")
      const resultIP = await responseIP.json();

      // Encrypt sensitive data before storing in cookies
      const encryptedUserIp = CryptoJS.AES.encrypt(resultIP.ip, 'secretKey').toString();
      Cookies.set('userIP', encryptedUserIp);

      // Get user's location based on IP address
      const responseLocation = await fetch(`https://freeipapi.com/api/json/${resultIP.ip}`)
      const locationData = await responseLocation.json();

      // Create location string in the specified format
      const locationString = `${locationData.cityName}/${locationData.regionName}/${locationData.countryName}/${locationData.zipCode}`;


      // Encrypt sensitive data before storing in cookies
      const encryptedLocation = CryptoJS.AES.encrypt(locationString, 'secretKey').toString();
      Cookies.set('location', encryptedLocation);

      // Login Check with user's IP and location
      const response = await fetch('https://api.s2bet.in/userlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: username,
          password,
          userIP: resultIP.ip,
          geoLocationn: locationString,
        }),
      });

      const responseData = await response.json();

      //console.log("Response data is from login is : ", responseData)


      if (responseData.isSuccess) {



        // Encrypt sensitive data before storing in cookies
        const encryptedUserName = CryptoJS.AES.encrypt(responseData.data.UserName, 'secretKey').toString();
        const encryptedId = CryptoJS.AES.encrypt(responseData.data.Id.toString(), 'secretKey').toString();
        const encryptedFullName = CryptoJS.AES.encrypt(responseData.data.FullName, 'secretKey').toString();
        const encryptedExposure = CryptoJS.AES.encrypt(responseData.data.ExposureLimit.toString(), 'secretKey').toString();

        Cookies.set('userName', encryptedUserName);
        Cookies.set('id', encryptedId);
        Cookies.set('fullName', encryptedFullName);
        Cookies.set('exposure', encryptedExposure);


        // // Handle successful login
        // Cookies.set('userName', responseData.data.UserName);
        // Cookies.set('id', responseData.data.Id);
        // Cookies.set('fullName', responseData.data.FullName);
        // Cookies.set('exposure', responseData.data.ExposureLimit)
        handleRoute();
        //console.log('Login successful: ' + JSON.stringify(responseData));
        //console.log('role Id ' + JSON.stringify(responseData.data.roleId));
      } else {
        // Handle login failure
        setisError(responseData.message)
        console.error('Login failed');
      }

    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      // Set loading state back to false after the request is completed
      setIsLoading(false);
    }
  };


  return (
    <>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <div className="bg_login">
        <div id="wrapper">
          <div className="logo">
            <img src="https://ag.s2bet.in/images/logo.png" alt="..." />
          </div>
          <div id="login" className="form">
            <section className="login_content">
              <form onSubmit={handleSubmit} acceptCharset="utf-8" autoComplete="off">
                <span style={{ color: 'red', fontWeight: 'bold' }} id="error" >
                  {isError}
                </span>
                <div>
                  <label> Username</label>
                  <div className="linput">
                    <input
                      type="text"
                      name="email"
                      style={{ paddingLeft: 10, fontWeight: 'bold' }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control"
                      placeholder="username"
                      required={1}
                    />
                  </div>
                </div>
                <div>
                  <label> Password</label>
                  <div className="linput">
                    <input
                      type="password"
                      name="password"
                      style={{ paddingLeft: 10, fontWeight: 'bold' }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      placeholder="Password"
                      required={1}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="checkbox">
                  <label>
                    <input type="checkbox" name="remember" id="remember" />
                    Remember me
                  </label>
                  <a style={{ color: '#232323' }} href="" className="download-apk-btn">
                    <i className="fa fa-android" />
                  </a>
                </div>
                <div>
                  <button type="submit" className="btn btn-default submit">
                    Log in
                  </button>
                  <h6 style={{ color: '#000', fontWeight: 'bold' }} id="loginMessage">
                    -
                  </h6>
                </div>
                <div className="clearfix" />
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
