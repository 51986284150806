import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer'

function UserMatchDetail() {


  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  const navigate = useNavigate()
  const { marketId } = useParams();
  //console.log(marketId)

  const [filtered, setFiltered] = useState([])
  const [plAmount, setPLAmount] = useState('')

  useEffect(() => {

    fetchData();
  }, []);


  const fetchData = async () => {
    const userId = decryptCookie('id')
    const formattedUserName = decryptCookie('userName') ? decryptCookie('userName').replace(/"/g, '') : '';
    try {
      const response = await fetch(`https://api.s2bet.in/GetProfitAndLoss/${userId}`);
      const result = await response.json();

      //console.log('ProfitLoss Api : ' + result.data.plMainVMList)

      if (Array.isArray(result.data.plMainVMList)) {
        // Format the date in each item before setting it in the state
        const formattedData = result.data.plMainVMList.map(item => ({
          ...item
          // formattedPlaceTime: <Moment format='ddd D MMM h:mm a'>{item.placeTime}</Moment>,
        }));
        //console.log("Formated data after map " + JSON.stringify(formattedData))

        // Filter by date and Status
        const filteredData = formattedData.filter(item =>
          item.marketId == marketId
        );

        // Sort the data by the ISO date string
        // filteredData.sort((a, b) => new Date(a.placeTime) - new Date(b.placeTime));

        //console.log("Formatted and Sorted Bet History data:", filteredData);

        //Calc MatchOdds Amount
        const totalPLAmount = filteredData.reduce((accumulator, item) => accumulator + item.amount, 0);
        const roundedTotalAmount = totalPLAmount.toFixed(2);
        //console.log("Total Pl amount is : "+roundedTotalAmount)
        // // Sort the data by the ISO date string
        // formattedData.sort((a, b) => new Date(a.result.data.placeTime) - new Date(b.result.data.placeTime));

        // //console.log("Formatted and Sorted Bet History data:", formattedData);
        setFiltered(filteredData)
        setPLAmount(roundedTotalAmount)

      } else {
        console.error('Invalid data format:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleEvent = (marketId) => {
    navigate(`/userShowBetCR/${marketId}`);
  }

  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="container">
            <section id="content">
              <section className="vbox">
                <section className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title_new_at">
                        Match Details{" "}
                        <button
                          style={{ float: "right" }}
                          className="btn btn-xs btn-primary"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div id="divLoading" />
                      <div className="table-scroll table-responsive">
                        <div
                          id="DataTables_Table_23_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <div
                            id="DataTables_Table_23_filter"
                            className="dataTables_filter"
                          >
                            <label>
                              Search:
                              <input
                                type="search"
                                className=""
                                placeholder=""
                                aria-controls="DataTables_Table_23"
                              />
                            </label>
                          </div>
                          <table
                            datatable=""
                            className="table table-striped jambo_table bulk_action no-footer dataTable"
                            id="DataTables_Table_23"
                            role="grid"
                            aria-describedby="DataTables_Table_23_info"
                            style={{}}
                          >
                            <thead>
                              <tr className="headings" role="row">
                                <th
                                  className="sorting_asc"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="S.No. : activate to sort column descending"
                                  style={{ width: 102 }}
                                  aria-sort="ascending"
                                >
                                  S.No.
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Event Name : activate to sort column ascending"
                                  style={{ width: 216 }}
                                >
                                  Event Name
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Market : activate to sort column ascending"
                                  style={{ width: 138 }}
                                >
                                  Market
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="P_L : activate to sort column ascending"
                                  style={{ width: 76 }}
                                >
                                  P_L
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Comm : activate to sort column ascending"
                                  style={{ width: 122 }}
                                >
                                  Comm
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Comm : activate to sort column ascending"
                                  style={{ width: 122 }}
                                >
                                  Total
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Comm : activate to sort column ascending"
                                  style={{ width: 122 }}
                                >
                                  Result
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Date : activate to sort column ascending"
                                  style={{ width: 90 }}
                                >
                                  Date
                                </th>
                                <th
                                  className="sorting"
                                  tabIndex={0}
                                  aria-controls="DataTables_Table_23"
                                  rowSpan={1}
                                  colSpan={1}
                                  aria-label="Action : activate to sort column ascending"
                                  style={{ width: 126 }}
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody id="betlistdiv">
                              {filtered.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.event}</td>
                                  <td>{item.oddsType}</td>
                                  <td className={item.amount >= 0 ? "checkColor green" : "checkColor red"}>{item.amount}</td>
                                  <td className="checkColor green">-0.00</td>
                                  <td className={item.amount >= 0 ? "checkColor green" : "checkColor red"}>{item.amount}</td>
                                  <td></td>
                                  <td>{format(parseISO(item.settledTime), "dd MMM yy hh:mm:ss a")}</td>
                                  <td>
                                    <Link onClick={(e) => {
                                      e.preventDefault();
                                      handleEvent(item.marketId);
                                    }}>Show Bets</Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colSpan={3}>Total Pnl</td>
                                <td colSpan={1} id="total" className={plAmount >= 0 ? "green" : "red"}>
                                  {plAmount}
                                </td>
                                <td colSpan={1} className="red" id="totalCom">
                                  -0
                                </td>
                                <td colSpan={3} id="grandtotal" className={plAmount >= 0 ? "green" : "red"}>
                                  {plAmount}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>


    </>
  )
}

export default UserMatchDetail