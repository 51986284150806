import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

function PrivateRoutes() {
    const [authentication, setAuthentication] = useState(true);

    // Decrypt the encrypted cookie value
    const decryptCookie = (cookieName) => {
        const encryptedCookieValue = Cookies.get(cookieName);
        if (encryptedCookieValue) {
            const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return '';
    };

    useEffect(() => {
        const isLoggedIn = Cookies.get('logged-in');
       //console.log('is logged in console ... ', isLoggedIn);
       //console.log('type of logged in console ... ', typeof isLoggedIn);

        if (isLoggedIn == 'true') {
            setAuthentication(true);
        } else {
            setAuthentication(false);
        }
    }, []);

    useEffect(() => {
        const isLoggedIn = Cookies.get('logged-in');
        if (isLoggedIn == 'true') {
            // Redirect to "/" if already logged in and accessing "/login"
            // return window.location.href = "/";
        }
    }, []);

    return (
        authentication ? <Outlet /> : <Navigate to='/login' />
    );
}

export default PrivateRoutes