import { createContext, useContext, useState } from 'react';

const ChipValuesContext = createContext();

export const ChipValuesProvider = ({ children }) => {
  const [betSend, setBetSend] = useState(false)
  const [betStartTime, setBetStartTime] = useState(0)
  const [isBetlock, setIsBetlock] = useState(0)
  const [updateBetId , setupdateBetId ] = useState('')
  const defaultChipValues = {
    Name1: 100,
    Value1: 100,
    Name2: 200,
    Value2: 200,
    Name3: 500,
    Value3: 500,
    Name4: 1000,
    Value4: 1000,
    Name5: 5000,
    Value5: 5000,
    Name6: 10000,
    Value6: 10000,
  };

  const [chipValues, setChipValues] = useState(defaultChipValues);

  const updateChipValue = (name, value) => {
    setChipValues((prevChipValues) => ({ ...prevChipValues, [name]: value }));
  };

  return (
    <ChipValuesContext.Provider value={{ chipValues, updateChipValue, betSend, setBetSend, isBetlock, setIsBetlock, betStartTime, setBetStartTime, updateBetId , setupdateBetId }}>
      {children}
    </ChipValuesContext.Provider>
  );
};

export const useChipValues = () => {
  const context = useContext(ChipValuesContext);
  if (!context) {
    throw new Error('useChipValues must be used within a ChipValuesProvider');
  }
  return context;
};
