import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Moment from 'react-moment';
import 'moment-timezone';
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';
import { CloseButton } from 'react-bootstrap';

function BetHistory() {

  const [data, setData] = useState([]);
  const [user, setUser] = useState('')
  const [selectedSportId, setSelectedSportId] = useState(5); // Default to All
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(getDefaultFromDate());
  const [toDate, setToDate] = useState(getDefaultToDate());
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState(1); // Default to Settled
  const [filterClicked, setFilterClicked] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  function getDefaultFromDate() {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return twoDaysAgo.toISOString().split('T')[0];
  }

  function getDefaultToDate() {
    const today = new Date();
    today.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
    return today.toISOString().split('T')[0];
  }

  const fetchData = async () => {
    const userId = decryptCookie('id');
    const formattedUserName = decryptCookie('userName') ? decryptCookie('userName').replace(/"/g, '') : '';
    try {
      setIsLoading(true);
      const response = await fetch(`https://api.s2bet.in/GetBetHistoryByDate/${userId}?status=${status}&sportId=${selectedSportId}&fromDate=${fromDate} 00:00:00&toDate=${toDate} 23:59:59&page=${currentPage}&totalPage=${perPage}&searchTerm=${encodeURIComponent(searchTerm)}`);
      const result = await response.json();

      if (result.isSuccess && Array.isArray(result.data) && result.data.length > 0) {
        setTotalRows(result.totalRows); // Set total rows from the response

        const formattedData = result.data.map(item => ({
          ...item,
          formattedPlaceTime: <Moment format='ddd D MMM h:mm a'>{item.placeTime}</Moment>,
        }));

        // formattedData.sort((a, b) => new Date(b.placeTime) - new Date(a.placeTime));

        setUser(formattedUserName);
        setData(formattedData);
      } else {
        console.error('Invalid data format:', result);
        setData([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false)
    }
  };


  const handleSportTabClick = (sportId) => {
    setSelectedSportId(sportId);
    setCurrentPage(1); // Reset to the first page when changing tabs
    setFilterClicked(true); // Trigger refetching data
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
    setFilterClicked(true); // Trigger refetching data
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleFilter = () => {
    setFilterClicked(true); // Trigger refetching with the updated filter criteria
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setFilterClicked(true); // Trigger refetching data
  };

  useEffect(() => {
    if (filterClicked) {
      fetchData();
      setFilterClicked(false); // Reset the filterClicked state
    }
  }, [filterClicked, perPage]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <style>{`
      .betTable {
        height: 600px;
        overflow-y: scroll;
        }
        @media (max-width: 767px) {
          .betTable {
            height: 400px;
            overflow-y: scroll;
            }
            }
            `}</style>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="col-md-12">
            <div className="title_new_at">
              Bet History
              <select style={{ color: "black" }} id="pages" onChange={handlePerPageChange} value={perPage}>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
          <div className="col-md-12" />
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="clearfix data-background">
              <form method="post" id="formSubmit" className="form-horizontal form-label-left input_mask">
                <div className="popup_col_2">
                  <input
                    type="date"
                    name="from_date"
                    id="betsstartDate"
                    className="form-control col-md-7 col-xs-12 has-feedback-left"
                    placeholder="From date"
                    autoComplete="off"
                    onChange={handleFromDateChange}
                    value={fromDate}
                  />
                </div>
                <div className="popup_col_2">
                  <input
                    type="date"
                    name="to_date"
                    id="betsendDate"
                    className="form-control col-md-7 col-xs-12 has-feedback-left"
                    placeholder="To date"
                    autoComplete="off"
                    onChange={handleToDateChange}
                    value={toDate}
                  />
                </div>
                <div className="popup_col_1">
                  <input
                    type="search"
                    name="searchTerm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    id="searchBet"
                    maxLength={100}
                    size={50}
                    className="form-control"
                    placeholder="Search"
                  />
                </div>
                <div className="popup_col_2">
                  <select className="form-control" name="pStatus" id="betStatus" onChange={handleStatusChange} value={status}>
                    <option value={2}>Open</option>
                    <option value={1}>Settled</option>
                  </select>
                </div>
                <div className="popup_col_3">
                  <button
                    type="button"
                    name="submit"
                    className="blue_button"
                    id="submit_form_button"
                    value="filter"
                    onClick={handleFilter}
                    data-attr="submit"
                  >
                    <i className="fa fa-filter" />
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="tab_bets get-mchlist">
              <ul id="betsalltab" className="nav nav-pills match-lists">
                <li id="All" className={selectedSportId === 5 ? 'active' : ''}>
                  <a onClick={() => handleSportTabClick(5)} dat-attr={5}>All</a>
                </li>
                <li id="Cricket" className={selectedSportId === 4 ? 'active' : ''}>
                  <a onClick={() => handleSportTabClick(4)} dat-attr={4}>Cricket</a>
                </li>
                <li id="Tennis" className={selectedSportId === 2 ? 'active' : ''}>
                  <a onClick={() => handleSportTabClick(2)} dat-attr={2}>Tennis</a>
                </li>
                <li id="Soccer" className={selectedSportId === 1 ? 'active' : ''}>
                  <a onClick={() => handleSportTabClick(1)} dat-attr={1}>Soccer</a>
                </li>
                <li id="Fancy" className={selectedSportId === 0 ? 'active' : ''}>
                  <a onClick={() => handleSportTabClick(0)} dat-attr={0}>Fancy</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div id="divLoading" />
            <div className={totalRows > 10 ? `table-responsive appendAjaxTbl betTable` : `table-responsive appendAjaxTbl`}>
              <table className="table table-striped jambo_table bulk_action" id="datatables">
                <thead>
                  <tr className="headings">
                    <th className="darkpurplecolor">S.No.</th>
                    <th className="lightgreencolor">Client</th>
                    <th className="darkpurplecolor">Description</th>
                    <th className="lightgreencolor">Selection</th>
                    <th className="darkpurplecolor">Type</th>
                    <th className="lightgreencolor">Odds</th>
                    <th className="darkpurplecolor">Stack</th>
                    <th className="lightgreencolor">Date</th>
                    <th className="darkpurplecolor">P_L</th>
                    <th className="lightgreencolor">Profit</th>
                    <th className="darkpurplecolor">Liability</th>
                    <th className="lightgreencolor">Bet type</th>
                    <th className="darkpurplecolor">Status</th>
                    <th className="lightgreencolor">IP</th>
                  </tr>
                </thead>
                <tbody id="betlistdiv">
                  {data.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * perPage + index + 1}</td>
                      <td>{user}</td>
                      <td>{item.event}</td>
                      <td>{item.selection}</td>
                      <td>{item.market}</td>
                      <td>{item.oddsRequest}</td>
                      <td>{item.amountStake}</td>
                      <td>{item.formattedPlaceTime}</td>
                      <td className={item.resultAmount >= 0 ? "green" : "red"}>{item.resultAmount}</td>
                      <td className={item.profit >= 0 ? "green" : "red"}>{item.profit}</td>
                      <td className={item.liability >= 0 ? "green" : "red"}>{item.liability}</td>
                      <td>{item.type}</td>
                      <td>{item.isSettlement === 1 ? "Close" : "Open"}</td>
                      <td>{item.ipAddress}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p id="items">Showing {(currentPage - 1) * perPage + 1} to {Math.min(currentPage * perPage, totalRows)} of Entries {totalRows}</p>
            <div className="pagination-row dataTables_paginate paging_simple_numbers" style={{ marginBottom: '15%' }}>
              {currentPage > 1 && (
                <button
                  className="paginate_button"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              )}
              {Array.from({ length: Math.ceil(totalRows / perPage) }, (_, i) => i + 1)
                .filter(page =>
                  page === 1 ||
                  page === Math.ceil(totalRows / perPage) ||
                  (page >= currentPage - 1 && page <= currentPage + 1)
                )
                .map(page => (
                  <button
                    key={page}
                    className={`paginate_button ${currentPage === page ? 'current' : ''}`}
                    onClick={() => paginate(page)}
                  >
                    {page}
                  </button>
                ))}
              {currentPage < Math.ceil(totalRows / perPage) && (
                <button
                  className="paginate_button"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer style={{ margintop: '10%' }} />
      </div>
    </>
  )
}

export default BetHistory