import React from 'react'
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer'

function Announcement() {
  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="row">
            <div className="container" style={{ marginBottom: "5%" }}>
              <div
                className="col-lg-12 col-xs-12"
                style={{ background: "#fff", padding: 0 }}
              >
                <div className="title_new_at">
                  Announcement
                  <select id="pages" style={{ color: "black" }}>
                    <option value={10} selected="selected">
                      10
                    </option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <div className="pull-right">
                    <button
                      className="btn_common"
                      onclick="javascript:history.go(-1)"
                    >
                      Back
                    </button>{" "}
                  </div>
                </div>
                <div
                  className="col-lg-12 col-xs-12"
                  style={{ padding: 0, marginTop: 2 }}
                  id="data"
                >
                  <div className="row box" style={{ marginBottom: "5px !important" }}>
                    <div
                      className="col-lg-1 col-xs-2"
                      style={{
                        textAlign: "center",
                        padding: 0,
                        border: "1px solid #000"
                      }}
                    >
                      <p
                        className="margin0"
                        style={{ fontWeight: 700, fontSize: 25 }}
                      >
                        24
                      </p>
                      <p className="margin0">Mar</p>
                      <p className="margin0">2022</p>
                    </div>
                    <div className="col-lg-11 col-xs-10">
                      <span style={{ fontWeight: 700, fontSize: 20 }}>IPL</span>
                      <p className="fsize" style={{ fontSize: 17 }}>
                        AAP SABKO AANE WALE IPL KI SHUBKAMNAYE
                      </p>
                    </div>
                  </div>
                  <div className="row box" style={{ marginBottom: "5px !important" }}>
                    <div
                      className="col-lg-1 col-xs-2"
                      style={{
                        textAlign: "center",
                        padding: 0,
                        border: "1px solid #000"
                      }}
                    >
                      <p
                        className="margin0"
                        style={{ fontWeight: 700, fontSize: 25 }}
                      >
                        07
                      </p>
                      <p className="margin0">Sep</p>
                      <p className="margin0">2021</p>
                    </div>
                    <div className="col-lg-11 col-xs-10">
                      <span style={{ fontWeight: 700, fontSize: 20 }}>Old Data</span>
                      <p className="fsize" style={{ fontSize: 17 }}>
                        PURANA DATA http://old4bet.dsbet.in/ PE JAKE DEKH SAKTE HAI
                      </p>
                    </div>
                  </div>
                  <div className="row box" style={{ marginBottom: "5px !important" }}>
                    <div
                      className="col-lg-1 col-xs-2"
                      style={{
                        textAlign: "center",
                        padding: 0,
                        border: "1px solid #000"
                      }}
                    >
                      <p
                        className="margin0"
                        style={{ fontWeight: 700, fontSize: 25 }}
                      >
                        07
                      </p>
                      <p className="margin0">Sep</p>
                      <p className="margin0">2021</p>
                    </div>
                    <div className="col-lg-11 col-xs-10">
                      <span style={{ fontWeight: 700, fontSize: 20 }}>OLD DATA </span>
                      <p className="fsize" style={{ fontSize: 17 }}>
                        PURANA DATA old4bet.dsbet.in. / PE JAKE DEKH SAKTE HAI PURANA
                        DATA 3 DIN K LIE RAHEGASAB LOG APNA PURANA SETTLEMENT CLEAR
                        KAR LE
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="dataTables_wrapper no-footer"
                  style={{ marginBottom: 10 }}
                >
                  <div
                    className="dataTables_info"
                    id="datatable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 3 of Entries 3
                  </div>
                  <div
                    className="dataTables_paginate paging_full_numbers"
                    id="pages_data"
                  >
                    <a
                      className="paginate_button first disabled"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx={0}
                      tabIndex={-1}
                      id="DataTables_Table_0_first"
                    >
                      First
                    </a>
                    <a
                      className="paginate_button previous disabled"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx={1}
                      tabIndex={-1}
                      id="DataTables_Table_0_previous"
                    >
                      Previous
                    </a>
                    <span>
                      <a
                        className="paginate_button current"
                        aria-controls="DataTables_Table_0"
                        data-dt-idx={2}
                        tabIndex={0}
                      >
                        1
                      </a>
                    </span>
                    <a
                      className="paginate_button previous disabled"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx={1}
                      tabIndex={-1}
                      id="DataTables_Table_0_previous"
                    >
                      Next
                    </a>
                    <a
                      className="paginate_button first disabled"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx={0}
                      tabIndex={-1}
                      id="DataTables_Table_0_first"
                    >
                      Last
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>


    </>
  )
}

export default Announcement