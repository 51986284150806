import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Moment from 'react-moment';
import 'moment-timezone';
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';

function AccountSt() {

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  const navigate = useNavigate();
  const userName = decryptCookie('userName') ? decryptCookie('userName').replace(/"/g, '') : '';

  const [allData, setAllData] = useState([]);
  const [accData, setAccData] = useState([]);
  const [betData, setBetData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]); // Default filter
  const [fromDate, setFromDate] = useState(getDefaultFromDate());
  const [toDate, setToDate] = useState(getDefaultToDate());
  const [searchTerm, setSearchTerm] = useState('');
  const [filterClicked, setFilterClicked] = useState(false);




  useEffect(() => {
    fetchData()
  }, [])


  useEffect(() => {

    // Fetch data initially
    if (filterClicked) {
      fetchData();
      // setCurrentPage(1);
      setFilterClicked(false); // Reset the filterClicked state
    }

    // Fetch data initially
    // fetchData();
    document.getElementById('Statement').click();
    // ...
  }, [fromDate, toDate, searchTerm, filterClicked]);

  const fetchData = async () => {
    const id = decryptCookie('id');

    try {
      const response = await fetch(`https://api.s2bet.in/GetAccountStatement/${id}`);
      const result = await response.json();

      ////console.log('Data in Account Statement API:', result.data);

      // Account statement data
      if (Array.isArray(result.data.accountstatement)) {
        const accountData = result.data.accountstatement.map(item => ({ ...item, source: 'accountstatement' }));

        // Sort the data by the ISO date string
        accountData.sort((a, b) => new Date(b.createdDate || b.settleTime) - new Date(a.createdDate || a.settleTime));

        // setAccData(accountData);
        //console.log("Account Data:", accountData);
      } else {
        console.error('Invalid data format:', result);
      }

      // Bet data
      if (Array.isArray(result.data.bets)) {
        const Data = result.data.bets.map(item => ({ ...item, source: 'bets' }));

        // Sort the data by the ISO date string
        Data.sort((a, b) => new Date(b.createdDate || b.settleTime) - new Date(a.createdDate || a.settleTime));

        // setBetData(Data);
        //console.log("Bets Data:", Data);
      } else {
        console.error('Invalid data format:', result);
      }

      // Both sub-api Data in Array
      if (
        Array.isArray(result.data.accountstatement) &&
        Array.isArray(result.data.bets)
      ) {
        // Combine the two arrays into a single array with source property
        const formattedData = [
          ...result.data.accountstatement.map(item => ({ ...item, source: 'accountstatement' })),
          ...result.data.bets.map(item => ({ ...item, source: 'bets' })),
        ];
       //console.log("Formatted data before filter : " + formattedData.length)
       //console.log("Formatted data before filter : " + JSON.stringify(formattedData))


        // Filter by date and Status
        const filteredData = formattedData.filter(item => {
          const dateToCompare = item.createdDate || item.settleTime;
          const displayedContent = getDisplayedContent(item);

          //console.log('Search Term:', searchTerm);
          //console.log('Displayed Content:', displayedContent);


          return (
            new Date(dateToCompare) >= new Date(fromDate) &&
            new Date(dateToCompare) <= new Date(toDate).setHours(23, 59, 59, 999) &&
            (searchTerm === '' || displayedContent.toLowerCase().includes(searchTerm.toLowerCase()))

          );
        });

        // Sort the data by the ISO date string
        filteredData.sort((a, b) => new Date(b.createdDate || b.settleTime) - new Date(a.createdDate || a.settleTime));

       //console.log("Formatted and Sorted AccountSt data:", filteredData);

        const accountsData = filteredData.filter(item => item.source == 'accountstatement')
       //console.log('Accounts data : ', JSON.stringify(accountsData))
        const betsData = filteredData.filter(item => item.source == 'bets')
       //console.log('bets data : ', JSON.stringify(betsData))

        setAccData(accountsData)
        setBetData(betsData)
        setAllData(filteredData);
        setSelectedFilter(filteredData)
        //console.log("Combined Data:", filteredData);
      } else {
        console.error('Invalid data format:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getDisplayedContent = (item) => {
    if (item.source === 'bets') {
      return item.resultAmount >= 0
        ? `Profit to ${userName} by ${item.event} ${item.market}`
        : `Loss from ${userName} by ${item.event} ${item.market}`;
    } else {
      return item.balance >= 0
        ? `Chip Credited to ${userName} by (${item.toUserName}) - Done By - (${item.byUserName})`
        : `Chip Withdraw from ${userName} by (${item.toUserName}) - Done By - (${item.byUserName})`;
    }
  };

  function getDefaultFromDate() {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return twoDaysAgo.toISOString().split('T')[0];
  }

  function getDefaultToDate() {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today.toISOString().split('T')[0];
  }

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleFilter = () => {
    fetchData(); // Trigger refetching with the updated filter criteria
    // setCurrentPage(1);
    setFilterClicked(true);
  };

  const getData = (type, value) => {
    // Placeholder for handling radio button clicks
    // You can implement logic based on the 'type' and 'value' parameters
    if (value === "Account") {
     //console.log("selected Account data : " + JSON.stringify(accData))
      setAllData(accData)
    } else if (value === "PL") {
     //console.log("selected bet data : " + JSON.stringify(betData))
      setAllData(betData)
    } else if (value === "Statement") {
     //console.log("selected Statement data : " + selectedFilter)
      setAllData(selectedFilter);
    }
    //console.log(`Clicked on ${type} with value: ${value}`);
  };

  // No empty dependency array, effect will run continuously
  const handleEvent = (marketId) => {
    navigate(`/userMatchDetail/${marketId}`);
  }


  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="row">
            <div className="col-md-12">
              <div className="title_new_at">
                Account Statement
                <div className="pull-right">
                  <button className="btn_common" onclick="javascript:history.go(-1)">
                    Back
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="col-md-12" />
            <div className="col-md-12">
              <div className="filter_page data-background">
                {/* <form method="post" id="formSubmit" style="color: #000;"> */}
                <div className="col-md-12 custom-check">
                  <input
                    type="hidden"
                    name="user_id"
                    id="user_id"
                    defaultValue={36452}
                  />
                  <input
                    type="hidden"
                    name="ajaxUrl"
                    id="ajaxUrl"
                    defaultValue="CacStatement"
                  />
                  <div className="form-group">
                    <input
                      name="fltrselct"
                      defaultValue={0}
                      checked={selectedFilter === allData} // Set defaultChecked based on the selectedFilter state
                      type="radio"
                      onClick={() => getData('All', 'Statement')}
                      id="Statement"
                    />
                    <label htmlFor="Statement">
                      <span>Statement</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      name="fltrselct"
                      defaultValue={1}
                      type="radio"
                      id="Account"
                      onClick={() => getData('Acc', 'Account')} />
                    <label htmlFor="Account">
                      <span>Accounts</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      name="fltrselct"
                      defaultValue={0}
                      type="radio"
                      onClick={() => getData('PL', 'PL')}
                      id="PL"
                    />
                    <label htmlFor="PL">
                      <span>P/L Statement</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      name="fltrselct"
                      defaultValue={0}
                      type="radio"
                      onClick={() => getData('CPL', 'CPL')}
                      id="CPL"
                    />
                    <label htmlFor="CPL">
                      <span>Casino P/L Statement</span>
                    </label>
                  </div>
                </div>
                <div className="block_2">
                  <input
                    type="date"
                    name="fdate"
                    id="fdate"
                    defaultValue=""
                    className="form-control"
                    placeholder="From Date" onChange={handleFromDateChange}
                    value={fromDate}
                    autoComplete="off"
                  />
                </div>
                <div className="block_2">
                  <input
                    type="date"
                    name="tdate"
                    id="tdate"
                    defaultValue=""
                    className="form-control"
                    placeholder="To Date" onChange={handleToDateChange}
                    value={toDate}
                    autoComplete="off"
                  />
                </div>
                <div className="block_2">
                  <input
                    type="search"
                    name="searchTerm"
                    id="searchTerm"
                    className="form-control"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    maxLength={100}
                    size={50}
                    autoComplete="off"
                  />
                </div>
                <div className="block_2 buttonacount">
                  <button
                    type="button"
                    name="submit"
                    id="submit_form_button"
                    onClick={handleFilter}
                    className="blue_button"
                    data-attr="submit"
                  >
                    Filter
                  </button>
                  {/* <a  class="red_button">Clear</a> */}
                </div>
                {/*  </form> */}
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div id="divLoading" />
              {/*Loading class */}
              <div className="table-responsive" id="filterdata">
                <table
                  className="table table-bordered table-dark table_new_design"
                  id="datatablesss"
                >
                  <thead>
                    <tr className="headings">
                      <th className="darkpurplecolor">S.No.</th>
                      <th className="lightgreencolor">Date</th>
                      <th className="darkpurplecolor">Description</th>
                      <th className="lightgreencolor rrig text-right">Credit</th>
                      <th className="darkpurplecolor rrig text-right">Debit</th>
                      <th className="lightgreencolor rrig text-right">Commission</th>
                      <th className="darkpurplecolor rrig text-right">Balance</th>
                      <th className="lightgreencolor rrig text-right">Remarks</th>
                    </tr>
                  </thead>
                  <tbody id="statments">
                    {allData.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        {/* <td className=" ">{item.createdDate ? (
                          <td className=" ">{format(parseISO(item.createdDate), "dd MMM yy hh:mm:ss a")}</td>
                        ) : (
                          <td className=" ">{item.settleTime ? format(parseISO(item.settleTime), "dd MMM yy hh:mm:ss a") : 'N/A'}</td>
                        )}</td> */}
                        {/* <td className= " ">{item.createdDate ? format(parseISO(item.createdDate), "dd MMM yy hh:mm:ss a") : format(parseISO(item.settleTime), "dd MMM yy hh:mm:ss a")}</td> */}
                        <td className=' '><Moment format='DD MMM yy hh:mm:ss a'>{item.createdDate || item.settleTime}</Moment></td>
                        <td className=" ">
                          {item.source === 'bets' && (
                            // <a href="#" onClick={(e) => handleEvent(item.marketId)}>
                            //   {getDisplayedContent(item)}
                            // </a>
                            <Link onClick={(e) => { e.preventDefault(); handleEvent(item.marketId) }}>{getDisplayedContent(item)}</Link>
                          )}
                          {item.source !== 'bets' && getDisplayedContent(item)}

                        </td>
                        {/* {item.source === 'accountstatement' ? <> </> :<> <td className="green text-right">{item.resultAmount >= 0}</td></>} */}
                        <td className="green text-right">{item.source === 'accountstatement' ? item.deposit : item.resultAmount >= 0 ? item.resultAmount : ''}</td>
                        <td className="red text-right">{item.source === 'accountstatement' ? item.withdraw : item.resultAmount < 0 ? item.resultAmount : ''}</td>
                        <td className="red text-right">0</td>
                        <td className={item.balance >= 0 ? "green text-right" : "red text-right"}>{item.balance}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                    {/* {allData.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index}</td>
                        <td className=" ">{item.createdDate ? format(parseISO(item.createdDate), "dd MMM yy hh:mm:ss a") : format(parseISO(item.settleTime), "dd MMM yy hh:mm:ss a")}</td>
                        <td className=" ">
                          
                          <a href="/userMatchDetail">
                            {item.balance >= 0 ? `Profit to ${userName} by ${item.event} ${item.market}` : `Loss from ${userName} by ${item.event} ${item.market}`}
                          </a>
                        </td>
                        <td className="green text-right">{item.deposit}</td>
                        <td className="red text-right">{item.withdraw}</td>
                        <td className="red text-right">0</td>
                        <td className={item.balance >= 0 ? "green text-right" : "red text-right"}>{item.balance}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>


    </>
  )
}

export default AccountSt