import React, { useContext, useEffect, useState } from 'react';
// import $ from 'jquery';
import { useParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';
import { useChipValues } from './ChipValuesContext';
import Moment from 'react-moment';
import axios from 'axios';
import { getDate } from 'date-fns';
import { toast } from 'react-hot-toast';



const convertToIST = (dateString) => {
  const date = new Date(dateString);
  const ISTOffset = 330; // IST offset is UTC+5:30
  const ISTTime = new Date(date.getTime() + (ISTOffset * 60000));
  return ISTTime.toISOString().slice(0, 19).replace('T', ' '); // Format the date-time string
};


function EVENT() {


  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  const { eid, gameId, marketId } = useParams();
  const location = useLocation();
  const isBetlock = location.state && location.state.isBetlock;
  const isMO = location.state && location.state.isMO;
  const isBM = location.state && location.state.isBM;
  const isFancy = location.state && location.state.isFancy;

  // console.log('bet lock : ', isBetlock)



  const { chipValues, betSend, setBetSend, betStartTime, setupdateBetId } = useChipValues();
  const mainBlnc = decryptCookie('MainBalance');
  const exposure = decryptCookie('exposure');
  const userId = decryptCookie('id')
  const userIp = decryptCookie('userIP')


  //console.log('Bet time : ', betStartTime)
  //console.log('bet send is : ', betSend)

  //console.log("Is Bet Lock at event page : ", isBetlock)
  //console.log("Is isMatchOdds Lock at event page : ", isMO)
  //console.log("Is isBM Lock at event page : ", isBM)
  //console.log("Is isFancy Lock at event page : ", isFancy)



  const numEid = parseInt(eid)
  //console.log("eid type : " + typeof (numEid))

  const [filteredMatch, setFilterMatch] = useState([]);
  const [filteredEventNames, setfilterEventNames] = useState([])
  const [fileredMarketId, setfilterMarketId] = useState([])
  const [odds, setOdds] = useState([]);
  const [bm, setBM] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [data, setData] = useState([]);
  const [stakeValue, setStakeValue] = useState(0);
  const [teamName, setBetTeamName] = useState('');
  const [lossData, setLossData] = useState(0);
  const [profitData, setProfitData] = useState(0);
  const [type, setType] = useState('');
  const [teamselectionId, setTeamSelectionId] = useState('');
  const [clickedType, setClickedType] = useState(null);
  const [clickedTable, setClickedTable] = useState('');
  const [fancyPrice, setFancyPrice] = useState(0);
  const [sportsStack, setSportsStack] = useState([]);
  const [userMatchStack, setuserMatchStack] = useState([]);
  const [minmaxStack, setMinMaxStack] = useState([]);
  const [betDelay, setBetDelay] = useState(0);
  const [bookMDelay, setBookMDelay] = useState(0);
  const [fancyDelay, setFancyDelay] = useState(0);
  const [teamProfit, setTeamProfit] = useState(0)
  const [teamLoss, setTeamLoss] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [matchDelayTime, setMatchDelaytime] = useState(null)
  const [matchOddMarket, setMatchOddMarket] = useState([])
  const [bookMakerMarket, setbookMakerMarket] = useState([])
  const [fancyBookMarket, setFancyBookMarket] = useState([])
  const [matchOddBetProfit, setmatchOddBetProfit] = useState(0)
  const [matchOddBetLoss, setmatchOddBetLoss] = useState(0)
  const [matchOddBetProfitLay, setmatchOddBetProfitLay] = useState(0)
  const [matchOddBetLossLay, setmatchOddBetLossLay] = useState(0)
  const [bookMBetProfit, setBookMBetProfit] = useState(0)
  const [bookMBetLoss, setBookMBetLoss] = useState(0)
  const [fancyBetProfit, setfancyBetProfit] = useState(0)
  const [fancyBetLoss, setfancyBetLoss] = useState(0)
  const [openBetTable, setOpenBetTable] = useState([]);
  const [FancybookbtnArray, setFancyBookBtnArray] = useState([]);
  const [accumulatedAmountStack, setAccumulatedAmountStack] = useState(new Map());
  const [savedBetId, setSaveBetId] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  // const [betSend, setBetSend] = useState(false)
  const [betStart, setBetStart] = useState(false);
  const [totalMatched, setTotalMatched] = useState('0')
  const [spanId, setSpanId] = useState('')
  const [fancyGameStatus, setFancyGameStatus] = useState('')

  const [apiInplay, setApiInPlay] = useState(false);



  var betType;
  var oddType;
  var eve;
  var mop = {}



  if (clickedTable == "OddsDiv") {
    betType = "Matchodds";
    oddType = 1;
    eve = filteredMatch[0].eventName;


  } else if (clickedTable == "bookmakerDiv") {
    betType = "BookMaker";
    oddType = 2;
    eve = filteredMatch[0].eventName;
  } else {
    betType = "Fancy";
    oddType = 3;
    eve = teamName;
  }


  useEffect(() => {

    fetchData();
    // fetchBet()
    tableApi();
    getStackLimit();
    fetchLastBets();

  }, [gameId, eid]);

  useEffect(() => {
    fetchBet()
    allCalculatedBet();
    fetchLastBets();
    // tableApi();
    setBetSend(false)
  }, [betSend])


  useEffect(() => {

    if (clickedTable == "OddsDiv") {

      if (type == "back") {
        const calc = ((clickedType - 1).toFixed(2)) * stakeValue
        setLossData(-1 * stakeValue)
        setProfitData(calc)
      } else {
        const calc = ((clickedType - 1).toFixed(2)) * stakeValue
        setLossData(-1 * calc)
        setProfitData(stakeValue)
      }
    } else if (clickedTable == "bookmakerDiv") {
      if (type == "back") {
        const newStake = stakeValue / 100
        const calc = clickedType * newStake
        setLossData(-1 * stakeValue)
        setProfitData(calc)
      } else {
        const newStake = stakeValue / 100
        const calc = clickedType * newStake
        setLossData(-1 * calc)
        setProfitData(stakeValue)
      }
    } else if (clickedTable == "fancyTableSHow") {
      const calc = stakeValue * (fancyPrice / 100)

      if (type == "back") {

        setLossData(-1 * stakeValue)
        setProfitData(calc)
      } else {
        setLossData(-1 * calc)
        setProfitData(stakeValue)
      }

    }

    //console.log("set clickedType state value is : " + clickedType)

  }, [type, stakeValue, clickedType, clickedTable, fancyPrice])

  // Call tableApi when apiInplay is true
  useEffect(() => {
    let intervalId;

    if (apiInplay) {
      intervalId = setInterval(() => {
        console.log('apiinplay : ', apiInplay, " type of : ", typeof (apiInplay));
        tableApi();
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [apiInplay]);

  useEffect(() => {
    setTimeout(() => {
      fetchBet();
    }, 2000)
  }, [minmaxStack, openBetTable, filteredMatch])


  const tableApi = async () => {
    try {
      console.log('Calling table Api ....')
      const response = await fetch(`https://api.s2bet.in/checkGetMatchOdds/${marketId}/${gameId}`);
      const result = await response.json();

      ////console.log("Table api data of matchOdds : ", result);

      if (result.isSuccess) {

        if (Array.isArray(result.Odds)) {
          const firstOddsItem = result.Odds[0];
          setApiInPlay(firstOddsItem.inplay)
          console.log('firstOddsItem.inplay : ', firstOddsItem.inplay, ' type of : ', typeof (firstOddsItem.inplay))
          setTotalMatched(firstOddsItem.totalMatched)

          if (Array.isArray(firstOddsItem.runners) && isMO != 0) {
            const runnersData = firstOddsItem.runners;
            setOdds(runnersData);
          } else {
            console.error('No runners data available.');
          }
        } else {
          console.error('Invalid data format:', result);
        }

        if (Array.isArray(result.Bookmaker) && isBM != 0) {
          //console.log("BookMaker data:", JSON.stringify(result.Bookmaker));
          setBM(result.Bookmaker);
        } else {
          console.error('No Bookmaker data available.');
        }

        if (Array.isArray(result.Fancy) && isFancy != 0) {
          //console.log("Fancy data:", JSON.stringify(result.Fancy));
          setFancy(result.Fancy);
        } else {
          console.error('No Fancy data available.');
        }

      }


    } catch (error) {
      console.error('Error fetching Table API data:', error);
    }
  };


  function generateRandomAlphaNumeric(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  const fetchLastBets = async () => {
    try {
      const response = await fetch(`https://api.s2bet.in/fetchLastBet/${userId}/${gameId}`);
      const result = await response.json();
      //console.log("Bet result : " + JSON.stringify(result))
      if (result.isSuccess) {
        setOpenBetTable(result.data)
      }

    } catch (error) {
      console.error("Failed to fetch last bets:", error);
    }
  }


  // // Example: Generate a random alphanumeric string of length 8
  // const randomString = generateRandomAlphaNumeric(8);
  // //console.log(randomString);

  const fetchBet = async () => {
    console.log("calling fetchbet..")
    try {
      const response = await fetch(`https://api.s2bet.in/fetchLastBet/${userId}/${gameId}`);
      const result = await response.json();
      //console.log("Bet result : " + JSON.stringify(result))

      if (result.isSuccess && result.data.length > 0) {

        const filteredData = result.data;

        // NEXT TEAM For BACK /////////////

        const filterODD = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "back" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgainForOdd = odds.filter(item => !filterODDSelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

        // //console.log("Same Id data: " + JSON.stringify(selectionItems))

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionId = {};
        const totalLossBySelectionId = {};

        // Calculate net profit for each selectionId
        combinedArrayForOdd.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
            totalProfitBySelectionId[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
            totalLossBySelectionId[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionId[selectionId] += profit;
          totalLossBySelectionId[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionId = {};
        Object.keys(totalProfitBySelectionId).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

          const profit = totalProfitBySelectionId[selectionId];
          // const loss = totalLossBySelectionId[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
        });

        // NEXT TEAM For LAY /////////////

        const filterODDLay = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "Matchodds" &&
            item.type == "lay" &&
            odds.some(oddsitem => oddsitem.selectionId == item.selectionId);
        });

        // Get unique selectionIds from filterODDLay
        const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterOddAgain = odds.filter(item => !filterODDLaySelectionIds.has(item.selectionId));

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

        //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLay = {};
        const totalLossBySelectionIdLay = {};

        // Calculate net profit for each selectionId
        combinedArray.forEach(item => {
          const selectionId = item.selectionId;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLay[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLay[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLay[selectionId] += loss;
          totalLossBySelectionIdLay[selectionId] += profit;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLay = {};
        Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLay[selectionId];
          // const loss = totalLossBySelectionIdLay[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
        });

        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitMatchOdd = {};

        Object.keys(netProfitBySelectionId).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
        });

        if (Object.keys(netProfitBySelectionId).length === 0) {
          // Object is empty
          Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            //console.log("Selection ID of final profit match odd: ", selectionId);
            finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
          });
        }
        // console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

        setmatchOddBetProfit(finalProfitMatchOdd)
        mop = finalProfitMatchOdd;
        // setmatchOddBetLoss(finalLossMatchOdd)


        // NEXT TEAM For BACK /////////////

        const filterBook = filteredData.filter(item => {

          return item.market == "BookMaker" &&
            item.type == "back" &&
            bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));
          // bm.some(bmitem => bmitem.sid == item.selectionId);
        });

        //console.log("Next team filter back bookMaker : " + JSON.stringify(filterBook))

        // Get unique selectionIds from filterODDLay
        const filterBookSelectionIds = new Set(filterBook.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgainForBooks = bm.filter(item => !filterBookSelectionIds.has(item.sid));

        const filterBookAgainForBook = filterBookAgainForBooks.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLossBook = filterBookAgainForBook.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));

        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayForBook = [...filterBook, ...filterBookAgainWithProfitLossBook];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdBook = {};
        const totalLossBySelectionIdBook = {};

        // Calculate net profit for each selectionId
        combinedArrayForBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;

          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdBook[selectionId] += profit;
          totalLossBySelectionIdBook[selectionId] += loss;
        });

        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdBook = {};
        Object.keys(totalProfitBySelectionIdBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdBook)
            .filter(otherSelectionId => otherSelectionId != selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdBook[selectionId];
          // const loss = totalLossBySelectionIdBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        // NEXT TEAM For LAY /////////////

        const filterBookLayNextTeam = filteredData.filter(item => {
          // Check if the market is "Matchodds", type is "back", 
          // and there is at least one matching selectionId in the odds array
          return item.market == "BookMaker" &&
            item.type == "lay" &&
            bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));
        });

        // Get unique selectionIds from filterODDLay
        const filterBookLaySelectionIds = new Set(filterBookLayNextTeam.map(item => item.selectionId));

        // Filter items from odds based on selectionId not present in filterODDLay
        const filterBookAgains = bm.filter(item => !filterBookLaySelectionIds.has(item.sid));
        //console.log("filter Book Again : " + JSON.stringify(filterBookAgains.flat()));

        const filterBookAgain = filterBookAgains.flat();

        // Add profit = 0 and loss = 0 to the items in filterOddAgain
        const filterBookAgainWithProfitLoss = filterBookAgain.map(item => ({
          ...item,
          profit: 0,
          liability: 0,
        }));


        // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
        const combinedArrayBook = [...filterBookLayNextTeam
          , ...filterBookAgainWithProfitLoss];

        // Create an object to store total profit for each selectionId
        const totalProfitBySelectionIdLayBook = {};
        const totalLossBySelectionIdLayBook = {};

        // Calculate net profit for each selectionId
        combinedArrayBook.forEach(item => {
          const selectionId = item.selectionId || item.sid;
          const profit = item.profit;
          const loss = item.liability;


          // If selectionId is not in the object, add it
          if (!totalProfitBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalProfitBySelectionIdLayBook[selectionId] = 0;
          }

          // If selectionId is not in the object, add it
          if (!totalLossBySelectionIdLayBook.hasOwnProperty(selectionId)) {
            totalLossBySelectionIdLayBook[selectionId] = 0;
          }

          // Add profit to the total for this selectionId
          totalProfitBySelectionIdLayBook[selectionId] += loss;
          totalLossBySelectionIdLayBook[selectionId] += profit;
        });

        //console.log("total after combined profit : ", totalProfitBySelectionIdLayBook)
        //console.log("total after combined loss : ", totalLossBySelectionIdLayBook)


        // Calculate the net profit (profit - loss) for each selectionId
        const netProfitBySelectionIdLayBook = {};
        Object.keys(totalProfitBySelectionIdLayBook).forEach(selectionId => {
          // Calculate total loss to other selectionIds
          const totalLossToOtherSelectionsBook = Object.keys(totalLossBySelectionIdLayBook)
            .filter(otherSelectionId => otherSelectionId !== selectionId)
            .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBook[otherSelectionId], 0);

          const profit = totalProfitBySelectionIdLayBook[selectionId];
          // const loss = totalLossBySelectionIdLayBook[selectionId];

          // Subtract total loss to other selectionIds from the profit
          netProfitBySelectionIdLayBook[selectionId] = profit + totalLossToOtherSelectionsBook;
        });


        //console.log("Total Profit by SelectionId Book: ", netProfitBySelectionIdBook)
        //console.log("Total Loss by SelectionId Book: ", totalLossBySelectionIdBook)
        //console.log("Total Profit by SelectionId Lay Book: ", totalProfitBySelectionIdLayBook);
        //console.log("Total Loss by SelectionId Lay Book: ", totalLossBySelectionIdLayBook);
        //console.log("Net Profit by SelectionId Lay Book: ", netProfitBySelectionIdLayBook);

        // const finalProfitMatchOdd = netProfitBySelectionId + netProfitBySelectionIdLay
        // // const finalLossMatchOdd = totalLossBySelectionId + netProfitBySelectionIdLay

        // //console.log("Profit " + finalProfitMatchOdd)


        // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
        const finalProfitBook = {};

        Object.keys(netProfitBySelectionIdBook).forEach(selectionId => {
          // Sum the corresponding values for each selectionId
          finalProfitBook[selectionId] = (netProfitBySelectionIdBook[selectionId] || 0) + (netProfitBySelectionIdLayBook[selectionId] || 0);
        });

        setBookMBetProfit(finalProfitBook)
        // setmatchOddBetLoss(finalLossMatchOdd)
      } else {
        setmatchOddBetProfit(0)
        setBookMBetProfit(0)
      }



    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const allCalculatedBet = async () => {

    try {
      const response = await fetch(`https://api.s2bet.in/GetBetHistory1/${userId}`);
      const result = await response.json();
      //console.log("Bet result : " + JSON.stringify(result))

      // Filter by date and Statu s
      const filteredData = result.data.filter(item =>
        item.eventId == gameId && item.isSettlement == 2 && item.isDelete == 0
      );

      const filterFancyAll = filteredData.filter(item => item.market == "Fancy" && fancy.some(facnyitem => facnyitem.sid == item.selectionId))

      // //console.log("filterFacnyAll data : " + JSON.stringify(filterFancyAll))

      // Initialize a Map to store the accumulated amountStack for each selectionId
      const amountStackMap = new Map();

      // Iterate through the filterFancyAll array
      filterFancyAll.forEach(item => {
        const { selectionId, amountStake } = item;

        // If the selectionId is not in the map, add it with the current amountStack
        if (!amountStackMap.has(selectionId)) {
          amountStackMap.set(selectionId, amountStake || 0);
        } else {
          // If the selectionId is already in the map, accumulate the amountStack
          const currentAmountStack = amountStackMap.get(selectionId);
          amountStackMap.set(selectionId, currentAmountStack + (amountStake || 0));
        }
      });

      // Update the state with the accumulated amountStack
      setAccumulatedAmountStack(new Map(amountStackMap));
      //console.log("Calculated Bets of fancy : " + new Map(amountStackMap))

    } catch (error) {
      console.error('Error fetching allCalculatedBet funciton:', error);
    }
  }

  const openFancyBtn = async (id) => {

    try {
      const response = await fetch(`https://api.s2bet.in/GetBetHistory1/${userId}`);
      const result = await response.json();
      // //console.log("Bet result : " + JSON.stringify(result))

      // Filter by date and Statu s
      const filteredData = result.data.filter(item =>
        item.eventId == gameId && item.isSettlement == 2 && item.isDelete == 0
      );

      //console.log("Fanncy filteredData : ", filteredData)
      //console.log("fancy state : ", fancy)
      // bm.some(innerArray => innerArray.some(bmitem => bmitem.sid == item.selectionId));

      const filterFancyAll = filteredData.filter(item => item.market == "Fancy" && fancy.some(innerArray => innerArray.some(facnyitem => facnyitem.SelectionId == item.selectionId)))

      //console.log("filterFacnyAll data : " + JSON.stringify(filterFancyAll))

      const filterByIdAll = filterFancyAll.filter(item => item.selectionId == id)

      //console.log("Fancy filter ALl : " + JSON.stringify(filterByIdAll))


      // Find the minimum and maximum oddsRequest in the group
      const minOddsRequest = Math.min(...filterByIdAll.map(item => item.oddsRequest));
      const maxOddsRequest = Math.max(...filterByIdAll.map(item => item.oddsRequest));



      // BET CALCULATION FOR BACK !!!!!!!!!

      const filterByBack = filterByIdAll.filter(item => item.type == "back")

      //console.log("Fancy filter for Back : " + JSON.stringify(filterByBack))


      // Group items by id
      const groupedByIdBack = {};
      filterByBack.forEach(item => {
        if (!groupedByIdBack[item.id]) {
          groupedByIdBack[item.id] = [];
        }
        groupedByIdBack[item.id].push(item);
      });

      // Calculate the range and set the length dynamically
      const rangeBack = maxOddsRequest - minOddsRequest + 10;

      // Create FancyBookArray for each group
      const resultArraysBack = [];
      const calcArrayBack = []
      Object.keys(groupedByIdBack).forEach(groupId => {
        const groupItems = groupedByIdBack[groupId];


        const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
          const request = index + minOddsRequest - 5;
          const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;

          return { request, pl };
        });

        resultArraysBack.push({ id: groupId, FancyBookArray });
      });

      // //console.log("Result Arrays For Back:", resultArraysBack);

      const redcBack = resultArraysBack.reduce((acc, current) => {
        current.FancyBookArray.forEach((entry) => {
          const existingEntry = acc.find((item) => item.request == entry.request);

          if (existingEntry) {
            existingEntry.pl += entry.pl;
          } else {
            acc.push({ request: entry.request, pl: entry.pl });
          }
        });

        return acc;
      }, []);

      // //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))



      // BET CALCULATION FOR LAY !!!!!!!!!

      const filterByLay = filterByIdAll.filter(item => item.type == "lay")

      //console.log("Fancy filter for Lay : " + JSON.stringify(filterByLay))


      // Group items by id
      const groupedById = {};
      filterByLay.forEach(item => {
        if (!groupedById[item.id]) {
          groupedById[item.id] = [];
        }
        groupedById[item.id].push(item);
      });

      // Calculate the range and set the length dynamically
      const range = maxOddsRequest - minOddsRequest + 10;

      // Create FancyBookArray for each group
      const resultArrays = [];
      const calcArray = []
      Object.keys(groupedById).forEach(groupId => {
        const groupItems = groupedById[groupId];


        const FancyBookArray = Array.from({ length: range }, (_, index) => {
          const request = index + minOddsRequest - 5;
          const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;

          return { request, pl };
        });

        resultArrays.push({ id: groupId, FancyBookArray });
      });

      //console.log("Result Arrays:", resultArrays);

      const redc = resultArrays.reduce((acc, current) => {
        current.FancyBookArray.forEach((entry) => {
          const existingEntry = acc.find((item) => item.request == entry.request);

          if (existingEntry) {
            existingEntry.pl += entry.pl;
          } else {
            acc.push({ request: entry.request, pl: entry.pl });
          }
        });

        return acc;
      }, []);

      //console.log("Calculated Array for lay : " + JSON.stringify(redc))
      //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))


      let finalCalculatedArray = []
      if (redcBack.length > 0) {

        // Combine redcBack and redc to create the final calculated array
        finalCalculatedArray = redcBack.map((entryBack) => {
          const entryLay = redc.find((entry) => entry.request == entryBack.request);

          if (entryLay) {
            entryBack.pl += entryLay.pl;
          }

          return entryBack;
        });
      } else {
        finalCalculatedArray = redc
      }

      //console.log("Final Calculated Array : " + JSON.stringify(finalCalculatedArray));

      setFancyBookBtnArray(finalCalculatedArray)


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handlePlaceBet1 = () => {

    const spanIndex = document.getElementById(spanId)
    const spanValue = Number(spanIndex.textContent);
    console.log('span text value is : ', spanValue)

    console.log('odd value is : ', clickedType)

    if (clickedType == spanValue) {
      if (clickedTable == 'fancyTableSHow') {
        if (fancyGameStatus != '') {
          const gameStatus = document.getElementById(fancyGameStatus);
          if (gameStatus.style.display == 'block') {
            const modalId = document.getElementById('openBetSlipM')
            $(modalId).modal('hide');
            toast.error("Ball Running..", {
              duration: 1000
            });
            return;
          } else {
            if (clickedTable == "OddsDiv") {

              if (stakeValue < minmaxStack[0].minOddLimit || stakeValue > minmaxStack[0].maxOddLimit) {

                //console.log('Enter Stake in your Odds limit')
                const modalId = document.getElementById('openBetSlipM')
                $(modalId).modal('hide');

                $('#error').modal('show');
                $('#errormes').html(`Enter Stake between ${minmaxStack[0].minOddLimit} to ${minmaxStack[0].maxOddLimit}`)
                setTimeout(() => {
                  $('#error').modal('hide');
                }, 2000);
              } else {
                sendBet();
              }
            } else if (clickedTable == "bookmakerDiv") {

              if (stakeValue < minmaxStack[0].minBookmakerLimit || stakeValue > minmaxStack[0].maxBookmakerLimit) {

                //console.log('Enter Stake in your Bookmaker limit')
                const modalId = document.getElementById('openBetSlipM')
                $(modalId).modal('hide');

                $('#error').modal('show');
                $('#errormes').html(`Enter Stake between ${minmaxStack[0].minBookmakerLimit} to ${minmaxStack[0].maxBookmakerLimit}`)
                setTimeout(() => {
                  $('#error').modal('hide');
                }, 2000);

              } else {
                sendBet();
              }

            } else if (clickedTable == "fancyTableSHow") {

              if (stakeValue < minmaxStack[0].minFancyLimit || stakeValue > minmaxStack[0].maxFancyLimit) {
                const modalId = document.getElementById('openBetSlipM')
                $(modalId).modal('hide');

                $('#error').modal('show');
                $('#errormes').html(`Enter Stake between ${minmaxStack[0].minFancyLimit} to ${minmaxStack[0].maxFancyLimit}`)
                setTimeout(() => {
                  $('#error').modal('hide');
                }, 2000);

              } else {
                sendBet();
              }
            }
          }
        }
      }


      if (clickedTable != 'fancyTableSHow') {

        if (clickedTable == "OddsDiv") {

          if (stakeValue < minmaxStack[0].minOddLimit || stakeValue > minmaxStack[0].maxOddLimit) {

            const modalId = document.getElementById('openBetSlipM')
            $(modalId).modal('hide');
            $('#error').modal('show');
            $('#errormes').html(`Enter Stake between ${minmaxStack[0].minOddLimit} to ${minmaxStack[0].maxOddLimit}`)
            setTimeout(() => {
              $('#error').modal('hide');
            }, 2000);
          } else {
            sendBet();
          }
        } else if (clickedTable == "bookmakerDiv") {

          if (stakeValue < minmaxStack[0].minBookmakerLimit || stakeValue > minmaxStack[0].maxBookmakerLimit) {

            const modalId = document.getElementById('openBetSlipM')
            $(modalId).modal('hide');
            $('#error').modal('show');
            $('#errormes').html(`Enter Stake between ${minmaxStack[0].minBookmakerLimit} to ${minmaxStack[0].maxBookmakerLimit}`)
            setTimeout(() => {
              $('#error').modal('hide');
            }, 2000);
          } else {
            sendBet();
          }

        } else if (clickedTable == "fancyTableSHow") {

          if (stakeValue < minmaxStack[0].minFancyLimit || stakeValue > minmaxStack[0].maxFancyLimit) {

            const modalId = document.getElementById('openBetSlipM')
            $(modalId).modal('hide');
            $('#error').modal('show');
            $('#errormes').html(`Enter Stake between ${minmaxStack[0].minFancyLimit} to ${minmaxStack[0].maxFancyLimit}`)
            setTimeout(() => {
              $('#error').modal('hide');
            }, 2000);
          } else {
            sendBet();
          }
        }
      }
    } else {
      const modalId = document.getElementById('openBetSlipM')
      $(modalId).modal('hide');
      toast.error("Odds Changed!", {
        duration: 1000
      });
    }
  }

  const handlePlaceBet = () => {
    const spanIndex = document.getElementById(spanId);
    const spanValue = Number(spanIndex.textContent);
    console.log('span text value is : ', spanValue);
    console.log('odd value is : ', clickedType);

    if (clickedType != spanValue) {
      hideModal('openBetSlipM');
      showToast("Odds Changed!", 1000);
      return;
    }

    const showErrorModal = (message) => {
      hideModal('openBetSlipM');
      $('#error').modal('show');
      $('#errormes').html(message);
      setTimeout(() => $('#error').modal('hide'), 2000);
    };

    const checkStakeLimits = (minLimit, maxLimit) => {
      if (stakeValue < minLimit || stakeValue > maxLimit) {
        showErrorModal(`Enter Stake between ${minLimit} to ${maxLimit}`);
        return false;
      }
      return true;
    };

    if (clickedTable === 'fancyTableSHow' && fancyGameStatus !== '') {
      const gameStatus = document.getElementById(fancyGameStatus);
      if (gameStatus.style.display === 'block') {
        hideModal('openBetSlipM');
        showToast("Ball Running..", 1000);
        return;
      }
    }

    let limits = {};
    switch (clickedTable) {
      case 'OddsDiv':
        limits = { min: minmaxStack[0].minOddLimit, max: minmaxStack[0].maxOddLimit };
        break;
      case 'bookmakerDiv':
        limits = { min: minmaxStack[0].minBookmakerLimit, max: minmaxStack[0].maxBookmakerLimit };
        break;
      case 'fancyTableSHow':
        limits = { min: minmaxStack[0].minFancyLimit, max: minmaxStack[0].maxFancyLimit };
        break;
      default:
        return;
    }

    if (checkStakeLimits(limits.min, limits.max)) {
      sendBet();
    }
  };

  const sendBet = async () => {
    try {
      console.log('Bets sent func call....')
      setIsLoading(true);
      const matchedTime = convertToIST(new Date());
      const betDetails = {
        BetId: generateRandomAlphaNumeric(30),
        SportId: parseInt(eid),
        EventId: parseInt(gameId),
        Event: eve,
        MarketId: fileredMarketId[0],
        Market: betType,
        SelectionId: teamselectionId,
        Selection: teamName,
        OddsType: oddType,
        Type: type,
        OddsRequest: parseFloat(clickedType),
        AmountStake: stakeValue,
        BetType: 2,
        IsSettlement: 2,
        IsDelete: 0,
        Status: true,
        UserId: parseInt(userId),
        ResultType: null,
        ResultAmount: 0,
        IpAddress: userIp,
        IsMatched: true,
        EventName: filteredMatch[0].eventName,
        Profit: profitData,
        Liability: lossData,
        MatchedTime: matchedTime,
        settleTime: null,
        PlaceTime: convertToIST(new Date()),
      };

      const response = await fetch('https://api.s2bet.in/saveBet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(betDetails),
      });

      const result = await response.json();
      if (result.isSuccess) {
        console.log('Bet Sent..');
        setSaveBetId(result.betId);
        fetchExposure(result.betId);
      } else {
        setIsLoading(false);
        showErrorModal('Bet Not Placed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during Place Bet button Api:', error);
      showErrorModal('Bet Not Placed');
    }
  };


  // const showErrorModal = (message) => {
  //   const openBetSlipModal = document.getElementById('openBetSlipM');
  //   const errorModal = document.getElementById('error');
  //   const errorMessage = document.getElementById('errormes');
  //   const modalId = document.getElementById('openBetSlipM')
  //   $(modalId).modal('hide');
  //   $('#error').modal('show');
  //   $('#errormes').html(message);
  //   console.log('showErrorModal Open..')
  //   // setmatchOddBetProfit(0)
  //   setTimeout(() => {
  //     // setIsLoading(false); // Set loading state back to false after the timeout
  //     $('#error').modal('hide');
  //     console.log('showErrorModal hide..')
  //   }, 2000);
  // };

  // const showSuccessModal = () => {
  //   // const openBetSlipModal = document.getElementById('openBetSlipM');
  //   // const successModal = document.getElementById('success');
  //   const modalId = document.getElementById('openBetSlipM')
  //   $(modalId).modal('hide');
  //   $('#success').modal('show');
  //   console.log('showSuccessModal Open..')
  //   setTimeout(() => {
  //     $('#success').modal('hide');
  //     console.log('showSuccessModal hide..')
  //     // setMatchDelaytime(null)
  //   }, 2000);
  //   // window.l
  // };

  const fetchExposure = async (betId) => {
    try {
      console.log("Fetch exposure START at event ...");

      const responseBal = await fetch(`https://api.s2bet.in/expo2/${userId}`);
      const resultBal = await responseBal.json();

      if (resultBal.isSuccess) {
        const apiBalance = resultBal.mainBalancebeforeExpo;
        const exposure = resultBal.FinalExpo;
        const calcBalance = apiBalance + exposure;
        const exposureLimit = decryptCookie('exposure');

        if (calcBalance <= 0) {
          await handleInsufficientBalance(betId);
        } else if ((exposure * -1) <= exposureLimit) {
          await handleValidExposure(betId, exposure);
        } else {
          await handleExposureLimitExceeded(betId);
        }
      } else {
        handleApiError();
      }

      console.log("Fetch exposure END at event ...");
    } catch (error) {
      handleApiError(error);
    }
  };

  //For Insufficient Balance And call Delete Bet
  const handleInsufficientBalance = async (betId) => {
    setIsLoading(false);
    showErrorModal('Insufficient Balance!');
    await deleteBet(betId);
  };

  // For call Update Bet
  const handleValidExposure = async (betId, exposure) => {
    await updateBet(betId, exposure);
  };

  const handleExposureLimitExceeded = async (betId) => {
    setIsLoading(false);
    showErrorModal('Exposure Limit Exceed!');
    await deleteBet(betId);
  };

  const handleApiError = (error) => {
    setIsLoading(false);
    console.error('Error during fetchExposure api', error);
    showErrorModal('Bet Not Placed');
  };

  const deleteBet = async (betId) => {
    try {
      const deleteQuery = await fetch(`https://api.s2bet.in/deleteUnmatchBet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId })
      });

      const queryResp = await deleteQuery.json();
      if (!queryResp.isSuccess) {
        console.error('Error in deleting bet');
      }
    } catch (error) {
      console.error('Error in deleteBet function', error);
    }
  };

  const updateBet = async (betId, exposure) => {
    try {
      const updateQuery = await fetch(`https://api.s2bet.in/updateUnmatchBet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, BetId: betId, exposure })
      });

      const queryResp = await updateQuery.json();
      if (queryResp.isSuccess) {
        setTimeout(async () => {
          setIsLoading(false);
          setupdateBetId(betId);
          setBetSend(true);
          showSuccessModal();
          setStakeValue(0);
          console.log('Bet Updated..');
        }, betDelay * 1000);
      } else {
        setIsLoading(false);
        showErrorModal('Bet Not Placed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error in updateBet function', error);
      showErrorModal('Bet Not Placed');
    }
  };

  const showErrorModal = (message) => {
    hideModal('openBetSlipM');
    showModal('error');
    updateModalMessage('errormes', message);
    setTimeout(() => hideModal('error'), 2000);
  };

  const showSuccessModal = () => {
    hideModal('openBetSlipM');
    showModal('success');
    setTimeout(() => hideModal('success'), 2000);
  };

  const hideModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    $(modalElement).modal('hide');
  };

  const showModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    $(modalElement).modal('show');
  };

  const showToast = (message, duration) => {
    toast.error(message, { duration });
  };

  const updateModalMessage = (elementId, message) => {
    const element = document.getElementById(elementId);
    element.innerHTML = message;
  };


  const getStackLimit = async () => {
    try {
      const response = await fetch(`https://api.s2bet.in/GetStackLimit1/${gameId}/${eid}`);
      const result = await response.json();

      if (Array.isArray(result.data.sports) && Array.isArray(result.data.userMatchSettings)) {
        const sportsData = [...result.data.sports];
        const userMatchData = [...result.data.userMatchSettings];

        setSportsStack(sportsData);
        setuserMatchStack(userMatchData);

        const filterGameId = userMatchData.filter(item => item.gameId == gameId);
        const filterEid = sportsData.filter(item => item.id == eid);

        setBetDelay(filterEid[0]?.betDelayTime || 0);
        setBookMDelay(filterEid[0]?.bookmakerDelayTime || 0);
        setFancyDelay(filterEid[0]?.fancyDelayTime || 0);

        if (filterGameId.length !== 0) {
          setMinMaxStack(filterGameId);
        } else {
          setMinMaxStack(filterEid);
        }
      }
    } catch (error) {
      console.error("Error in fetching GetStackLimit api", error);
    }
  };


  const fetchData = async () => {
    try {
      const response = await fetch(`https://api.s2bet.in/GetInPlaySportEventsByEventId/${gameId}`);
      const result = await response.json();

      const responseByEid = await fetch(`https://api.s2bet.in/GetInPlaySportEventsByEid/${eid}`);
      const resultByEid = await responseByEid.json();

      //console.log("Inplay api before combine : ", JSON.stringify(result.data.sportsEventModelTomorrow))

      if (result.isSuccess) {
        // Combine the three arrays into a single array
        const combinedDatas = [...result.data, ...resultByEid.data];

        //// console.log('Event Comibinde data : ' + JSON.stringify(combinedDatas))

        const combinedData = combinedDatas.flat();
        //// console.log('Event Flat data : ' + JSON.stringify(combinedData))

        const filteredData = combinedData.filter(item => item.gameId == gameId);
        ////console.log("gameId " + JSON.stringify(filteredData))
        const filteredEid = combinedData.filter(item => item.eid == eid);
        //console.log('Eid: ', JSON.stringify(filteredEid))


        //console.log("Filtered data back home : ", filteredData)

        //console.log("gameId length : ",filteredData.length)
        if (filteredData.length > 0 && filteredData[0].Status == 1 && filteredData[0].ActiveMatch == 1) {

          // Extracting eventName values from the filteredEid array
          const eventNameArray = filteredEid.map(item => {
            return {
              matchName: item.eventName,
              gameId: item.gameId,
              marketId: item.marketId,
              eid: item.eid
            }
          });
          const eventMarketId = filteredEid.map(item => item.marketId)

          const eventDate = new Date(filteredData[0].eventDate);
          const betStartDateTime = new Date(eventDate.getTime() - betStartTime * 60000);

          //console.log("EventDate is : ", eventDate)
          //console.log("Bet start time : ", betStartDateTime);

          if (new Date() > betStartDateTime) {
            setBetStart(true);
          } else {
            setBetStart(false);
          }

          //console.log('Filtered Event Market: ', eventMarketId);
          //console.log('Filtered Event Name: ', eventNameArray);


          // //console.log('filter : ' + JSON.stringify(filteredData[0].eventName))

          setFilterMatch(filteredData)
          setfilterEventNames(eventNameArray)
          setfilterMarketId(eventMarketId)

          setData(combinedData);
          setBetSend(true);
        } else {
          window.location.href = '/'
        }

      } else {
        console.error('Event Not Found in GetInPlaySportsevent :', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function openTable() {
    var maindivbets = document.getElementById("maindivbets");
    var currentDisplayStyle = window.getComputedStyle(maindivbets).display;

    // Toggle between "block" and "none"
    maindivbets.style.display = (currentDisplayStyle == "none") ? "block" : "none";
  }

  function checkIframe() {
    var tvformobilediv = document.getElementsByClassName("tvformobilediv")[0];

    if (tvformobilediv) {
      var currentDisplayStyle = window.getComputedStyle(tvformobilediv).display;

      // Toggle between "block" and "none"
      tvformobilediv.style.display = currentDisplayStyle == "none" ? "block" : "none";
    }
  }

  const betLockNotify = () => {
    toast.error("You are not able to place bets..", {
      duration: 1000
    })

  }

  const openBetSlip = (team, odd, type, table, fancyyPrice, selectionId, spanIndex, gameStatus) => {

    if (isBetlock == 1) {

      //console.log("Bets is locked ....")

    } else {

      var betSlip = document.getElementById('placeBetSilp');
      var betSlipInput = betSlip.querySelector('#ShowBetPrice'); // Replace 'betSlipInput' with the actual id of your input
      var betTeam = document.getElementById('betTeamName')
      var lossData = document.getElementById('LossData')
      var stakeInput = document.getElementById('stakeValue');

      const modalId = document.getElementById('openBetSlipM');

      $(modalId).on('hidden.bs.modal', function () {
        // Assuming setStakeValue is a state updater function
        setStakeValue(0);
        //console.log("Modal hide ")
      });

      // var currentDisplayStyle = window.getComputedStyle(betSlip).display;
      // betSlip.style.display = currentDisplayStyle == "none" ? "block" : "none";
      // betSlip.style.display = "block"

      // setTimeout(() => {
      //   betSlip.style.display = "none"
      // }, 5000)

      // Set the input value


      setSpanId(spanIndex)
      if (gameStatus != '' || gameStatus != undefined) {
        setFancyGameStatus(gameStatus)
      }



      if (table == "bookmakerDiv") {
        betSlipInput.value = odd / 100
      } else {
        betSlipInput.value = odd;
      }
      betTeam.innerHTML = team;
      const convertNum = parseInt(fancyyPrice)
      setType(type)
      setClickedType(odd)
      setClickedTable(table)
      setFancyPrice(convertNum)
      setBetTeamName(team)
      setTeamSelectionId(selectionId)


      //console.log("clicked data is : " + JSON.stringify(odd))
      //console.log("clicked team is : " + JSON.stringify(team))
      //console.log("clicked type is : " + JSON.stringify(type))
      //console.log("clicked table is : " + table)
      //console.log("clicked Fancy Price is : " + parseInt(convertNum) + " " + typeof (convertNum))
      //console.log("clicked table is selection Id : " + selectionId)

      // Set a new timeout to hide the bet slip after 5 seconds
      // betSlipTimeout = setTimeout(() => {
      //   betSlip.style.display = 'none';
      // }, 5000);

      // Scroll to the element with ID "placeBetSlip"
      // const placeBetSlipElement = document.getElementById('placeBetSlip');
      // if (placeBetSlipElement) {
      //   placeBetSlipElement.scrollIntoView({ behavior: 'smooth' });
      // }
      // betSlip.scrollIntoView({ behavior: 'smooth' });


    }


  };

  const handleStakeChange = (event) => {
    const newStakeValue = parseFloat(event.target.value) || 0;
    setStakeValue(newStakeValue);
  };

  function handleToggleStyle(element) {
    if (element) {
      const anchorTag = document.querySelector('a');
      const isActive = document.getElementsByClassName('active');

      if (isActive) {
        anchorTag.style.background = '#83a95c !important';
        var maindivbets = document.getElementById("maindivbets");
        maindivbets.style.display = "block"
        // openTable();
      } else {
        anchorTag.style.background = '';
      }
    }
  }

  const handleStakeButtonClick = (name) => {
    // Extract the number from the name (e.g., "Name1" becomes "1")
    const number = name.replace(/\D/g, '');

    // Form the corresponding value key (e.g., "Value1")
    const valueKey = `Value${number}`;

    // Get the value from chipValues using the constructed value key
    const correspondingValue = chipValues[valueKey];

    // Select the stake input element by its ID
    var stakeInput = document.getElementById('stakeValue');

    stakeInput.value = Number(correspondingValue) + Number(stakeInput.value)

    setStakeValue(stakeInput.value)

    // Log the result
    //console.log(`Stake button clicked for ${name}. Corresponding value: ${correspondingValue}`);
  };

  const handleClearStake = () => {
    // Handle logic for clearing stakes
    // Select the stake input element by its ID
    // var stakeInput = document.getElementById('stakeValue');
    // stakeInput.value = "";
    //console.log('Clear button clicked');
    setStakeValue(0)
    setProfitData(0)
    setLossData(0)
  };

  const handleIncrement = () => {
    setStakeValue((prevStakeValue) => Number(prevStakeValue) + 100);
  };

  const handleDecrement = () => {
    setStakeValue((prevStakeValue) => Math.max(prevStakeValue - 100, 0));
  };

  const handleClearAll = () => {
    const modalId = document.getElementById('openBetSlipM')
    $(modalId).modal('hide');
    setStakeValue(0)
    // var betSlip = document.getElementById('placeBetSilp');
    // betSlip.style.display = "none";
    setProfitData(0)
    setLossData(0)
    //console.log('Clear All button clicked');
  }



  return (
    <>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      {/* rest of your JSX */}
      <div className="main_container" id="sticky">
        <div className="right_col" role="main">
          <div className="fullrow tile_count">
            <div className="col-md-7">
              <div id="UpCommingData" style={{ display: "none" }} />
              <div id="MatchOddInfo">
                {/* <style
          dangerouslySetInnerHTML={{
            __html:
              "\n\t\t\t\t\t\t\t\t.block_box_btn button {\n\t\t\t\t\t\t\t\t\tmargin-right: 3px;\n\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t"
          }}
        /> */}
                <div
                  className="fullrow matchBoxMain matchBox_4087 matchBoxs_214087"
                  style={{}}
                >
                  <div className="modal-dialog-staff">
                    {/* dialog body */}
                    <div className="match_score_box">
                      <div className="modal-header mod-header">
                        <div className="block_box">
                          <span id="tital_change">
                            <span id="fav4087">
                              <i
                                className="fa fa-star-o"
                                aria-hidden="true"
                              />
                            </span>
                            {filteredMatch.length > 0 && filteredMatch[0].eventName}
                          </span>
                          <div className="block_box_btn">
                            <button
                              style={{ padding: 3 }}
                              className="btn btn-primary btn-xs"
                              data-toggle="modal"
                              data-target="#listmatch"
                            >
                              Matches
                            </button>
                          </div>
                        </div>
                      </div>
                      <iframe
                        id="animscore"
                        // src={eid != 4 ? `https://stream.1ex99.in/sportRadarScore?eventId=${gameId}` : `https://centerpanel.ons3.co/owner/score/?market_id=${marketId}`}
                        src={`https://winx777.com/score/sportRadar/?eventId=${gameId}`}
                        className="iframestyle"
                        title="Match Score"
                        style={{ display: "none" }}
                      />
                      <div className="score_area">
                        <iframe
                          id="cricketScore"
                          // src={eid != 4 ? `https://stream.1ex99.in/sportRadarScore?eventId=${gameId}` : `https://centerpanel.ons3.co/owner/score/?market_id=${marketId}`}
                          src={`https://winx777.com/score/sportRadar/?eventId=${gameId}`}
                          style={{
                            height: "100%",
                            width: "100%",
                            background: "url(/images/score-bg.png)no-repeat"
                          }}
                          className="iframestyle"
                          title="Match Score"
                        />
                      </div>
                    </div>
                    <div
                      className="matchClosedBox_214087"
                      style={{ display: "none" }}
                    >
                      <div className="fullrow fullrownew">
                        <div className="pre-text">
                          Match Odds
                          <br />
                          <span className="match-colsed"> Closed </span>
                        </div>
                        <div className="matchTime">04/08/2020 01:00 PM</div>
                      </div>
                      <div>
                        <div className="closedBox">
                          <h1>Closed</h1>
                        </div>
                      </div>
                    </div>
                    <div className="sportrow-4 matchOpenBox_214087">
                      <div className="match-odds-sec">
                        <div
                          className="item match-status"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            marginLeft: "1%",
                            color: "#000"
                          }}
                        >
                          MATCHED -
                          <span style={{ fontWeight: "bold" }} id="tmval">
                            {totalMatched}
                          </span>
                        </div>
                        <div
                          className="item match-shedule"
                          id="demo_30183211"
                          style={{
                            padding: "10px 0px",
                            textAlign: "right",
                            marginRight: "1%"
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              marginRight: "2%",
                              color: "blue"
                            }}
                            id="valumeText"
                          >
                            HIGH VOL.
                          </span>
                          <span>
                            <a
                            // style={{ cursor: "pointer" }}
                            >
                              <span
                                style={{ color: "red", fontWeight: 700 }}
                                className="inplay_txt"
                              >
                                <i
                                  className="fa fa-bullhorn"
                                  style={{ fontSize: 15, marginRight: "3%" }}
                                />{" "}
                              </span>
                            </a>
                          </span>
                          <span className="click-tv" id="tvvv">
                            <img
                              style={{ float: "none" }}
                              className="tv-icons tvformobile"
                              onClick={checkIframe}
                              src="/images/tv-screen.png"
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        className="col-lg-12 col-xs-12 tvformobilediv"
                        style={{ display: "none", padding: 0 }}
                      >
                        <iframe
                          id="tvFrame"
                          style={{ width: "100%", height: 250 }}
                          // src={`https://stream.1ex99.in/tv2?EventId=${gameId}`}
                          src={`https://crickexpo.in/tv/?eventId=${gameId}`}
                        />
                      </div>
                      <div
                        className="fullrow MatchIndentB"
                        style={{ position: "relative" }}
                      >
                        {odds?.length > 0 && (
                          <table
                            className="table table-striped bulk_actions"
                            id="OddsDiv"
                          >
                            <tbody className="" id="oddsBody">
                              <tr className="headings mobile_heading">
                                <th className="fix_heading color_red" id="oddsMinMax">
                                  {minmaxStack.length > 0 ? (
                                    `Min stake: ${minmaxStack[0]?.minOddLimit || 'N/A'} Max stake: ${minmaxStack[0]?.maxOddLimit || 'N/A'}`
                                  ) : (
                                    'Loading...' // or any loading indicator you prefer
                                  )}
                                </th>
                                <th />
                                <th />
                                <th className="back_heading_color">Back</th>
                                <th className="lay_heading_color">Lay</th>
                                <th />
                                <th />
                              </tr>
                              {odds?.map((item, index) => (
                                <tr
                                  id="user_row0"
                                  className="back_lay_color runner-row-1" key={index}  >

                                  <td>
                                    <p className="runner_text">{item.runnerName}</p>
                                    <p className="blue-odds" id="teamBook16606" style={{ color: matchOddBetProfit[item.selectionId] < 0 ? 'red' : '' }}>
                                      {/* {(index == 0 ? profitData : lossData)} */}
                                      {/* {item.selectionId == odds[0].selectionId ? matchOddBetProfit : matchOddBetLoss} */}
                                      {Number(matchOddBetProfit[item.selectionId] != null ? matchOddBetProfit[item.selectionId] : 0).toFixed()}
                                    </p>
                                  </td>


                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  {/* {item.ex.availableToBack[0].map((item)=>(

                                <td
                                  className="mark-back"
                                  onclick="betSlipLoadData(`Australia`,'16606','back','')"
                                >
                                  <span id="back16606">{item.price}</span>
                                  <span id="backPrice16606">{item.size}</span>
                                </td>
                                ))} */}
                                  <td
                                    className="mark-back"
                                    onClick={isBetlock == 1 || !betStart || item.ex.availableToBack[0].price > 3 || item.ex.availableToBack[0].price < 1.01 ? betLockNotify : () => openBetSlip(item.runnerName, item.ex.availableToBack[0].price, "back", "OddsDiv", "", item.selectionId, `back16606${index}`, '')}
                                    data-toggle={isBetlock == 1 || !betStart || item.ex.availableToBack[0].price > 3 || item.ex.availableToBack[0].price < 1.01 ? undefined : "modal"}
                                    data-target={isBetlock == 1 || !betStart || item.ex.availableToBack[0].price > 3 || item.ex.availableToBack[0].price < 1.01 ? undefined : '#openBetSlipM'}
                                  >
                                    <span id={`back16606${index}`}>{item.ex.availableToBack[0].price}</span>
                                    <span id="backPrice16606">{item.ex.availableToBack[0].size}</span>
                                  </td>
                                  {/*- availableToLay */}
                                  < td
                                    className=""
                                    onClick={isBetlock == 1 || !betStart || item.ex.availableToLay[0].price > 3 || item.ex.availableToLay[0].price < 1.01 ? betLockNotify : () => openBetSlip(item.runnerName, item.ex.availableToLay[0].price, "lay", "OddsDiv", " ", item.selectionId, `lay16606${index}`, '')}
                                    data-toggle={isBetlock == 1 || !betStart || item.ex.availableToLay[0].price > 3 || item.ex.availableToLay[0].price < 1.01 ? undefined : "modal"}
                                    data-target={isBetlock == 1 || !betStart || item.ex.availableToLay[0].price > 3 || item.ex.availableToLay[0].price < 1.01 ? undefined : '#openBetSlipM'}
                                  >
                                    <span id={`lay16606${index}`}>{item.ex.availableToLay[0].price}</span>
                                    <span id="layPrice16606">{item.ex.availableToLay[0].size}</span>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                </tr>

                              ))}

                            </tbody>
                          </table>
                        )}
                      </div>
                      <div
                        className="fullrow MatchIndentB"
                        id="bookmaker"
                        style={{ position: "relative", marginTop: "1%" }}
                      >
                        {bm?.length > 0 && (
                          <table
                            className="table table-striped bulk_actions"
                            id="bookmakerDiv"
                          >
                            <tbody className="" id="oddsBody">
                              <tr className="headings mobile_heading">
                                <th
                                  className="fix_heading color_red"
                                  id="oddsMinMax"
                                >
                                  {minmaxStack.length > 0 ? (
                                    `Min stake: ${minmaxStack[0]?.minBookmakerLimit || 'N/A'} Max stake: ${minmaxStack[0]?.maxBookmakerLimit || 'N/A'}`
                                  ) : (
                                    'Loading...' // or any loading indicator you prefer
                                  )}
                                  {/* Min stake:100 Max stake:1000 */}
                                </th>
                                <th />
                                <th />
                                <th className="back_heading_color">Back</th>
                                <th className="lay_heading_color">Lay</th>
                                <th />
                                <th />
                              </tr>
                              {bm[0]?.map((item, index) => (
                                <tr
                                  id="user_row0"
                                  className="back_lay_color runner-row-1" key={index}
                                >
                                  <td>
                                    <p className="runner_text">{item.nat}</p>
                                    <p className="blue-odds" id="teamBookmaker16606" style={{ color: bookMBetProfit[item.sid] < 0 ? 'red' : '' }}>
                                      {bookMBetProfit[item.sid] != null ? bookMBetProfit[item.sid] : 0}
                                    </p>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  {item.s != 'ACTIVE' ? <>
                                    <td
                                      className="mark-back" style={{ cursor: "none" }}

                                    >
                                      <span id={`bookMakerback16606${index}`}>{item.b1}</span>
                                      <span id="bookMakerbackPrice16606">{item.bs1}</span>
                                    </td>

                                    <td
                                      className="" style={{ cursor: "none" }}
                                    >
                                      <span id={`bookMakerlay16606${index}`}>{item.l1}</span>
                                      <span id="bookMakerlayPrice16606">{item.ls1}</span>
                                    </td>
                                  </> : <>
                                    <td
                                      className="mark-back"
                                      onClick={isBetlock == 1 || !betStart ? betLockNotify : () => openBetSlip(item.nat, item.b1, "back", "bookmakerDiv", "", item.sid, `bookMakerback16606${index}`, '')}
                                      data-toggle={isBetlock == 1 || !betStart ? undefined : "modal"}
                                      data-target={isBetlock == 1 || !betStart ? undefined : '#openBetSlipM'}
                                    >
                                      <span id={`bookMakerback16606${index}`}>{item.b1}</span>
                                      <span id="bookMakerbackPrice16606">{item.bs1}</span>
                                    </td>

                                    <td
                                      className=""
                                      onClick={isBetlock == 1 || !betStart ? betLockNotify : () => openBetSlip(item.nat, item.l1, "lay", "bookmakerDiv", "", item.sid, `bookMakerlay16606${index}`, '')}
                                      data-toggle={isBetlock == 1 || !betStart ? undefined : "modal"}
                                      data-target={isBetlock == 1 || !betStart ? undefined : '#openBetSlipM'}
                                    >
                                      <span id={`bookMakerlay16606${index}`}>{item.l1}</span>
                                      <span id="bookMakerlayPrice16606">{item.ls1}</span>
                                    </td>
                                  </>}
                                  {/* <td
                                  className="mark-back"
                                  onclick="bookMakerbetSlipLoadData(`Australia`,'16606','back','bookMaker')"
                                >
                                  <span id="bookMakerback16606">{item.b1}</span>
                                  <span id="bookMakerbackPrice16606">{item.bs1}</span>
                                </td>
                                <td
                                  className=""
                                  onclick="bookMakerbetSlipLoadData(`Australia`,'16606','lay','bookMaker')"
                                >
                                  <span id="bookMakerlay16606">{item.l1}</span>
                                  <span id="bookMakerlayPrice16606">{item.ls1}</span>
                                </td> */}

                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                  <td className="">
                                    <span id=""> </span>
                                    <span id=""> </span>
                                  </td>
                                </tr>

                              ))}

                            </tbody>
                          </table>

                        )}

                      </div>
                    </div>
                    <div className="otherMarket_4087">
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n\t\t\t\t\t\t\t\t\t\t\t.block_box_btn button {\n\t\t\t\t\t\t\t\t\t\t\t\tmargin-right: 3px;\n\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t"
                        }}
                      />
                      <div
                        className="fullrow matchBoxMain matchBox_4087 matchBoxs_11867"
                        style={{ display: "none" }}
                      >
                        <div className="modal-dialog-staff">
                          {/* dialog body */}
                          <div className="match_score_box">
                            <div className="modal-header mod-header">
                              <div className="block_box">
                                <span id="tital_change">
                                  <span id="fav4087">
                                    <i
                                      className="fa fa-star-o"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  Evergreen Cricket Club v Helsingborg Royals Sports
                                  Club
                                </span>
                                <div className="block_box_btn">
                                  <button
                                    className="btn btn-primary btn-xs"
                                  >
                                    Bets
                                  </button>
                                  <button
                                    className="btn btn-primary btn-xs"
                                  >
                                    X
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="score_area">
                              <span
                                style={{}}
                                className="matchScore"
                                id="matchScore_4087"
                              >
                                {" "}
                              </span>
                            </div>
                          </div>
                          <div
                            className="matchClosedBox_11867"
                            style={{ display: "none" }}
                          >
                            <div className="fullrow fullrownew">
                              <div className="pre-text">
                                BOOKIE BHAV 0 % COMM. 0 SEC DELAY <br />
                                <span className="match-colsed"> Closed </span>
                              </div>
                              <div className="matchTime">04/08/2020 01:00 PM</div>
                            </div>
                            <div>
                              <div className="closedBox">
                                <h1>Closed</h1>
                              </div>
                            </div>
                          </div>
                          <div className="sportrow-4 matchOpenBox_11867">
                            <div className="match-odds-sec">
                              <div className="item match-status">
                                BOOKIE BHAV 0 % COMM. 0 SEC DELAY
                              </div>
                              <div className="item match-status-odds">
                                <span className="inplay_txt"> In-play </span>
                                <span className="click-tv">
                                  <img
                                    className="tv-icons"
                                    src="/images/tv-screen.png"
                                  />
                                </span>
                              </div>
                              <div className="item match-shedule" id="demo_4087" />
                            </div>
                            <div
                              className="fullrow MatchIndentB"
                              style={{ position: "relative" }}
                            >
                              <table
                                className="table table-striped bulk_actions"
                                id="fancyDiv"
                              >
                                <tbody>
                                  <tr className="headings mobile_heading">
                                    <th className="fix_heading color_red">
                                      Min stake:4000 Max stake:18000
                                    </th>
                                    <th />
                                    <th />
                                    <th className="back_heading_color">Back</th>
                                    <th className="lay_heading_color">Lay</th>
                                    <th />
                                    <th />
                                  </tr>
                                  <tr
                                    id="user_row0"
                                    className="back_lay_color runner-row-186711"
                                  >
                                    <td>
                                      <p className="runner_text" id="runnderName0">
                                        Evergreen Cricket Club
                                      </p>
                                      <p className="blue-odds" id="">
                                        0
                                      </p>
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="">0</span>
                                      <span id="">10000000.00</span>
                                    </td>
                                    {/*- availableToLay */}
                                    <td className="">
                                      <span id="">0</span>
                                      <span id="">10000000.00</span>
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                  </tr>
                                  {/*- TD FOR RUNNER VALUE ONE --*/}
                                  <tr
                                    id="user_row1"
                                    className="back_lay_color runner-row-186712 ball_running-message"
                                  >
                                    <td>
                                      <p className="runner_text" id="runnderName1">
                                        Helsingborg Royals Sports Club
                                      </p>
                                      <p className="blue-odds" id="">
                                        0
                                      </p>
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="">0</span>
                                      <span id="">10000000.00</span>
                                      <h6>SUSPENDED</h6>
                                    </td>
                                    {/*- availableToLay */}
                                    <td className="">
                                      <span id="">0</span>
                                      <span id="">10000000.00</span>
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                    <td className="">
                                      <span id="" />
                                      <span id="" />
                                    </td>
                                  </tr>
                                  {/*- TD FOR RUNNER VALUE ONE --*/}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fancyAPI" id="tossfancydesign"></div>
                    <div
                      className="fullrow margin_bottom fancybox"
                      id="fancyTable"
                      style={{}}
                    >
                      {fancy?.length > 0 && (
                        <div style={{}} className="fancy-table" id="fancyTableSHow">
                          <div className="fancy-heads">
                            <div
                              className="event-sports color_red"
                              style={{ padding: 3, fontWeight: 700 }}
                              id="fancyOdds"
                            >
                              {minmaxStack.length > 0 ? (
                                `Min stake: ${minmaxStack[0]?.minFancyLimit || 'N/A'} Max stake: ${minmaxStack[0]?.maxFancyLimit || 'N/A'}`
                              ) : (
                                'Loading...' // or any loading indicator you prefer
                              )}
                              {/* Min stake:100 Max stake:1000 */}
                            </div>
                            <div className="fancy_buttons">
                              <div className="fancy-backs head-no">
                                <strong>NO</strong>
                              </div>
                            </div>
                            <div className="fancy_buttons">
                              <div className="fancy-lays head-yes">
                                <strong>YES</strong>
                              </div>
                            </div>
                          </div>
                          <div className="fancyAPI" id="fancy_table_own">
                            {fancy[0]?.map((item, index) => (
                              <div className="block_box f_m_4087 fancy_20628 f_m_20628" key={index}>
                                <ul className="sport-high fancyListDiv">
                                  <li>
                                    <div className="ses-fan-box">
                                      <table className="table table-striped bulk_actions">
                                        <tbody>
                                          <tr className="session_content">
                                            <td>

                                              <span className="fancyhead20628"
                                                id="">
                                                {item.RunnerName}
                                              </span>
                                              <b
                                                className="fancyLia20628"
                                                style={{ display: "none", color: 'red' }}
                                              >
                                                {Array.from(accumulatedAmountStack).map(([mapSelectionId, mapAmountStack]) => {
                                                  if (mapSelectionId == item.SelectionId) {
                                                    return - mapAmountStack || 0; // If amountStack is falsy, use 0
                                                  }
                                                  return null; // Return null for entries that don't match
                                                })}
                                              </b>
                                              <p className="position_btn">
                                                <button
                                                  data-toggle="modal"
                                                  data-target="#fancyposition"
                                                  onClick={() => openFancyBtn(item.SelectionId)}
                                                  className="btn btn-xs btn-danger"
                                                >
                                                  Book
                                                </button>
                                              </p>
                                              <input
                                                id="bookLay14"
                                                type="hidden"
                                                defaultValue={6.0}
                                              />
                                            </td>
                                            <td />
                                            <td />
                                            <td
                                              className="fancy_lay"
                                              onClick={isBetlock == 1 ? betLockNotify : () => openBetSlip(item.RunnerName, item.LayPrice1, "lay", "fancyTableSHow", item.LaySize1, item.SelectionId, `lay14${index}`, `gameStatus${index}`)}
                                              data-toggle={isBetlock == 1 ? undefined : "modal"}
                                              data-target={isBetlock == 1 ? undefined : '#openBetSlipM'}
                                            >
                                              <button
                                                className="lay-cell cell-btn"
                                                id={`lay14${index}`}
                                              >
                                                {item.LayPrice1}
                                              </button>
                                              <button
                                                className="disab-btn"
                                                id={`laySize14${index}`}
                                              >
                                                {item.LaySize1}
                                              </button>
                                            </td>
                                            <td
                                              className="fancy_back"
                                              onClick={isBetlock == 1 ? betLockNotify : () => openBetSlip(item.RunnerName, item.BackPrice1, "back", "fancyTableSHow", item.BackSize1, item.SelectionId, `back14${index}`, `gameStatus${index}`)}
                                              data-toggle={isBetlock == 1 ? undefined : "modal"}
                                              data-target={isBetlock == 1 ? undefined : '#openBetSlipM'}
                                            >
                                              <button
                                                className="back-cell cell-btn"
                                                id={`back14${index}`}
                                              >
                                                {item.BackPrice1}
                                              </button>
                                              <button
                                                className="disab-btn"
                                                id={`backSize14${index}`}
                                              >
                                                {item.BackSize1}
                                              </button>
                                            </td>

                                            <td />
                                            <td />
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div
                                        className="ball-msg-box show_msg_box_20632"
                                        id={`gameStatus${index}`}
                                        style={{ display: item.GameStatus != "" ? "block" : "none" }}
                                      >
                                        <h1>{item.GameStatus}</h1>
                                      </div>
                                    </div>
                                  </li>
                                  <li style={{ display: "inline-block" }}>
                                    <div id="fancyBetSlip14" className="palcebetdesign" />
                                  </li>
                                </ul>
                              </div>
                            ))}
                          </div>
                          <div className="fancyLM" />
                        </div>
                      )}
                    </div>
                    <div id="tv-box-popup" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xs-12 matchBox">
              <div className="betSlipBox">
                <div className="betslip-head" style={{}}>
                  <span id="tital_change" className="item">
                    Bet Slip
                  </span>
                  <a
                    href=""
                    data-toggle="modal"
                    data-target="#chipsetting"
                    data-backdrop="static"
                    data-keyboard="false"
                  >
                    Edit Stake
                  </a>
                </div>
                <div>
                  <div
                    className="betBox border-box"
                    id="placeslip"
                    style={{ margin: 0 }}
                  >
                    <div className="block_box">
                      <span id="msg_error" />
                      <span id="errmsg" />
                      <div className="loader" style={{ display: "none" }}>
                        <div className="spinner">
                          <div className="loader-inner box1" />
                          <div className="loader-inner box2" />
                          <div className="loader-inner box3" />
                        </div>
                      </div>
                      <div id='openBetSlipM' className='modal fade' role='dialog' display='none'>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-body'>
                              <div
                                method="POST"
                                id="placeBetSilp"
                                className="palcebetdesign"
                                style={{ display: "block" }}
                              >

                                <div id="betSlipDesignHandler">

                                  {/* <div id="countdown">
                            <div id="countdown-number">5</div>
                            <svg>
                              <circle r={18} cx={20} cy={20} />
                            </svg>
                          </div> */}
                                  <label className="control-label m-t-xs BetFor"> Yet (Bet For)</label>
                                  <div className="liabilityprofit" id=" ">
                                    <span className="stake_label">Profit</span>
                                    <div className="stack_input_field">
                                      <span
                                        id="profitData"
                                        style={{ color: "rgb(0, 124, 14)", fontWeight: "bold" }}
                                      >
                                        {Number(profitData)?.toFixed(1)}

                                      </span>
                                    </div>
                                  </div>
                                  <div className="liabilityprofit" id=" ">
                                    <span className="stake_label">Loss</span>
                                    <div className="stack_input_field">
                                      <span
                                        id="LossData"
                                        style={{ color: "rgb(255, 0, 0)", fontWeight: "bold" }}
                                      >
                                        {Number(lossData)?.toFixed(1)}

                                      </span>
                                    </div>
                                  </div>
                                  <div id="betTeamName" className="match_runner_name">
                                    India
                                  </div>
                                  <div className="odds-stake">
                                    <div className="item form-group full_rowOdd">
                                      <span className="stake_label">Odd</span>
                                      <div className="stack_input_field numbers-row">
                                        <div className="inc plus_btn">
                                          <button style={{ background: "none", border: "none" }} id="">
                                            +
                                          </button>
                                        </div>
                                        <input
                                          type="number"
                                          step="0.01"
                                          id="ShowBetPrice"
                                          readOnly="true"
                                          className="calProfitLoss odds-input form-control CommanBtn"

                                        />
                                        <div className="dec plus_btn">
                                          <button style={{ background: "none", border: "none" }} id="">
                                            -
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="item form-group" id="Input-Stake ">
                                      <span className="stake_label">Stake</span>
                                      <div className="stack_input_field numbers-row">
                                        <div className="inc plus_btn">
                                          <button style={{ background: "none", border: "none" }} id="" onClick={handleIncrement}>
                                            +
                                          </button>
                                        </div>
                                        <input
                                          type="number"
                                          pattern="[0-9]*"
                                          step={1}
                                          id="stakeValue"
                                          className="calProfitLoss stake-input form-control CommanBtn"
                                          value={stakeValue}
                                          onChange={handleStakeChange}
                                        />
                                        <div className="dec plus_btn">
                                          <button style={{ background: "none", border: "none" }} id="" onClick={handleDecrement}>
                                            -
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="betPriceBox">
                                    {Object.keys(chipValues).filter((key) => key.startsWith('Name')).map((name, index) => (
                                      <input
                                        key={name}
                                        className={`btn btn-success CommanBtn chipName${index + 1} stakeButton`}
                                        type="button"
                                        value={chipValues[name]}
                                        onClick={() => handleStakeButtonClick(name)}
                                      />
                                    ))}
                                    <button className="btn btn-success CommanBtn" type="button" id="clearStake" onClick={handleClearStake}>
                                      Clear
                                    </button>
                                  </div>
                                  <div className="betFooter">
                                    <button
                                      className="btn btn-danger CommanBtn"
                                      type="button"
                                      id="clearAllBetSelection"
                                      onClick={handleClearAll}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      className="btn btn-success CommanBtn placebet"
                                      type="button"
                                      id="placeBetButton"
                                      onClick={handlePlaceBet}
                                    >
                                      Place Bet
                                    </button>
                                    <button
                                      className="btn btn-success CommanBtn placefancy"
                                      type="button"
                                      id="placeFancyBetButton"
                                      style={{ display: "none" }}
                                    >
                                      Place Bet
                                    </button>
                                    <button
                                      className="btn btn-success CommanBtn placetoss"
                                      type="button"
                                      id="placeTossBetButton"
                                      style={{ display: "none" }}
                                    >
                                      Place Bet
                                    </button>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div className="tab_bets">
                    <ul
                      className="nav nav-tabs-event nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item betdata active-all active" id="aabet" onClick={(el) => handleToggleStyle(el)}>
                        <a
                          style={{ padding: "8px 15px", border: "none" }}
                          className="allbet active"
                          data-toggle="tab"
                          href="#allbetss"
                          aria-expanded="false"
                        >
                          <span className="bet-label">All Bet</span>{" "}
                          <span id="matchBetsCount">({openBetTable.filter(item => item.oddsType !== 3).length})</span>
                        </a>
                      </li>
                      <li className="nav-item betdata" style={{ display: "none" }}>
                        <a className="unmatchbet">
                          <span className="bet-label">UnMatch Bet</span>{" "}
                          <span id="cnt_row1">(0)</span>{" "}
                        </a>
                      </li>
                      <li
                        className="nav-item betdata"
                        id="ffbet"
                        // style={{ background: "#944e8f" }}
                        onClick={(el) => handleToggleStyle(el)}
                      >
                        <a
                          style={{ padding: "7px 15px", border: "none" }}
                          className="unmatchbet"
                          data-toggle="tab"
                          href="#fbets"
                          aria-expanded="false"
                        >
                          <span className="bet-label">Fancy Bet</span>{" "}
                          <span id="fancyBetsCount">({openBetTable.filter(item => item.oddsType == 3).length})</span>{" "}
                        </a>
                      </li>
                      <li
                        className="nav-item active-position"
                        style={{
                          float: "right",
                          cursor: "pointer",
                          textAlign: "right"
                        }}
                      >
                        <a style={{ padding: 5 }} id="togdiv" onClick={openTable}>
                          <span>
                            &nbsp;
                            <i
                              className="fa fa-caret-down"
                              style={{ fontSize: 25 }}
                            />
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*- Match UnMatch data -*/}
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n\t\t\t\t\t\t\t.searchbtnn {\n\t\t\t\t\t\t\t\tmargin-right: 20px;\n\t\t\t\t\t\t\t}\n\t\t\t\t\t\t"
                }}
              />
              <div
                className="tab-content"
                id="maindivbets"
                style={{ display: "none" }}
              >
                <div
                  className="match_bets MachShowHide tab-pane fade active in"
                  id="allbetss"
                  style={{ overflowX: 'scroll' }}
                >
                  <table className="table jambo_table bulk_action" style={{ whiteSpace: 'nowrap' }} >
                    <thead>
                      <tr className="headings">
                        <td style={{ width: "4%" }}>No.</td>
                        <td style={{ width: "18%" }}>Runner</td>
                        <td style={{ width: "18%" }}>Market</td>
                        <td style={{ width: "6%" }}>Odds</td>
                        <td style={{ width: "10%" }}>Stack</td>
                        <td style={{ width: "8%" }}>Bet Type</td>
                        <td style={{ width: "21%" }}>Place Time</td>
                        <td>Matched Time</td>
                      </tr>
                    </thead>
                    <tbody id="matchBets">
                      {openBetTable.filter(filterItem => filterItem.oddsType !== 3).map((item, index) => (
                        <tr key={item.id} className={item.type == 'back' ? 'mark-back content_user_table' : 'mark-lay content_user_table'}>
                          <td>{index + 1}</td>
                          <td>{item.selection}</td>
                          <td>{item.market}</td>
                          <td>{item.oddsRequest}</td>
                          <td>{item.amountStake}</td>
                          <td>{item.type}</td>
                          <td><Moment format='ddd D MMM Y HH:mm:ss '>{item.placeTime}</Moment></td>
                          <td><Moment format='ddd D MMM Y HH:mm:ss '>{item.matchedTime}</Moment></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="match_bets MachShowHide tab-pane fade" id="fbets">
                  <table className="table jambo_table bulk_action" style={{ whiteSpace: 'nowrap' }}>
                    <thead>
                      <tr className="headings">
                        <td style={{ width: "4%" }}>No.</td>
                        <td style={{ width: "18%" }}>Runner</td>
                        <td style={{ width: "8%" }}>Bet Type</td>
                        <td style={{ width: "8%" }}>Odds</td>
                        <td style={{ width: "8%" }}>Stack</td>
                        <td style={{ width: "21%" }}>Place Time</td>
                        <td style={{}}>Matched Time</td>
                      </tr>
                    </thead>
                    <tbody id="fancybets">

                      {openBetTable.filter(filterItem => filterItem.oddsType == 3).map((item, index) => (
                        <tr key={item.id} className={item.type == 'back' ? 'mark-back content_user_table' : 'mark-lay content_user_table'}>
                          <td>{index + 1}</td>
                          <td>{item.selection}</td>
                          <td>{item.type == "back" ? "YES" : "NO"}</td>
                          <td>{item.oddsRequest}</td>
                          <td>{item.amountStake}</td>
                          <td><Moment format='ddd D MMM Y HH:mm:ss '>{item.placeTime}</Moment></td>
                          <td><Moment format='ddd D MMM Y HH:mm:ss '>{item.matchedTime}</Moment></td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
              {/*- User Current Position  -*/}
            </div>
          </div>
        </div >
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div >

      <div
        id="listmatch"
        className="modal fade"
        role="dialog"
        style={{ top: "11%", display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {/* Modal content*/}
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                background: "linear-gradient(#b159c2, #853395)",
                padding: "10px"
              }}
            >
              <button
                style={{ lineHeight: "10px", color: "#fff", opacity: "1" }}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                ×
              </button>
            </div>
            <div className="modal-body" style={{ padding: '0px !important' }}>
              <div
                className="table-responsive"
                style={{ textAlign: "center", margin: 0 }}
                id="matchlistdiv"
              >
                <table
                  className="table table-bordered common-table"
                  style={{ marginBottom: 0 }}
                >
                  <tbody id="matchListBody">

                    {filteredEventNames.map((item, index) => (

                      <tr key={index} style={{ background: "#000" }}>
                        <td className="cell-market-title">
                          <span>
                            <a
                              className="event-name"
                              style={{ color: "#fff" }}
                              href={`/EVENT/${item.eid}/${item.gameId}/${item.marketId}`}
                            >
                              {item.matchName}
                            </a>{" "}
                            <span style={{ float: "right" }} />
                          </span>
                        </td>
                      </tr>
                    ))}
                    {/* <tr style={{ background: "#000" }}>
                    <td className="cell-market-title">
                      <span>
                        <a
                          className="event-name"
                          style={{ color: "#fff" }}
                          href="/EVENT/4/32855234"
                        >
                          Central Districts v Northern Brave
                        </a>{" "}
                        <span style={{ float: "right" }} />
                      </span>
                    </td>
                  </tr>
                  <tr style={{ background: "#000" }}>
                    <td className="cell-market-title">
                      <span>
                        <a
                          className="event-name"
                          style={{ color: "#fff" }}
                          href="/EVENT/4/1812262033"
                        >
                          UNITED ARAB EMIRATES V AFGHANISTAN
                        </a>{" "}
                        <span style={{ float: "right" }} />
                      </span>
                    </td>
                  </tr>
                  <tr style={{ background: "#000" }}>
                    <td className="cell-market-title">
                      <span>
                        <a
                          className="event-name"
                          style={{ color: "#fff" }}
                          href="/EVENT/4/32892400"
                        >
                          New Zealand v Bangladesh
                        </a>{" "}
                        <span style={{ float: "right" }} />
                      </span>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="fancyposition" role="dialog" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content" style={{ background: "#fff" }}>
            <div className="popup_form">
              <div
                className="title_popup"
                style={{ background: "linear-gradient(#b159c2,#853395)!important" }}
              >
                <span> Fancy Position</span>
                <button type="button" data-dismiss="modal" className="close" onClick={(() => setFancyBookBtnArray([]))}>
                  <div className="close_new">
                    <i className="fa fa-times-circle" />
                  </div>
                </button>
              </div>
              <div className="content_popup">
                <div className="popup_form_row">
                  <div className="modal-body" style={{ padding: "0 !important" }}>
                    <table className="table bets-table">
                      <thead>
                        <tr className="box-heading fancy-head">
                          <th style={{ textAlign: "center" }}>Score</th>
                          <th style={{ textAlign: "center" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: "center" }} id="fancyBook">
                        {FancybookbtnArray.map((item) => (
                          <tr key={item.id}>
                            <td>{item.request}</td>
                            <td style={{ color: item.pl < 0 ? "red" : "green" }}>{Number(item.pl).toFixed()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="tvlive"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div
          className="modal-dialog"
          id="mobiletvmodal"
          role="document"
          style={{ width: "30%", margin: "0 auto", position: "relative" }}
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ background: "#9e49af !important" }}
            >
              <button className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                Australia v Pakistan
              </h4>
            </div>
            <div className="modal-body"></div>
          </div>
        </div>
      </div>
      <script>
        $(".tvformobile").click(function () {"{"}
        $(".tvformobilediv").toggle();
        {"}"}); $("#togdiv").click(function () {"{"}
        $("#maindivbets").toggle();
        {"}"}); $("#aabet").click(function () {"{"}
        $("#maindivbets").show();
        {"}"}); $("#ffbet").click(function () {"{"}
        $("#maindivbets").show();
        {"}"});
      </script>


    </>
  )
}

export default EVENT;