import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer'

function UserShowBet() {

  const { marketId } = useParams();
  //console.log("market id is : " + marketId)

  const [userName, setUserName] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [plAmount, setPLAmount] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };


  useEffect(() => {

    fetchData();
  }, []);


  const fetchData = async () => {
    const userId = decryptCookie('id')
    const formattedUserName = decryptCookie('userName') ? decryptCookie('userName').replace(/"/g, '') : '';
    setUserName(formattedUserName)
    try {
      const response = await fetch(`https://api.s2bet.in/GetBetHistory/${userId} `);
      const result = await response.json();

      //console.log('Bet history Api : ' + result.data)

      if (Array.isArray(result.data)) {
        // Format the date in each item before setting it in the state
        const formattedData = result.data.map(item => ({
          ...item
          // formattedPlaceTime: <Moment format='ddd D MMM h:mm a'>{item.placeTime}</Moment>,
        }));
        //console.log("Formated data after map " + JSON.stringify(formattedData))

        // Filter by date and Status
        const filteredData = formattedData.filter(item =>
          item.marketId === marketId
        );

        // Sort the data by the ISO date string
        // filteredData.sort((a, b) => new Date(a.placeTime) - new Date(b.placeTime));

        //console.log("Formatted and Sorted Bet History data:", filteredData);

        //Calc MatchOdds Amount
        // const totalPLAmount = filteredData.reduce((accumulator, item) => accumulator + item.amount, 0);
        // const roundedTotalAmount = totalPLAmount.toFixed(2);
        // //console.log("Total Pl amount is : "+roundedTotalAmount)
        // // Sort the data by the ISO date string
        // formattedData.sort((a, b) => new Date(a.result.data.placeTime) - new Date(b.result.data.placeTime));

        // //console.log("Formatted and Sorted Bet History data:", formattedData);
        setFiltered(filteredData)
        // setPLAmount(roundedTotalAmount)

      } else {
        console.error('Invalid data format:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = filtered.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="container">
            <section id="content">
              <section className="vbox">
                <section className="">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="title_new_at">
                        Show
                        <select style={{ color: "black" }} id="pages" onChange={handlePerPageChange}
                          value={perPage}>
                          <option value={10} selected="selected">
                            10
                          </option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      {/*Loading class */}
                      <div className="custom-scroll table-responsive">
                        <table
                          className="table table-bordered table-dark table_new_design jambo_table bulk_action dataTable"
                          id="datatables"
                        >
                          <thead>
                            <tr className="headings">
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                S.No.
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Client
                              </th>
                              {/* <th
                          className=""
                          style={{ background: "#654062", color: "#fff" }}
                        >
                          Admin
                        </th>
                        <th
                          className=""
                          style={{ background: "#83a95c", color: "#000" }}
                        >
                          Hyper
                        </th>
                        <th
                          className=""
                          style={{ background: "#654062", color: "#fff" }}
                        >
                          Super Master
                        </th>
                        <th
                          className=""
                          style={{ background: "#83a95c", color: "#000" }}
                        >
                          Master
                        </th> */}
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Description
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Selection
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Type
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Odds
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Stack
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Place Time
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Result Time
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                P_L
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Profit
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Liability
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                Result
                              </th>
                              <th
                                className=""
                                style={{ background: "#83a95c", color: "#000" }}
                              >
                                Status
                              </th>
                              <th
                                className=""
                                style={{ background: "#654062", color: "#fff" }}
                              >
                                IP
                              </th>
                            </tr>
                          </thead>
                          <tbody id="betlistdiv">
                            {currentItems.map((item, index) => (

                              <tr key={item.id} className="content_user_table mark-lay odd">
                                <td>{(currentPage - 1) * perPage + index + 1}</td>
                                <td>{userName}</td>
                                {/* <td>admin</td>
                        <td>rosan1</td>
                        <td>bansal85</td>
                        <td>line1</td> */}
                                <td>{item.event}</td>
                                <td>{item.selection}</td>
                                <td>{item.type}</td>
                                <td>{item.oddsRequest}</td>
                                <td>{item.amountStake}</td>
                                <td>{format(parseISO(item.placeTime), "dd MMM yy hh:mm:ss a")}</td>
                                <td>{format(parseISO(item.settleTime), "dd MMM yy hh:mm:ss a")}</td>
                                <td className={item.resultAmount >= 0 ? "green" : "red"}>{item.resultAmount}</td>
                                <td className="green"></td>
                                <td className="red"></td>
                                <td></td>
                                <td>{item.isSettlement == 2 ? "Open" : "Settled"}</td>
                                <td>{item.ipAddress}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <p id="items">Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filtered.length)} of Entries {filtered.length}</p>
                        <div className="pagination-row dataTables_paginate paging_simple_numbers">
                          {Array.from({ length: Math.ceil(filtered.length / perPage) }, (_, i) => (
                            <button
                              key={i + 1}
                              className={`paginate_button ${currentPage === i + 1 ? 'current' : ''}`}
                              onClick={() => paginate(i + 1)}
                            >
                              {i + 1}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </section>
            </section>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>
    </>
  )
}

export default UserShowBet