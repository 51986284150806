import React from 'react'
import Header from '../Component/Header'
import LeftPanel from '../Component/LeftPanel'
import { Outlet } from 'react-router-dom'

function RouteLayout() {
    return (
        <>
            <Header />
            <LeftPanel />
            <Outlet />

        </>
    )
}

export default RouteLayout