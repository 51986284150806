import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Login from './Component/Login';
import Dashboard from './Component/Dashboard';
// import Inplay from './Component/Inplay';
import InPlay from './Component/InPlay';
import AccountSt from './Component/AccountSt';
import ProfitLoss from './Component/ProfitLoss';
import BetHistory from './Component/BetHistory';
import UserMatchDetail from './Component/UserMatchDetail';
import UserShowBet from './Component/UserShowBet';
import UserLedgerInside from './Component/UserLedgerInside';
import EVENT from './Component/EVENT';
import Runningmarketanalysis from './Component/Runningmarketanalysis';
import Announcement from './Component/Announcement';
import ChangePassword from './Component/ChangePassword';
import ErrorPage from './utils/ErrorPage';
import PrivateRoutes from './utils/PrivateRoutes';
import RouteLayout from './Layouts/RouteLayout';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  useEffect(() => {
    // Check if user information is present in session storage
    const userName = decryptCookie('userName');
    const userId = decryptCookie('id');

    if (userName && userId) {
      setIsLoggedIn(true);
      Cookies.set('logged-in', true);
    } else {
      setIsLoggedIn(false);
      Cookies.set('logged-in', false);
    }
  }, [isLoggedIn]);


  return (

    <>
      <BrowserRouter>
        <Routes>
          {/* <Route
          exact
          path='/'
          element={isLoggedIn ? <Dashboard /> : <Login />}
        /> */}

          <Route path='/login' element={!isLoggedIn ? <Login /> : <Navigate to='/' />} />

          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<RouteLayout />}>
              <Route index element={<Dashboard />} />
              <Route path='/inPlay' element={<InPlay />} />
              <Route path='/accountStatement' element={<AccountSt />} />
              <Route path='/profitLoss' element={<ProfitLoss />} />
              <Route path='/betHistory' element={<BetHistory />} />
              <Route path='/userMatchDetail/:marketId' element={<UserMatchDetail />} />
              <Route path='/userShowBetCR/:marketId' element={<UserShowBet />} />
              <Route path='/userLedgerInside/:marketId' element={<UserLedgerInside />} />
              <Route path='/EVENT/:eid/:gameId/:marketId' element={<EVENT />} />
              {/* <Route path='/EVENT/:eid/:gameId' element={<EVENT2 />} /> */}

              <Route path='/runningmarketanalysis' element={<Runningmarketanalysis />} />
              <Route path='/announcement' element={<Announcement />} />
              <Route path='/changePasswordNew' element={<ChangePassword />} />
            </Route>
          </Route>
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>

      <Toaster />
    </>
  );
}

export default App;
