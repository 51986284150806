import React from "react";
import ReactDOM from "react-dom/client";
// import './index.css';
import App from "./App";
import { ChipValuesProvider } from "./Component/ChipValuesContext";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render
        (
                <ChipValuesProvider>
                        <App />
                </ChipValuesProvider>

        );
