import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom';
import { useChipValues } from './ChipValuesContext';


export default function () {

  const navigate = useNavigate();
  const { betSend, setBetStartTime, updateBetId, setupdateBetId } = useChipValues();
  const [siteNotice, setSiteNotice] = useState('')
  const [userName, setUserName] = useState('')
  const [fullName, setfullName] = useState('')
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isLiveGameOpen, setIsLiveGameOpen] = useState(false);
  const [bookMBetProfit, setBookMBetProfit] = useState('')
  const [matchOddBetProfit, setmatchOddBetProfit] = useState('')
  const [runnersData, setRunnersData] = useState([])
  const [userExposure, setUserExposure] = useState(0);
  const [userAmount, setUserAmount] = useState(0)
  const [userBalance, setuserBalance] = useState(0);




  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  // Cookies variables

  let userId;
  let conNum;
  let userIp;
  let userN;
  let Fname;

  // const encryptedId = Cookies.get('id');

  userId = decryptCookie('id');
  conNum = parseInt(userId)
  ////console.log('user id is : ' + conNum);
  userIp = decryptCookie('userIP');
  ////console.log("userip in cookies is : " + userIp + " " + typeof (userIp))





  useEffect(() => {
    headerNoticeData()
    // userN = Cookies.get('userName');
    // Fname = Cookies.get('fullName')

    // Decrypt the encrypted cookie values
    userN = decryptCookie('userName');
    Fname = decryptCookie('fullName');
    ////console.log('get key: ' + userN, +' ' + Fname);

    const formattedUserName = userN ? userN.replace(/"/g, '') : '';
    const formattedFullName = Fname ? Fname.replace(/"/g, '') : '';
    setUserName(formattedUserName);
    setfullName(formattedFullName);
    fetchRunner();
    fetchUserStatus();
    // fetchExposure();
    newfetchBalance();

  }, [])



  useEffect(() => {
    if (betSend === true) {
      // fetchExposure1();
      newfetchBalance();
    }
  }, [betSend])

  const fetchUserStatus = async () => {
    try {
      const response = await fetch(`https://api.s2bet.in/GetUserIdandUserStatus/${userId}`);

      const result = await response.json();

      // ////console.log("result is from userId and User Status table : " + JSON.stringify(result));

      if (result && result.data && result.data.length > 0) {
        const dataArray = Array.isArray(result.data) ? result.data : [result.data];
        // Check Status is true
        const loggedInUsers = dataArray.filter(item => item.status == true);

        // ////console.log("loggedInUsers is : " + JSON.stringify(loggedInUsers));

        // Check ip and userID whose status is true
        const filterUser = loggedInUsers.filter(item => item.ipAddress == userIp && item.userId == conNum)

        // ////console.log("filtered ip is : " + JSON.stringify(filterUser))

        if (filterUser.length > 0) {
          ////console.log("user found status true ")
        } else {
          // User is logged in, proceed with your logic
          Cookies.remove('userName');
          Cookies.remove('fullName');
          Cookies.remove('id');
          Cookies.remove('userIP');
          setUserName('');
          window.location.reload();
          navigate('/');
        }
      } else {
        ////console.log("No data found in result or result.data.");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const headerNoticeData = async () => {
    const api = await fetch(`https://api.s2bet.in/api/getMasterData`)
    const apiData = await api.json();
    console.log("Header data is : ", apiData[0].SiteMessage)
    setSiteNotice(apiData[0].SiteMessage)
    setBetStartTime(apiData[0].BetStartTime)
  }

  const handleReportToggle = () => {
    setIsReportOpen(!isReportOpen);
  };

  const handleLiveGameToggle = () => {
    setIsLiveGameOpen(!isLiveGameOpen);
  };

  function openNav() {
    if (window.innerWidth <= 768) {
      document.getElementById("lefttSidenav").style.width = "250px";
    }
  }

  function closeNav() {
    // Check if the viewport width is less than or equal to a certain threshold (e.g., 768 pixels)
    if (window.innerWidth <= 768) {
      document.getElementById("lefttSidenav").style.width = "0";
    }
  }
  // Add event listener to detect touch events on the document body
  document.body.addEventListener('touchstart', function (event) {
    // Check if the touched element is not leftSidenav or its descendants
    if (!event.target.closest('#lefttSidenav')) {
      // Close the leftSidenav
      closeNav();
    }
  });

  const handleLogout = async () => {

    try {
      const response = await fetch(`https://api.s2bet.in/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserId: userId
        }),
      });

      if (response.status == 200) {
        // const responseData = await response.json();

        ////console.log("Message : " + response.message)
        Cookies.set('logged-in', false);
        Cookies.remove('userName')
        Cookies.remove('fullName')
        Cookies.remove('id')
        Cookies.remove('userIP')
        Cookies.remove('exposure')
        Cookies.remove('location')
        Cookies.remove('MainBalance')




        setUserName('');
        // navigate('/login');
        window.location.href = '/login'

      }
    } catch (error) {
      console.error('Error during login:', error);
    }

  }



  const fetchRunner = async () => {
    try {

      const responseRunner = await fetch(`https://api.s2bet.in/getRunners`);
      const resultRunner = await responseRunner.json();

      // ////console.log("RunnerByMatch Result for exposure is : " + JSON.stringify(resultRunner));

      const runnersSelectionIds = [];

      if (resultRunner.isSuccess && resultRunner.data.length > 0) {
        // Extracting SelectionId and Market from each item in the resultRunner.data array
        const filterRunnerSId = resultRunner.data.map(item => ({
          EventId: item.EventId,
          SelectionId: item.SelectionId,
          Market: item.Market
        }));

        runnersSelectionIds.push(...filterRunnerSId);
      }

      ////console.log("Runner Selection ID : ", runnersSelectionIds);
      setRunnersData(runnersSelectionIds)
    } catch (err) {
      ////console.log("Error fetching data from getRunners API .. ", err)
    }
  }

  const fetchExposure = async () => {

    try {
      // console.log("Fetch exposure START at Header ...")

      let apiBalance = 0;

      const responseBal = await fetch(`https://api.s2bet.in/userBalance/${userId}`);
      const resultBal = await responseBal.json();

      if (resultBal.isSuccess) {
        apiBalance = resultBal.mainBalance;

        ////console.log("user api Balance is : ", resultBal.mainBalance)

        // setuserBalance(calcBalance)
        // setUserAmount(result.mainBalance)
      }


      const responseRunner = await fetch(`https://api.s2bet.in/getRunnersByUserId/${userId}`);
      // const responseRunner = await fetch(`https://api.s2bet.in/getRunnersByEventMarket/${gameId}/${marketId}`)
      const resultRunner = await responseRunner.json();

      const runnersSelectionIds = [];

      if (resultRunner.isSuccess && resultRunner.data.length > 0) {
        // Extracting SelectionId and Market from each item in the resultRunner.data array
        const filterRunnerSId = resultRunner.data.map(item => ({
          eventId: item.EventId,
          selectionId: item.SelectionId,
          market: item.Market
        }));

        runnersSelectionIds.push(...filterRunnerSId);
      }

      //console.log("Runner Selection ID in fetch Exposure function  : ", runnersSelectionIds);

      const matchOddsBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Match Odds")

      const SBetsByRunner = matchOddsBetsByRunner.filter(runner => runner.selectionId);

      //console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunner);


      const BookMakerBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Bookmaker")

      const SBetsByRunnerBM = BookMakerBetsByRunner.filter(runner => runner.selectionId);

      ////console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunnerBM);

      const response = await fetch(`https://api.s2bet.in/getExposure/${userId}`);
      const result = await response.json();

      if (result.isSuccess) {
        ////console.log("Exposure Api data is : ", result.data);


        let exposure = 0;

        // Iterate over each unique EventId
        result.data.forEach(event => {
          // const eventId = event.eventId;
          // const eventSelections = [];
          // const eventSelectionsBM = [];


          // For BACK !!!!!!!

          // const filtermatchOdds = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

          // const totalProfit = filtermatchOdds.reduce((sum, item) => sum + item.profit, 0);

          // ////console.log("Total Profit for back: ", totalProfit);
          // const totalLoss = filtermatchOdds.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Loss for back: ", totalLoss);

          ////console.log("Match Odd filter : " + JSON.stringify(filtermatchOdds))

          // For LAY !!!!!!

          // const filtermatchOddsLay = event.data.filter(item => item.market == "Matchodds" && SBetsByRunner.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

          // const totalProfitLay = filtermatchOddsLay.reduce((sum, item) => sum + item.profit, 0);

          // ////console.log("Total Loss for Lay : ", totalProfitLay);
          // const totalLossLay = filtermatchOddsLay.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Profit for Lay : ", totalLossLay);

          ////console.log("Match Odd filter for lay : " + JSON.stringify(filtermatchOddsLay))

          // NEXT TEAM For BACK /////////////

          const filterODD = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "Matchodds" &&
              item.type == "back" &&
              SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filter ODD Next team : ", filterODD)

          // Get unique selectionIds from filterODDLay
          const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

          ////console.log("Filter odd SelectionIds ssss : ", filterODDSelectionIds)

          const filterOddAgainForOdd = SBetsByRunner.filter(item => !filterODDSelectionIds.has(item.selectionId)
            && filterODD.some(odds => odds.eventId == item.eventId));

          ////console.log("Filter odd again for odd ssss : ", filterOddAgainForOdd)

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("Filter odd again for odd with profit loss ssss : ", filterOddAgainWithProfitLossOdd)
          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

          ////console.log("Next Team for Odd: " + JSON.stringify(combinedArrayForOdd));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionId = {};
          const totalLossBySelectionId = {};

          // Calculate net profit for each selectionId
          combinedArrayForOdd.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
              totalProfitBySelectionId[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
              totalLossBySelectionId[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionId[selectionId] += profit;
            totalLossBySelectionId[selectionId] += loss;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionId = {};
          Object.keys(totalProfitBySelectionId).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
              .filter(otherSelectionId => otherSelectionId !== selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

            const profit = totalProfitBySelectionId[selectionId];
            const loss = totalLossBySelectionId[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
          });

          // Example usage:
          ////console.log("Total Profit by SelectionId for Odd: ", totalProfitBySelectionId);
          ////console.log("Total Loss by SelectionId for Odd : ", totalLossBySelectionId);
          ////console.log("Net Profit by SelectionId for Odd : ", netProfitBySelectionId);

          // NEXT TEAM For LAY /////////////

          const filterODDLay = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "Matchodds" &&
              item.type == "lay" &&
              SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          //console.log("filterODDLay fltered data : ", filterODDLay)
          // Get unique selectionIds from filterODDLay
          const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

          // Filter items from odds based on selectionId not present in filterODDLay
          const filterOddAgain = SBetsByRunner.filter(item => !filterODDLaySelectionIds.has(item.selectionId) && filterODDLay.some(odds => odds.eventId == item.eventId));

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("filter odd Again : " + JSON.stringify(filterOddAgainWithProfitLoss));

          ////console.log("Next Team for Lay : " + JSON.stringify(filterODDLay));

          ////console.log("Odds item : " + JSON.stringify(SBetsByRunner.length))

          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

          //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdLay = {};
          const totalLossBySelectionIdLay = {};

          // Calculate net profit for each selectionId
          combinedArray.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdLay[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdLay[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdLay[selectionId] += loss;
            totalLossBySelectionIdLay[selectionId] += profit;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdLay = {};
          Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
              .filter(otherSelectionId => otherSelectionId != selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdLay[selectionId];
            const loss = totalLossBySelectionIdLay[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
          });


          //console.log("Total Profit by SelectionId Odd: ", netProfitBySelectionId)
          //console.log("Total Loss by SelectionId Odd: ", totalLossBySelectionId)
          //console.log("Total Profit by SelectionId Lay: ", totalProfitBySelectionIdLay);
          //console.log("Total Loss by SelectionId Lay: ", totalLossBySelectionIdLay);
          //console.log("Net Profit by SelectionId Lay: ", netProfitBySelectionIdLay);

          // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
          const finalProfitMatchOdd = {};

          Object.keys(netProfitBySelectionId).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            //console.log("Sleection ID of final profit matchodd : ", selectionId)
            finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
          });

          if (Object.keys(netProfitBySelectionId).length === 0) {
            // Object is empty
            Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
              // Sum the corresponding values for each selectionId
              //console.log("Selection ID of final profit match odd: ", selectionId);
              finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
            });
          }



          //console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

          const negativeProfits = Object.values(finalProfitMatchOdd).filter(profit => profit < 0);

          // If there are negative profits, find the maximum among them
          if (negativeProfits.length > 0) {
            const maxNegativeProfit = Math.min(...negativeProfits);
            //console.log("Maximum negative profit from finalProfitMatchOdd: ", maxNegativeProfit);

            exposure += maxNegativeProfit;
          } else {
            //console.log("No negative profits found in finalProfitMatchOdd.");
          }

          // For Book Maker

          ////console.log("///////   Book Maker /////")
          // For BACK !!!!!!!

          const filtermatchOddsBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

          const totalProfitBM = filtermatchOddsBM.reduce((sum, item) => sum + item.profit, 0);

          ////console.log("Total Profit for back BookMaker : ", totalProfitBM);
          const totalLossBM = filtermatchOddsBM.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Loss for back BookMaker : ", totalLossBM);

          ////console.log("Match Odd filter BookMaker : " + JSON.stringify(filtermatchOddsBM))

          // For LAY !!!!!!

          const filtermatchOddsLayBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

          const totalProfitLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.profit, 0);

          ////console.log("Total Loss for Lay  BookMaker : ", totalProfitLayBM);
          const totalLossLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Profit for Lay BookMaker : ", totalLossLayBM);

          ////console.log("Match Odd filter for lay BookMaker : " + JSON.stringify(filtermatchOddsLayBM))

          // NEXT TEAM For BACK /////////////

          const filterODDBM = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "BookMaker" &&
              item.type == "back" &&
              SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filter ODD Next team BookMaker : ", filterODDBM)

          // Get unique selectionIds from filterODDLay
          const filterODDSelectionIdsBM = new Set(filterODDBM.map(item => item.selectionId));

          ////console.log("Filter odd SelectionIds ssss BookMaker : ", filterODDSelectionIdsBM)

          const filterOddAgainForOddBM = SBetsByRunnerBM.filter(item => !filterODDSelectionIdsBM.has(item.selectionId)
            && filterODDBM.some(odds => odds.eventId == item.eventId));



          ////console.log("Filter odd again for odd ssss BookMaker : ", filterOddAgainForOddBM)

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossOddBM = filterOddAgainForOddBM.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("Filter odd again for odd with profit loss ssss BookMaker : ", filterOddAgainWithProfitLossOddBM)
          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayForOddBM = [...filterODDBM, ...filterOddAgainWithProfitLossOddBM];

          ////console.log("Next Team for Odd BookMaker : " + JSON.stringify(combinedArrayForOddBM));
          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdBM = {};
          const totalLossBySelectionIdBM = {};

          // Calculate net profit for each selectionId
          combinedArrayForOddBM.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdBM.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdBM[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdBM.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdBM[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdBM[selectionId] += profit;
            totalLossBySelectionIdBM[selectionId] += loss;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdBM = {};
          Object.keys(totalProfitBySelectionIdBM).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdBM)
              .filter(otherSelectionId => otherSelectionId != selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBM[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdBM[selectionId];
            const loss = totalLossBySelectionIdBM[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdBM[selectionId] = profit + totalLossToOtherSelectionsBM;
          });


          // Example usage:
          ////console.log("Total Profit by SelectionId for Odd BookMaker: ", totalProfitBySelectionIdBM);
          ////console.log("Total Loss by SelectionId for Odd BookMaker : ", totalLossBySelectionIdBM);
          ////console.log("Net Profit by SelectionId for Odd  BookMaker : ", netProfitBySelectionIdBM);

          // NEXT TEAM For LAY /////////////

          const filterODDLayBM = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "BookMaker" &&
              item.type == "lay" &&
              SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filterODDLay fltered data BookMaker : ", filterODDLayBM)
          // Get unique selectionIds from filterODDLay
          const filterODDLaySelectionIdsBM = new Set(filterODDLayBM.map(item => item.selectionId));

          // Filter items from odds based on selectionId not present in filterODDLay
          const filterOddAgainBM = SBetsByRunnerBM.filter(item => !filterODDLaySelectionIdsBM.has(item.selectionId) && filterODDLayBM.some(odds => odds.eventId == item.eventId));

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossBM = filterOddAgainBM.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("filter odd Again BookMaker : " + JSON.stringify(filterOddAgainWithProfitLossBM));

          ////console.log("Next Team for Lay BookMaker : " + JSON.stringify(filterODDLayBM));

          ////console.log("Odds item BookMaker : " + JSON.stringify(SBetsByRunnerBM.length))

          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayBM = [...filterODDLayBM, ...filterOddAgainWithProfitLossBM];

          ////console.log("Combined Both Array BookMaker : " + JSON.stringify(combinedArrayBM));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdLayBM = {};
          const totalLossBySelectionIdLayBM = {};

          // Calculate net profit for each selectionId
          combinedArrayBM.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdLayBM.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdLayBM[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdLayBM.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdLayBM[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdLayBM[selectionId] += loss;
            totalLossBySelectionIdLayBM[selectionId] += profit;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdLayBM = {};
          Object.keys(totalProfitBySelectionIdLayBM).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdLayBM)
              .filter(otherSelectionId => otherSelectionId !== selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBM[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdLayBM[selectionId];
            const loss = totalLossBySelectionIdLayBM[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdLayBM[selectionId] = profit + totalLossToOtherSelectionsBM;
          });


          ////console.log("Total Profit by SelectionId Odd BookMaker : ", netProfitBySelectionIdBM)
          ////console.log("Total Loss by SelectionId Odd BookMaker : ", totalLossBySelectionIdBM)
          ////console.log("Total Profit by SelectionId Lay BookMaker : ", totalProfitBySelectionIdLayBM);
          ////console.log("Total Loss by SelectionId Lay BookMaker : ", totalLossBySelectionIdLayBM);
          ////console.log("Net Profit by SelectionId Lay BookMaker : ", netProfitBySelectionIdLayBM);

          // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
          const finalProfitMatchOddBM = {};

          Object.keys(netProfitBySelectionIdBM).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            finalProfitMatchOddBM[selectionId] = (netProfitBySelectionIdBM[selectionId] || 0) + (netProfitBySelectionIdLayBM[selectionId] || 0);
          });

          if (Object.keys(netProfitBySelectionIdBM).length === 0) {
            // Object is empty
            Object.keys(netProfitBySelectionIdLayBM).forEach(selectionId => {
              // Sum the corresponding values for each selectionId
              //console.log("Selection ID of final profit Bookmaker : ", selectionId);
              finalProfitMatchOddBM[selectionId] = netProfitBySelectionIdLayBM[selectionId] || 0;
            });
          }


          //console.log("Final Profit for Book Maker: ", finalProfitMatchOddBM);

          const negativeProfitsBM = Object.values(finalProfitMatchOddBM).filter(profit => profit < 0);

          // If there are negative profits, find the maximum among them
          if (negativeProfitsBM.length > 0) {
            const maxNegativeProfit = Math.min(...negativeProfitsBM);
            ////console.log("Maximum negative profit from finalProfitMatchOddBM BookMaker : ", maxNegativeProfit);
            exposure += maxNegativeProfit;
          } else {
            ////console.log("No negative profits found in finalProfitMatchOddBM BookMaker.");
          }

          //  FOR Fancy ///////
          ////console.log("//// Fancy ///")

          const filterFancy = event.data.filter(item => item.market == 'Fancy')



          //console.log("Fancy bets calcualtion : ", filterFancy)


          const fancyBackLayResult = [];
          let maxNegativeBackToLay = 0;
          let maxNegativeLayToBack = 0;
          let maxNegativeValue = 0;


          // Step 2: Group items by selectionId
          const groupedItems = {};

          filterFancy.forEach(item => {
            if (!groupedItems[item.selectionId]) {
              groupedItems[item.selectionId] = [];
            }
            groupedItems[item.selectionId].push(item);
          });

          // Step 3: Calculate profit and liability for 'Back' and 'Lay' markets for each selectionId
          // for (const selectionId in groupedItems) {
          //   const backItems = groupedItems[selectionId].filter(item => item.type == 'back');
          //   const layItems = groupedItems[selectionId].filter(item => item.type == 'lay');

          //   let backProfit = 0;
          //   let backLiability = 0;
          //   let layProfit = 0;
          //   let layLiability = 0;

          //   backItems.forEach(item => {
          //     backProfit += item.profit;
          //     backLiability += item.liability;
          //   });

          //   layItems.forEach(item => {
          //     layProfit += item.profit;
          //     layLiability += item.liability;
          //   });

          //   // Step 4: Calculate back profit to lay liability and back liability to lay profit
          //   const backProfitToLayLiability = backProfit + layLiability;
          //   const backLiabilityToLayProfit = backLiability + layProfit;

          //   // Step 5: Console the results for each selectionId
          //  //console.log(`SelectionID: ${selectionId}`);
          //  //console.log("Back Profit to Lay Liability:", backProfitToLayLiability);
          //  //console.log("Back Liability to Lay Profit:", backLiabilityToLayProfit);


          //   fancyBackLayResult.push({
          //     selectionId: selectionId,
          //     backPLayL: backProfitToLayLiability,
          //     backLlayP: backLiabilityToLayProfit
          //   });

          //   const maxNegativeInIteration = Math.min(backProfitToLayLiability, backLiabilityToLayProfit);
          //   maxNegativeValue += maxNegativeInIteration
          // }


          for (const selectionId in groupedItems) {
            const items = groupedItems[selectionId];
            const minOddsRequest = Math.min(...items.map(item => item.oddsRequest));
            const maxOddsRequest = Math.max(...items.map(item => item.oddsRequest));



            // BET CALCULATION FOR BACK !!!!!!!!!

            const filterByBack = items.filter(item => item.type == "back")

            //console.log("Fancy filter for Back : " + JSON.stringify(filterByBack))


            // Group items by id
            const groupedByIdBack = {};
            filterByBack.forEach(item => {
              if (!groupedByIdBack[item.id]) {
                groupedByIdBack[item.id] = [];
              }
              groupedByIdBack[item.id].push(item);
            });

            // Calculate the range and set the length dynamically
            const rangeBack = maxOddsRequest - minOddsRequest + 10;

            // Create FancyBookArray for each group
            const resultArraysBack = [];
            const calcArrayBack = []
            Object.keys(groupedByIdBack).forEach(groupId => {
              const groupItems = groupedByIdBack[groupId];


              const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
                const request = index + minOddsRequest - 5;
                const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;

                return { request, pl };
              });

              resultArraysBack.push({ id: groupId, FancyBookArray });
            });

            // //console.log("Result Arrays For Back:", resultArraysBack);

            const redcBack = resultArraysBack.reduce((acc, current) => {
              current.FancyBookArray.forEach((entry) => {
                const existingEntry = acc.find((item) => item.request == entry.request);

                if (existingEntry) {
                  existingEntry.pl += entry.pl;
                } else {
                  acc.push({ request: entry.request, pl: entry.pl });
                }
              });

              return acc;
            }, []);

            // //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))



            // BET CALCULATION FOR LAY !!!!!!!!!

            const filterByLay = items.filter(item => item.type == "lay")

            //console.log("Fancy filter for Lay : " + JSON.stringify(filterByLay))


            // Group items by id
            const groupedById = {};
            filterByLay.forEach(item => {
              if (!groupedById[item.id]) {
                groupedById[item.id] = [];
              }
              groupedById[item.id].push(item);
            });

            // Calculate the range and set the length dynamically
            const range = maxOddsRequest - minOddsRequest + 10;

            // Create FancyBookArray for each group
            const resultArrays = [];
            const calcArray = []
            Object.keys(groupedById).forEach(groupId => {
              const groupItems = groupedById[groupId];


              const FancyBookArray = Array.from({ length: range }, (_, index) => {
                const request = index + minOddsRequest - 5;
                const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;

                return { request, pl };
              });

              resultArrays.push({ id: groupId, FancyBookArray });
            });

            //console.log("Result Arrays:", resultArrays);

            const redc = resultArrays.reduce((acc, current) => {
              current.FancyBookArray.forEach((entry) => {
                const existingEntry = acc.find((item) => item.request == entry.request);

                if (existingEntry) {
                  existingEntry.pl += entry.pl;
                } else {
                  acc.push({ request: entry.request, pl: entry.pl });
                }
              });

              return acc;
            }, []);

            //console.log("Calculated Array for lay : " + JSON.stringify(redc))
            //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))


            let finalCalculatedArray = []
            if (redcBack.length > 0) {

              // Combine redcBack and redc to create the final calculated array
              finalCalculatedArray = redcBack.map((entryBack) => {
                const entryLay = redc.find((entry) => entry.request == entryBack.request);

                if (entryLay) {
                  entryBack.pl += entryLay.pl;
                }

                return entryBack;
              });
            } else {
              finalCalculatedArray = redc
            }

            //// console.log("Final Calculated Array : ", finalCalculatedArray)
            fancyBackLayResult.push(finalCalculatedArray)
          }


          //// console.log("final calc fancy array: ", fancyBackLayResult);
          // Initialize mostNegativeValue
          let mostNegativeValue = 0;

          // Iterate over each sub-array in fancyBackLayResult
          fancyBackLayResult.forEach(subArray => {
            // Find the maximum negative pl value in the current sub-array
            const negativePls = subArray.filter(item => item.pl < 0);
            if (negativePls.length > 0) {
              const maxNegativePl = Math.min(...negativePls.map(item => item.pl));
              // Add the maximum negative pl value to mostNegativeValue
              mostNegativeValue += maxNegativePl;
            }
          });

          //// console.log("Most negative value: ", mostNegativeValue);


          //console.log("final calc fancy array in header : ", fancyBackLayResult);
          //console.log("Maximum Negative Value in header :", maxNegativeValue);

          const totalLiabilityFancy = filterFancy.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("total Liabality of facny  : ", totalLiabilityFancy)

          exposure += mostNegativeValue;



        });
        console.log("Final Exposure of MO & BM & Fancy: ", exposure)
        setUserExposure(exposure)
        console.log("Balance Api Balance is : ", apiBalance)
        const calcBalance = apiBalance + exposure
        setuserBalance(calcBalance)

        //console.log("Updated bet Id is : ", updateBetId)

        if (betSend === true && updateBetId != '') {

          const updateQuery = await fetch(`https://api.s2bet.in/updateUnmatchBet`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, BetId: updateBetId, exposure: exposure })
          });

          const queryResp = await updateQuery.json()
          if (queryResp.isSuccess) {
            //console.log('Bet updated from header successflly..')
            setupdateBetId('')
          } else {
            //console.log("Bet not updated in header..")
            setupdateBetId('')
          }

        }

        // console.log("fetchExposure complete from Header...")

      } else {
        ////console.log("Error in fetching Bets exposure api in Header ");
      }
    } catch (error) {
      console.error('Error fetching bets exposure api', error);
    }
  };

  const fetchExposure1 = async () => {

    try {
      // console.log("Fetch exposure 1 START at Header ...")

      let apiBalance = 0;

      const responseBal = await fetch(`https://api.s2bet.in/userBalance/${userId}`);
      const resultBal = await responseBal.json();

      if (resultBal.isSuccess) {
        apiBalance = resultBal.mainBalance;

        ////console.log("user api Balance is : ", resultBal.mainBalance)

        // setuserBalance(calcBalance)
        // setUserAmount(result.mainBalance)
      }


      const responseRunner = await fetch(`https://api.s2bet.in/getRunners`);
      // const responseRunner = await fetch(`https://api.s2bet.in/getRunnersByEventMarket/${gameId}/${marketId}`)
      const resultRunner = await responseRunner.json();

      const runnersSelectionIds = [];

      if (resultRunner.isSuccess && resultRunner.data.length > 0) {
        // Extracting SelectionId and Market from each item in the resultRunner.data array
        const filterRunnerSId = resultRunner.data.map(item => ({
          eventId: item.EventId,
          selectionId: item.SelectionId,
          market: item.Market
        }));

        runnersSelectionIds.push(...filterRunnerSId);
      }

      //console.log("Runner Selection ID in fetch Exposure function  : ", runnersSelectionIds);

      const matchOddsBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Match Odds")

      const SBetsByRunner = matchOddsBetsByRunner.filter(runner => runner.selectionId);

      //console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunner);


      const BookMakerBetsByRunner = runnersSelectionIds.filter(runner => runner.market == "Bookmaker")

      const SBetsByRunnerBM = BookMakerBetsByRunner.filter(runner => runner.selectionId);

      ////console.log("SBetsByRunner Selection IDs of MAtch ODds  : ", SBetsByRunnerBM);

      const response = await fetch(`https://api.s2bet.in/getExposure/${userId}`);
      const result = await response.json();

      if (result.isSuccess) {
        ////console.log("Exposure Api data is : ", result.data);


        let exposure = 0;

        // console.log("result data : ", result.data)
        // console.log("Fetch exposure 1 Before forEach at Header ...")
        // Iterate over each unique EventId
        result.data.forEach(event => {

          // NEXT TEAM For BACK /////////////

          const filterODD = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "Matchodds" &&
              item.type == "back" &&
              SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filter ODD Next team : ", filterODD)

          // Get unique selectionIds from filterODDLay
          const filterODDSelectionIds = new Set(filterODD.map(item => item.selectionId));

          ////console.log("Filter odd SelectionIds ssss : ", filterODDSelectionIds)

          const filterOddAgainForOdd = SBetsByRunner.filter(item => !filterODDSelectionIds.has(item.selectionId)
            && filterODD.some(odds => odds.eventId == item.eventId));

          ////console.log("Filter odd again for odd ssss : ", filterOddAgainForOdd)

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossOdd = filterOddAgainForOdd.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("Filter odd again for odd with profit loss ssss : ", filterOddAgainWithProfitLossOdd)
          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayForOdd = [...filterODD, ...filterOddAgainWithProfitLossOdd];

          ////console.log("Next Team for Odd: " + JSON.stringify(combinedArrayForOdd));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionId = {};
          const totalLossBySelectionId = {};

          // Calculate net profit for each selectionId
          combinedArrayForOdd.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionId.hasOwnProperty(selectionId)) {
              totalProfitBySelectionId[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionId.hasOwnProperty(selectionId)) {
              totalLossBySelectionId[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionId[selectionId] += profit;
            totalLossBySelectionId[selectionId] += loss;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionId = {};
          Object.keys(totalProfitBySelectionId).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelections = Object.keys(totalLossBySelectionId)
              .filter(otherSelectionId => otherSelectionId !== selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionId[otherSelectionId], 0);

            const profit = totalProfitBySelectionId[selectionId];
            const loss = totalLossBySelectionId[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionId[selectionId] = profit + totalLossToOtherSelections;
          });

          // Example usage:
          ////console.log("Total Profit by SelectionId for Odd: ", totalProfitBySelectionId);
          ////console.log("Total Loss by SelectionId for Odd : ", totalLossBySelectionId);
          ////console.log("Net Profit by SelectionId for Odd : ", netProfitBySelectionId);

          // NEXT TEAM For LAY /////////////

          const filterODDLay = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "Matchodds" &&
              item.type == "lay" &&
              SBetsByRunner.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          //console.log("filterODDLay fltered data : ", filterODDLay)
          // Get unique selectionIds from filterODDLay
          const filterODDLaySelectionIds = new Set(filterODDLay.map(item => item.selectionId));

          // Filter items from odds based on selectionId not present in filterODDLay
          const filterOddAgain = SBetsByRunner.filter(item => !filterODDLaySelectionIds.has(item.selectionId) && filterODDLay.some(odds => odds.eventId == item.eventId));

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLoss = filterOddAgain.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("filter odd Again : " + JSON.stringify(filterOddAgainWithProfitLoss));

          ////console.log("Next Team for Lay : " + JSON.stringify(filterODDLay));

          ////console.log("Odds item : " + JSON.stringify(SBetsByRunner.length))

          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArray = [...filterODDLay, ...filterOddAgainWithProfitLoss];

          //console.log("Combined Both Array: " + JSON.stringify(combinedArray));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdLay = {};
          const totalLossBySelectionIdLay = {};

          // Calculate net profit for each selectionId
          combinedArray.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdLay.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdLay[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdLay.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdLay[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdLay[selectionId] += loss;
            totalLossBySelectionIdLay[selectionId] += profit;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdLay = {};
          Object.keys(totalProfitBySelectionIdLay).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelections = Object.keys(totalLossBySelectionIdLay)
              .filter(otherSelectionId => otherSelectionId != selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLay[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdLay[selectionId];
            const loss = totalLossBySelectionIdLay[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdLay[selectionId] = profit + totalLossToOtherSelections;
          });


          //console.log("Total Profit by SelectionId Odd: ", netProfitBySelectionId)
          //console.log("Total Loss by SelectionId Odd: ", totalLossBySelectionId)
          //console.log("Total Profit by SelectionId Lay: ", totalProfitBySelectionIdLay);
          //console.log("Total Loss by SelectionId Lay: ", totalLossBySelectionIdLay);
          //console.log("Net Profit by SelectionId Lay: ", netProfitBySelectionIdLay);

          // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
          const finalProfitMatchOdd = {};

          Object.keys(netProfitBySelectionId).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            //console.log("Sleection ID of final profit matchodd : ", selectionId)
            finalProfitMatchOdd[selectionId] = (netProfitBySelectionId[selectionId] || 0) + (netProfitBySelectionIdLay[selectionId] || 0);
          });

          if (Object.keys(netProfitBySelectionId).length === 0) {
            // Object is empty
            Object.keys(netProfitBySelectionIdLay).forEach(selectionId => {
              // Sum the corresponding values for each selectionId
              //console.log("Selection ID of final profit match odd: ", selectionId);
              finalProfitMatchOdd[selectionId] = netProfitBySelectionIdLay[selectionId] || 0;
            });
          }



          //console.log("Final Profit for Match Odd: ", finalProfitMatchOdd);

          const negativeProfits = Object.values(finalProfitMatchOdd).filter(profit => profit < 0);

          // If there are negative profits, find the maximum among them
          if (negativeProfits.length > 0) {
            const maxNegativeProfit = Math.min(...negativeProfits);
            //console.log("Maximum negative profit from finalProfitMatchOdd: ", maxNegativeProfit);

            exposure += maxNegativeProfit;
          } else {
            //console.log("No negative profits found in finalProfitMatchOdd.");
          }

          // For Book Maker

          ////console.log("///////   Book Maker /////")
          // For BACK !!!!!!!

          const filtermatchOddsBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "back")

          const totalProfitBM = filtermatchOddsBM.reduce((sum, item) => sum + item.profit, 0);

          ////console.log("Total Profit for back BookMaker : ", totalProfitBM);
          const totalLossBM = filtermatchOddsBM.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Loss for back BookMaker : ", totalLossBM);

          ////console.log("Match Odd filter BookMaker : " + JSON.stringify(filtermatchOddsBM))

          // For LAY !!!!!!

          const filtermatchOddsLayBM = event.data.filter(item => item.market == "BookMaker" && SBetsByRunnerBM.some(runner => runner.selectionId == item.selectionId) && item.type == "lay")

          const totalProfitLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.profit, 0);

          ////console.log("Total Loss for Lay  BookMaker : ", totalProfitLayBM);
          const totalLossLayBM = filtermatchOddsLayBM.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("Total Profit for Lay BookMaker : ", totalLossLayBM);

          ////console.log("Match Odd filter for lay BookMaker : " + JSON.stringify(filtermatchOddsLayBM))

          // NEXT TEAM For BACK /////////////

          const filterODDBM = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "BookMaker" &&
              item.type == "back" &&
              SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filter ODD Next team BookMaker : ", filterODDBM)

          // Get unique selectionIds from filterODDLay
          const filterODDSelectionIdsBM = new Set(filterODDBM.map(item => item.selectionId));

          ////console.log("Filter odd SelectionIds ssss BookMaker : ", filterODDSelectionIdsBM)

          const filterOddAgainForOddBM = SBetsByRunnerBM.filter(item => !filterODDSelectionIdsBM.has(item.selectionId)
            && filterODDBM.some(odds => odds.eventId == item.eventId));



          ////console.log("Filter odd again for odd ssss BookMaker : ", filterOddAgainForOddBM)

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossOddBM = filterOddAgainForOddBM.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("Filter odd again for odd with profit loss ssss BookMaker : ", filterOddAgainWithProfitLossOddBM)
          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayForOddBM = [...filterODDBM, ...filterOddAgainWithProfitLossOddBM];

          ////console.log("Next Team for Odd BookMaker : " + JSON.stringify(combinedArrayForOddBM));
          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdBM = {};
          const totalLossBySelectionIdBM = {};

          // Calculate net profit for each selectionId
          combinedArrayForOddBM.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdBM.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdBM[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdBM.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdBM[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdBM[selectionId] += profit;
            totalLossBySelectionIdBM[selectionId] += loss;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdBM = {};
          Object.keys(totalProfitBySelectionIdBM).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdBM)
              .filter(otherSelectionId => otherSelectionId != selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdBM[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdBM[selectionId];
            const loss = totalLossBySelectionIdBM[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdBM[selectionId] = profit + totalLossToOtherSelectionsBM;
          });


          // Example usage:
          ////console.log("Total Profit by SelectionId for Odd BookMaker: ", totalProfitBySelectionIdBM);
          ////console.log("Total Loss by SelectionId for Odd BookMaker : ", totalLossBySelectionIdBM);
          ////console.log("Net Profit by SelectionId for Odd  BookMaker : ", netProfitBySelectionIdBM);

          // NEXT TEAM For LAY /////////////

          const filterODDLayBM = event.data.filter(item => {
            // Check if the market is "Matchodds", type is "back", 
            // and there is at least one matching selectionId in the odds array
            return item.market == "BookMaker" &&
              item.type == "lay" &&
              SBetsByRunnerBM.some(oddsitem => oddsitem.selectionId == item.selectionId);
          });

          ////console.log("filterODDLay fltered data BookMaker : ", filterODDLayBM)
          // Get unique selectionIds from filterODDLay
          const filterODDLaySelectionIdsBM = new Set(filterODDLayBM.map(item => item.selectionId));

          // Filter items from odds based on selectionId not present in filterODDLay
          const filterOddAgainBM = SBetsByRunnerBM.filter(item => !filterODDLaySelectionIdsBM.has(item.selectionId) && filterODDLayBM.some(odds => odds.eventId == item.eventId));

          // Add profit = 0 and loss = 0 to the items in filterOddAgain
          const filterOddAgainWithProfitLossBM = filterOddAgainBM.map(item => ({
            ...item,
            profit: 0,
            liability: 0,
          }));

          ////console.log("filter odd Again BookMaker : " + JSON.stringify(filterOddAgainWithProfitLossBM));

          ////console.log("Next Team for Lay BookMaker : " + JSON.stringify(filterODDLayBM));

          ////console.log("Odds item BookMaker : " + JSON.stringify(SBetsByRunnerBM.length))

          // Combine filterODDLay and filterOddAgainWithProfitLoss into a single array
          const combinedArrayBM = [...filterODDLayBM, ...filterOddAgainWithProfitLossBM];

          ////console.log("Combined Both Array BookMaker : " + JSON.stringify(combinedArrayBM));

          // Create an object to store total profit for each selectionId
          const totalProfitBySelectionIdLayBM = {};
          const totalLossBySelectionIdLayBM = {};

          // Calculate net profit for each selectionId
          combinedArrayBM.forEach(item => {
            const selectionId = item.selectionId;
            const profit = item.profit;
            const loss = item.liability;

            // If selectionId is not in the object, add it
            if (!totalProfitBySelectionIdLayBM.hasOwnProperty(selectionId)) {
              totalProfitBySelectionIdLayBM[selectionId] = 0;
            }

            // If selectionId is not in the object, add it
            if (!totalLossBySelectionIdLayBM.hasOwnProperty(selectionId)) {
              totalLossBySelectionIdLayBM[selectionId] = 0;
            }

            // Add profit to the total for this selectionId
            totalProfitBySelectionIdLayBM[selectionId] += loss;
            totalLossBySelectionIdLayBM[selectionId] += profit;
          });

          // Calculate the net profit (profit - loss) for each selectionId
          const netProfitBySelectionIdLayBM = {};
          Object.keys(totalProfitBySelectionIdLayBM).forEach(selectionId => {
            // Calculate total loss to other selectionIds
            const totalLossToOtherSelectionsBM = Object.keys(totalLossBySelectionIdLayBM)
              .filter(otherSelectionId => otherSelectionId !== selectionId)
              .reduce((acc, otherSelectionId) => acc + totalLossBySelectionIdLayBM[otherSelectionId], 0);

            const profit = totalProfitBySelectionIdLayBM[selectionId];
            const loss = totalLossBySelectionIdLayBM[selectionId];

            // Subtract total loss to other selectionIds from the profit
            netProfitBySelectionIdLayBM[selectionId] = profit + totalLossToOtherSelectionsBM;
          });


          ////console.log("Total Profit by SelectionId Odd BookMaker : ", netProfitBySelectionIdBM)
          ////console.log("Total Loss by SelectionId Odd BookMaker : ", totalLossBySelectionIdBM)
          ////console.log("Total Profit by SelectionId Lay BookMaker : ", totalProfitBySelectionIdLayBM);
          ////console.log("Total Loss by SelectionId Lay BookMaker : ", totalLossBySelectionIdLayBM);
          ////console.log("Net Profit by SelectionId Lay BookMaker : ", netProfitBySelectionIdLayBM);

          // Assuming netProfitBySelectionId and netProfitBySelectionIdLay have the same selectionIds
          const finalProfitMatchOddBM = {};

          Object.keys(netProfitBySelectionIdBM).forEach(selectionId => {
            // Sum the corresponding values for each selectionId
            finalProfitMatchOddBM[selectionId] = (netProfitBySelectionIdBM[selectionId] || 0) + (netProfitBySelectionIdLayBM[selectionId] || 0);
          });

          if (Object.keys(netProfitBySelectionIdBM).length === 0) {
            // Object is empty
            Object.keys(netProfitBySelectionIdLayBM).forEach(selectionId => {
              // Sum the corresponding values for each selectionId
              //console.log("Selection ID of final profit Bookmaker : ", selectionId);
              finalProfitMatchOddBM[selectionId] = netProfitBySelectionIdLayBM[selectionId] || 0;
            });
          }


          //console.log("Final Profit for Book Maker: ", finalProfitMatchOddBM);

          const negativeProfitsBM = Object.values(finalProfitMatchOddBM).filter(profit => profit < 0);

          // If there are negative profits, find the maximum among them
          if (negativeProfitsBM.length > 0) {
            const maxNegativeProfit = Math.min(...negativeProfitsBM);
            ////console.log("Maximum negative profit from finalProfitMatchOddBM BookMaker : ", maxNegativeProfit);
            exposure += maxNegativeProfit;
          } else {
            ////console.log("No negative profits found in finalProfitMatchOddBM BookMaker.");
          }

          //  FOR Fancy ///////
          ////console.log("//// Fancy ///")

          const filterFancy = event.data.filter(item => item.market == 'Fancy')



          //console.log("Fancy bets calcualtion : ", filterFancy)


          const fancyBackLayResult = [];
          let maxNegativeBackToLay = 0;
          let maxNegativeLayToBack = 0;
          let maxNegativeValue = 0;


          // Step 2: Group items by selectionId
          const groupedItems = {};

          filterFancy.forEach(item => {
            if (!groupedItems[item.selectionId]) {
              groupedItems[item.selectionId] = [];
            }
            groupedItems[item.selectionId].push(item);
          });

          // Step 3: Calculate profit and liability for 'Back' and 'Lay' markets for each selectionId
          // for (const selectionId in groupedItems) {
          //   const backItems = groupedItems[selectionId].filter(item => item.type == 'back');
          //   const layItems = groupedItems[selectionId].filter(item => item.type == 'lay');

          //   let backProfit = 0;
          //   let backLiability = 0;
          //   let layProfit = 0;
          //   let layLiability = 0;

          //   backItems.forEach(item => {
          //     backProfit += item.profit;
          //     backLiability += item.liability;
          //   });

          //   layItems.forEach(item => {
          //     layProfit += item.profit;
          //     layLiability += item.liability;
          //   });

          //   // Step 4: Calculate back profit to lay liability and back liability to lay profit
          //   const backProfitToLayLiability = backProfit + layLiability;
          //   const backLiabilityToLayProfit = backLiability + layProfit;

          //   // Step 5: Console the results for each selectionId
          //  //console.log(`SelectionID: ${selectionId}`);
          //  //console.log("Back Profit to Lay Liability:", backProfitToLayLiability);
          //  //console.log("Back Liability to Lay Profit:", backLiabilityToLayProfit);


          //   fancyBackLayResult.push({
          //     selectionId: selectionId,
          //     backPLayL: backProfitToLayLiability,
          //     backLlayP: backLiabilityToLayProfit
          //   });

          //   const maxNegativeInIteration = Math.min(backProfitToLayLiability, backLiabilityToLayProfit);
          //   maxNegativeValue += maxNegativeInIteration
          // }


          for (const selectionId in groupedItems) {
            const items = groupedItems[selectionId];
            const minOddsRequest = Math.min(...items.map(item => item.oddsRequest));
            const maxOddsRequest = Math.max(...items.map(item => item.oddsRequest));



            // BET CALCULATION FOR BACK !!!!!!!!!

            const filterByBack = items.filter(item => item.type == "back")

            //console.log("Fancy filter for Back : " + JSON.stringify(filterByBack))


            // Group items by id
            const groupedByIdBack = {};
            filterByBack.forEach(item => {
              if (!groupedByIdBack[item.id]) {
                groupedByIdBack[item.id] = [];
              }
              groupedByIdBack[item.id].push(item);
            });

            // Calculate the range and set the length dynamically
            const rangeBack = maxOddsRequest - minOddsRequest + 10;

            // Create FancyBookArray for each group
            const resultArraysBack = [];
            const calcArrayBack = []
            Object.keys(groupedByIdBack).forEach(groupId => {
              const groupItems = groupedByIdBack[groupId];


              const FancyBookArray = Array.from({ length: rangeBack }, (_, index) => {
                const request = index + minOddsRequest - 5;
                const pl = request >= groupItems[0].oddsRequest ? groupItems[0].profit : groupItems[0].liability;

                return { request, pl };
              });

              resultArraysBack.push({ id: groupId, FancyBookArray });
            });

            // //console.log("Result Arrays For Back:", resultArraysBack);

            const redcBack = resultArraysBack.reduce((acc, current) => {
              current.FancyBookArray.forEach((entry) => {
                const existingEntry = acc.find((item) => item.request == entry.request);

                if (existingEntry) {
                  existingEntry.pl += entry.pl;
                } else {
                  acc.push({ request: entry.request, pl: entry.pl });
                }
              });

              return acc;
            }, []);

            // //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))



            // BET CALCULATION FOR LAY !!!!!!!!!

            const filterByLay = items.filter(item => item.type == "lay")

            //console.log("Fancy filter for Lay : " + JSON.stringify(filterByLay))


            // Group items by id
            const groupedById = {};
            filterByLay.forEach(item => {
              if (!groupedById[item.id]) {
                groupedById[item.id] = [];
              }
              groupedById[item.id].push(item);
            });

            // Calculate the range and set the length dynamically
            const range = maxOddsRequest - minOddsRequest + 10;

            // Create FancyBookArray for each group
            const resultArrays = [];
            const calcArray = []
            Object.keys(groupedById).forEach(groupId => {
              const groupItems = groupedById[groupId];


              const FancyBookArray = Array.from({ length: range }, (_, index) => {
                const request = index + minOddsRequest - 5;
                const pl = request >= groupItems[0].oddsRequest ? groupItems[0].liability : groupItems[0].profit;

                return { request, pl };
              });

              resultArrays.push({ id: groupId, FancyBookArray });
            });

            //console.log("Result Arrays:", resultArrays);

            const redc = resultArrays.reduce((acc, current) => {
              current.FancyBookArray.forEach((entry) => {
                const existingEntry = acc.find((item) => item.request == entry.request);

                if (existingEntry) {
                  existingEntry.pl += entry.pl;
                } else {
                  acc.push({ request: entry.request, pl: entry.pl });
                }
              });

              return acc;
            }, []);

            //console.log("Calculated Array for lay : " + JSON.stringify(redc))
            //console.log("Calculated Array for Back : " + JSON.stringify(redcBack))


            let finalCalculatedArray = []
            if (redcBack.length > 0) {

              // Combine redcBack and redc to create the final calculated array
              finalCalculatedArray = redcBack.map((entryBack) => {
                const entryLay = redc.find((entry) => entry.request == entryBack.request);

                if (entryLay) {
                  entryBack.pl += entryLay.pl;
                }

                return entryBack;
              });
            } else {
              finalCalculatedArray = redc
            }

            //// console.log("Final Calculated Array : ", finalCalculatedArray)
            fancyBackLayResult.push(finalCalculatedArray)
          }


          //// console.log("final calc fancy array: ", fancyBackLayResult);
          // Initialize mostNegativeValue
          let mostNegativeValue = 0;

          // Iterate over each sub-array in fancyBackLayResult
          fancyBackLayResult.forEach(subArray => {
            // Find the maximum negative pl value in the current sub-array
            const negativePls = subArray.filter(item => item.pl < 0);
            if (negativePls.length > 0) {
              const maxNegativePl = Math.min(...negativePls.map(item => item.pl));
              // Add the maximum negative pl value to mostNegativeValue
              mostNegativeValue += maxNegativePl;
            }
          });

          //// console.log("Most negative value: ", mostNegativeValue);


          //console.log("final calc fancy array in header : ", fancyBackLayResult);
          //console.log("Maximum Negative Value in header :", maxNegativeValue);

          const totalLiabilityFancy = filterFancy.reduce((sum, item) => sum + item.liability, 0);

          ////console.log("total Liabality of facny  : ", totalLiabilityFancy)

          exposure += mostNegativeValue;



        });
        // console.log("Fetch exposure 1 After forEach at Header ...")
        //console.log("Final Exposure of MO & BM & Fancy: ", exposure)
        setUserExposure(exposure)
        ////console.log("Balance Api Balance is : ", apiBalance)
        const calcBalance = apiBalance + exposure
        setuserBalance(calcBalance)

        //console.log("Updated bet Id is : ", updateBetId)

        if (betSend === true && updateBetId != '') {

          const updateQuery = await fetch(`https://api.s2bet.in/updateUnmatchBet`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, BetId: updateBetId, exposure: exposure })
          });

          const queryResp = await updateQuery.json()
          if (queryResp.isSuccess) {
            //console.log('Bet updated from header successflly..')
            setupdateBetId('')
          } else {
            //console.log("Bet not updated in header..")
            setupdateBetId('')
          }

        }

        // console.log("fetchExposure 1 complete from Header...")

      } else {
        ////console.log("Error in fetching Bets exposure api in Header ");
      }
    } catch (error) {
      console.error('Error fetching bets exposure api', error);
    }
  };

  const newfetchBalance = async () => {
    try {
      const bal = await fetch(`https://api.s2bet.in/expo1/${userId}`);
      const resultBal = await bal.json();
      // console.log('resullt bal : ', resultBal)
      if (resultBal.isSuccess) {
        // console.log(' main valance : ', resultBal.mainBalanceafterExpo)
        // const calcBalance = apiBalance + exposure
        setUserExposure(resultBal.FinalExpo)
        ////console.log("Balance Api Balance is : ", apiBalance)
        setuserBalance(resultBal.mainBalanceafterExpo)
      }
    } catch (error) {
      console.log('Error Fetching Balance : ', error)
    }
  }

  return (
    <>
      <div className="header-section">
        <div className="top_nav">
          <div className="righttogal righttogalhide">
            <span style={{ cursor: "pointer" }} onClick={openNav}>
              <i className="fa fa-bars" />{" "}
            </span>
          </div>
          <div className="nav_menu">
            <nav className="" role="navigation">
              <div className="nav_title">
                <Link to={"/"} className="site_title">
                  <img src="https://ag.s2bet.in/images/logo.png" alt="tree" />
                </Link>
              </div>
              <div className="marquee hidden-xs">
                <marquee id="marqmessage1" scrollamount={2}>
                  {siteNotice}{" "}
                </marquee>
                <span className="notifi_ico hidden-xs">
                  <img src="/images/bell-icon.png" />
                </span>
              </div>
              <div className="nav navbar-nav navbar-right">
                <ul>
                  <li className="belance-top hidden-xs">
                    <span className="wallet-icon">
                      <img
                        className="toggle-btn"
                        src="/images/wallet-icons.png"
                        alt=""
                      />
                    </span>
                    <a id="Wallet">
                      Main:{" "}
                      <span className="mWallet" id="balance">
                        {Number(userBalance).toFixed()}
                      </span>
                    </a>
                    <a id="UserLiability">
                      Exposure :{" "}
                      <span className="liability" id="expo">
                        {Number(userExposure).toFixed()}
                      </span>
                    </a>
                  </li>
                  <li className="">
                    <a
                      href="javascript:;"
                      className="user-profile dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img className="user_img" src="/images/user-ico.svg" />
                      {fullName} ({userName})
                      <span className="fa fa-angle-down" />
                    </a>
                    <ul className="dropdown-menu dropdown-usermenu pull-right">
                      <li>
                        <Link to={"/changePasswordNew"}>
                          Change Password
                        </Link>
                      </li>
                      <li className="dropdown-footer">
                        <Link onClick={handleLogout}>
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div >
        {/*top navigation */}
        < div id="lefttSidenav" className="left_col sidenav" >
          <a
            href="javascript:void(0)"
            className="closebtn righttogalhide"
            onClick={closeNav}
          >
            ×
          </a>
          <div className="left_col scroll-view">
            <div className="clearfix" />
            <nav id="cssmenu">
              {/*<div class="button"></div>*/}
              <ul className="nav">
                <li>
                  <Link to={"/"} className="" onClick={closeNav}>
                    <i className="fa fa-home hidden-xs" /> Dashboard
                  </Link>
                </li>
                <li>
                  {/* <a href='/inPlay' className='' onClick={closeNav}> */}
                  <Link to={"/inPlay"} className="" onClick={closeNav}>
                    In-Play
                  </Link>
                  {/* </a> */}
                </li>
                <li>
                  <Link to={"/runningmarketanalysis"} onClick={closeNav}>Running Market Analysis</Link>
                </li>
                <li className="has-sub" onClick={handleReportToggle} >
                  <span className="submenu-button" onClick={handleReportToggle}></span>
                  <span className="submenu-button" onClick={handleReportToggle}></span>
                  <Link>Report</Link>
                  <ul style={{ display: isReportOpen ? 'block' : 'none' }} className="open">
                    {/* Your submenu items */}
                    <li>
                      <Link to={"/accountStatement"} onClick={closeNav}>
                        Account Statement </Link>
                    </li>
                    <li>
                      <Link to={"/profitLoss"} onClick={closeNav}>
                        Profit &amp; Loss
                      </Link>
                    </li>
                    <li>
                      <Link to={"/betHistory"} onClick={closeNav}>Bet History</Link>
                    </li>
                    <li>
                      <Link to={"#"} onClick={closeNav}>Live Bet History</Link>
                    </li>
                    <li>
                      <Link to={"#"} onClick={closeNav}>Inter. Bet History</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-sub" onClick={handleLiveGameToggle}>
                  {/* <span className="submenu-button" onClick={handleLiveGameToggle}></span> */}
                  <span className="submenu-button" onClick={handleLiveGameToggle}></span>
                  <Link to={"#"}>Live Games</Link>
                  <ul style={{ display: isLiveGameOpen ? 'block' : 'none' }} className="open">
                    {/* <li><a href="/Casino">Casino 1</a></li>
                         <li><a href="/casino2user">Casino 2</a></li> */}
                    <li>
                      <Link to={"#"}>Casino</Link>
                    </li>
                    <li>
                      <Link to={"#"}>
                        Check Casino Result
                      </Link>
                    </li>
                    {/* <li><a href="/teenPattiT20">TeenPatti T20</a></li>
  
                        <li><a href="/teenPatti1Day">TeenPatti 1Day</a></li>
                        <li><a href="/AndarBahar">Andar Bahar</a></li>
                        <li><a href="/sevenupdown">Seven up Down</a></li>
                        <li><a href="/teenPattiDtl20">Dragon Tiger</a></li>
                        <li><a href="/thirtyTwoCardCasino">32 Cards</a></li>
                        <li><a href="/amarAkbarAnthony">Amar Akbar Anthony</a></li> */}
                  </ul>
                </li>
              </ul>
              <div className="social-links">
                <ul>
                  <li className="hidden-xs">
                    <a href="" className="download-apk-btn">
                      {" "}
                      <i className="fa fa-android" />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {/*sidebar menu */}
        </div >
      </div >
      <div className="mrq hidden-lg">
        <div className="mob_balance">
          <a id="Wallet">
            <img src="/images/wallet-icons.png" alt="" /> Main:{" "}
            <span className="mWallet" id="mbalance">
              {Number(userBalance).toFixed()}
            </span>{" "}
          </a>
          <a id="UserLiability">
            Exposure :{" "}
            <span className="liability" id="mlib">
              {Number(userExposure).toFixed()}
            </span>{" "}
          </a>
        </div>
        <div className="marquee">
          <Link style={{ color: "#fff" }}>
            <marquee id="marqmessage" scrollamount={2}>
              {siteNotice}{" "}
            </marquee>
          </Link>
        </div>
      </div>
      <div
        id="success"
        className="modal fade notifi-sec notification_popup "
        role="dialog"
        style={{ display: 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="">
              <div className="modal-body">
                <div className="success-box">
                  <div className="face">
                    <svg
                      id="Capa_1"
                      enableBackground="new 0 0 512 512"
                      height={512}
                      viewBox="0 0 512 512"
                      width={512}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <circle cx={128} cy={264} r={25} />
                        <path d="m128 319c-18.196 0-33 14.804-33 33v25h66v-25c0-18.196-14.804-33-33-33z" />
                        <path d="m393 309c-82.159 0-149-66.841-149-149 0-13.49 1.813-26.563 5.189-39h-204.189c-24.813 0-45 20.187-45 45v260c0 24.813 20.187 45 45 45h373c24.813 0 45-20.187 45-45v-134.501c-20.883 11.162-44.713 17.501-70 17.501zm-145 4h56c8.284 0 15 6.716 15 15s-6.716 15-15 15h-56c-8.284 0-15-6.716-15-15s6.716-15 15-15zm-57 79c0 8.284-6.716 15-15 15h-96c-8.284 0-15-6.716-15-15v-40c0-19.979 9.36-37.803 23.912-49.353-9.83-9.941-15.912-23.595-15.912-38.647 0-30.327 24.673-55 55-55s55 24.673 55 55c0 15.052-6.082 28.706-15.912 38.647 14.552 11.55 23.912 29.374 23.912 49.353zm137 15h-80c-8.284 0-15-6.716-15-15s6.716-15 15-15h80c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                        <path d="m393 41c-65.617 0-119 53.383-119 119s53.383 119 119 119 119-53.383 119-119-53.383-119-119-119zm35.523 104.603-40 48c-2.868 3.441-7.101 5.397-11.522 5.397-.575 0-1.152-.033-1.731-.1-5.028-.584-9.423-3.664-11.687-8.192l-16-32c-3.705-7.409-.701-16.42 6.708-20.124 7.409-3.707 16.419-.702 20.125 6.708l5.743 11.486 25.317-30.381c5.304-6.364 14.763-7.224 21.126-1.921 6.365 5.304 7.225 14.762 1.921 21.127z" />
                      </g>
                    </svg>
                  </div>
                  <div className="shadow scale" />
                  <div className="message-pop">
                    <h2 id="model-popup-heading"> Success</h2>
                    <p id="successmes" className="modalSuccessErrMsg">
                      Bet Placed Successfully
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="error"
        className="modal fade notifi-sec notification_popup "
        role="dialog"
        style={{}}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="">
              <div className="modal-body">
                <div className="success-box">
                  <div className="face" style={{ border: "1px solid #ff2c2c" }}>
                    <svg
                      style={{ fill: "#ff2c2c" }}
                      height="512pt"
                      viewBox="0 0 512 512"
                      width="512pt"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                      <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" />
                      <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" />
                    </svg>
                  </div>
                  <div className="shadow scale" style={{ background: "#ff2c2c" }} />
                  <div className="message-pop">
                    <h2 id="model-popup-heading"> Error</h2>
                    <p id="errormes" className="modalSuccessErrMsg">
                      Something Went Wrong
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="notice"
        className="modal fade"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        style={{
          marginTop: "7% !important",
          background: "none !important",
          paddingLeft: 5
        }}
      >
        <div className="modal-dialog" style={{ margin: "0 auto" }}>
          {/* Modal content*/}
          <div className="modal-content" style={{ border: "1px solid #000" }}>
            <div
              className="modal-header"
              style={{
                background: "linear-gradient(#b159c2,#853395)!important",
                textAlign: "center"
              }}
            >
              <h4 className="modal-title" style={{ color: "#fff", width: "100%" }}>
                4Bet Important Notice
              </h4>
            </div>
            <div className="modal-body" style={{ padding: "0px !important" }}>
              <div className="tab-content">
                <div id="" className="tab-pane fade in active">
                  <p style={{ padding: 10 }} id="impMsg"></p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-xs-12 col-lg-12" style={{ textAlign: "center" }}>
                <button
                  style={{
                    background: "linear-gradient(#b159c2,#853395)!important",
                    color: "#fff",
                    width: "30%"
                  }}
                  type="button"
                  id="msgClose"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="forModal" />
    </>







  )
}
