import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function LeftPanel() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.s2bet.in/GetInPlaySportEvents');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const result = await response.json();

                let combinedData = [];

                if (Array.isArray(result.data.sportsEventModelToday)) {
                    combinedData.push(...result.data.sportsEventModelToday);
                }

                if (Array.isArray(result.data.sportsEventModelTomorrow)) {
                    combinedData.push(...result.data.sportsEventModelTomorrow);
                }

                ////console.log("Enter in function ....", combinedData.flat())
                const flattenedArray = combinedData.flat()
                setData(flattenedArray);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Optionally, you can set some default data or show a message to the user
            }
        };

        fetchData();

        // Fetch data every 1 minute (60000 milliseconds)
        // const intervalId = setInterval(fetchData, 5000);

        // return () => clearInterval(intervalId);
    }, []);

    const handleEvent = (eid, gameId, marketId) => {
        navigate(`/EVENT/${eid}/${gameId}/${marketId}`);
    }


    return (
        <>
            <div className="left-side-menu">
                <div className="panel-group" id="accordion">
                    <h6>Sports</h6>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <Link to={"/inPlay"}>    In-Play </Link>
                            </h4>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseOne"
                                >
                                    Cricket <span className="extender" />
                                </a>
                            </h4>
                        </div>

                        <div id="collapseOne" className="panel-collapse collapse">
                            <div className="panel-body">
                                <ul id="cricketList">
                                    {data.map((item) => (
                                        item.eid === '4' && (
                                            <li key={item.id}>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEvent(item.eid, item.gameId, item.marketId,);
                                                    }}
                                                >
                                                    <i className="fa fa-angle-double-right" />
                                                    {item.eventName}
                                                </a>

                                            </li>)))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*- Tennis */}
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="javascript:void(0) #collapseTwo"
                                >
                                    Tennis <span className="extender" />
                                </a>
                            </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                            <div className="panel-body">
                                <ul id="tennisList">
                                    {data.map((item) => (
                                        item.eid === '2' && (
                                            <li key={item.id}>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEvent(item.eid, item.gameId, item.marketId,);
                                                    }}
                                                >
                                                    <i className="fa fa-angle-double-right" />
                                                    {item.eventName}
                                                </a>
                                                <ul id="" />
                                            </li>)))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*- Soccer */}
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title">
                                <a
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="javascript:void(0) #collapsethree"
                                >
                                    Soccer <span className="extender" />
                                </a>
                            </h4>
                        </div>
                        <div id="collapsethree" className="panel-collapse collapse">
                            <div className="panel-body">
                                <ul id="soccerList">
                                    {data.map((item) => (
                                        item.eid === '1' && (
                                            <li key={item.id}>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleEvent(item.eid, item.gameId, item.marketId,);
                                                    }}
                                                >
                                                    <i className="fa fa-angle-double-right" />
                                                    {item.eventName}
                                                </a>
                                                <ul id="" />
                                            </li>
                                        )))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LeftPanel