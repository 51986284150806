import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Header from './Header';
import LeftPanel from './LeftPanel';
import Footer from './Footer';
import { useChipValues } from './ChipValuesContext';

function InPlay() {

    const navigate = useNavigate();
    const { isBetlock, setIsBetlock } = useChipValues();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await fetch('https://api.s2bet.in/GetInPlaySportEvents');
                const result = await response.json();
                //console.log('In play api response is from local host : ' + JSON.stringify(result));
                // Ensure that result.data.sportsEventModelInPlay is an array
                if (Array.isArray(result.data.sportsEventModelInPlay)) {
                    // Format the date in each item before setting it in the state
                    const formattedData = result.data.sportsEventModelInPlay.map(item => ({
                        ...item,
                        formattedDate: format(new Date(item.eventDate), 'dd MMM HH:mm:ss'),
                    }));
                    // Sort the data by the ISO date string
                    formattedData.sort((a, b) => new Date(a.formattedDate) - new Date(b.formattedDate));


                   //console.log("Sorted inplay data : " + JSON.stringify(formattedData));

                    setData(formattedData);
                } else {
                    console.error('Invalid data format:', result);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch data initially
        fetchData();

        // Set up an interval to fetch data every 5 seconds (adjust as needed)
        const intervalId = setInterval(fetchData, 10000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // No empty dependency array, effect will run continuously


    const handleEvent = (eid, gameId) => {
        navigate(`/EVENT/${eid}/${gameId}`);
    }

    const sendLock = (eid, gameId, marketId, isLock, isMO, isBM, isFancy) => {
    //    console.log("is lock is : ", isLock)
        // setIsBetlock(isLock)
        navigate(`/EVENT/${eid}/${gameId}/${marketId}`, { state: { isBetlock: isLock, isMO, isBM, isFancy } });
    }


    return (
        <>
            <div className="main_container" id="sticky">
                <div className="right_col" role="main" style={{}}>
                    <div className="fullrow tile_count">
                        <div className="col-md-7">
                            <div id="UpCommingData">
                                <div
                                    className="sport-highlight-content tabs "
                                    id="accountView"
                                    role="main"
                                >
                                    <div className="modal-body tab-content matches-all">
                                        <span id="msg_error" />
                                        <span id="errmsg" />
                                        <div
                                            className="table table-striped jambo_table bulk_action"
                                            id="matchesData"
                                        >
                                            {/* Cricket */}
                                            <div id="user_row_" className="lotus-title">
                                                <div className="head-matchname">
                                                    <div className="match-head">Cricket</div>
                                                    <div className="match-odds-right">
                                                        <div className="items-up-odds">1</div>
                                                        <div className="items-up-odds">x</div>
                                                        <div className="items-up-odds">2</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.map((item, index) => (
                                                item.eid === '4' && (

                                                    <div key={index} className="sport_row " >
                                                        <div className="sport_name">
                                                            {/* <a href={`/event/${item.eid}/${item.gameId}`}>{item.eventName}</a> */}
                                                            {/* <a href='/event'>{item.eventName}</a> */}
                                                            <Link
                                                                // to={`/EVENT/${item.eid}/${item.gameId}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                                                }}>{item.eventName}</Link>
                                                            {/* <a
                                                                href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    handleEvent(item.eid, item.gameId);
                                                                }}
                                                            >{item.eventName}</a> */}
                                                            <time>
                                                                <i className="fa fa-clock-o" />
                                                                {item.formattedDate}
                                                            </time>
                                                            <span>
                                                                <i className="fa fa-star-o" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                        <div className="match_status">
                                                            <span className="inplay_txt">In-play </span>
                                                        </div>
                                                        <div className="match_odds_front">
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282916606"
                                                            >
                                                                {item.back11}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282916606"
                                                            >
                                                                {item.lay11}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.back1}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.lay1}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282960443"
                                                            >
                                                                {item.back12}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282960443"
                                                            >
                                                                {item.lay12}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )))}

                                            {/* Soccer */}
                                            <div id="user_row_" className="lotus-title">
                                                <div className="head-matchname">
                                                    <div className="match-head">Soccer</div>
                                                    <div className="match-odds-right">
                                                        <div className="items-up-odds">1</div>
                                                        <div className="items-up-odds">x</div>
                                                        <div className="items-up-odds">2</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.map((item, index) => (
                                                item.eid === '1' && (

                                                    <div key={index} className="sport_row ">
                                                        <div className="sport_name">
                                                            <Link
                                                                // to={`/EVENT/${item.eid}/${item.gameId}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                                                }}
                                                            >{item.eventName}</Link>
                                                            <time>
                                                                <i className="fa fa-clock-o" />
                                                                {item.formattedDate}
                                                            </time>
                                                            <span>
                                                                <i className="fa fa-star-o" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                        <div className="match_status">
                                                            <span className="inplay_txt">In-play </span>
                                                        </div>
                                                        <div className="match_odds_front">
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282916606"
                                                            >
                                                                {item.back11}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282916606"
                                                            >
                                                                {item.lay11}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.back1}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.lay1}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282960443"
                                                            >
                                                                {item.back12}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282960443"
                                                            >
                                                                {item.lay12}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )))}

                                            {/* Tennis */}
                                            <div id="user_row_" className="lotus-title">
                                                <div className="head-matchname">
                                                    <div className="match-head">Tennis</div>
                                                    <div className="match-odds-right">
                                                        <div className="items-up-odds">1</div>
                                                        <div className="items-up-odds">x</div>
                                                        <div className="items-up-odds">2</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data.map((item, index) => (
                                                item.eid === '2' && (

                                                    <div key={index} className="sport_row ">
                                                        <div className="sport_name">
                                                            <Link
                                                                // to={`/EVENT/${item.eid}/${item.gameId}`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                                                }}
                                                            >{item.eventName}</Link>
                                                            <time>
                                                                <i className="fa fa-clock-o" />
                                                                {item.formattedDate}
                                                            </time>
                                                            <span>
                                                                <i className="fa fa-star-o" aria-hidden="true" />
                                                            </span>
                                                        </div>
                                                        <div className="match_status">
                                                            <span className="inplay_txt">In-play </span>
                                                        </div>
                                                        <div className="match_odds_front">
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282916606"
                                                            >
                                                                {item.back11}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282916606"
                                                            >
                                                                {item.lay11}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.back1}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay328828297461"
                                                            >
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.lay1}
                                                            </span>
                                                            <span
                                                                className="back-cell odds32882829"
                                                                id="back3288282960443"
                                                            >
                                                                {item.back12}
                                                            </span>
                                                            <span
                                                                className="lay-cell odds32882829"
                                                                id="lay3288282960443"
                                                            >
                                                                {item.lay12}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="MatchOddInfo" />
                        </div>
                        <div className="col-md-5 col-xs-12 matchBox">
                            <div className="other-items">
                                <div className="balance-box">
                                    <div className="panel-heading">
                                        <h3 className="bal-tittle">Mini Games </h3>
                                        <span className="pull-right clickable">
                                            <i className="fa fa-chevron-down" />
                                        </span>
                                    </div>
                                    <div className="balance-panel-body"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /page content */}
                <Footer />
            </div>
        </>
    )
}

export default InPlay