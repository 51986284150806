import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer'
import { format, parseISO } from 'date-fns';

function ProfitLoss() {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState(getDefaultFromDate());
  const [toDate, setToDate] = useState(getDefaultToDate());
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [filterClicked, setFilterClicked] = useState(false);
  const [totalRows, setTotalRows] = useState(0); 
  const [isLoading, setIsLoading] = useState(false);

  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   if (filterClicked) {
  //     fetchData();
  //     setCurrentPage(1);
  //     setFilterClicked(false); // Reset the filterClicked state
  //   }

  // }, [filterClicked]);

  // const fetchData = async () => {

  //   const id = decryptCookie('id')
  //   try {
  //     const response = await fetch(`https://api.s2bet.in/GetProfitAndLoss/${id} `);
  //     const result = await response.json();
  //     //console.log('In LossPRofit api response is : ' + JSON.stringify(result.data.plMainVMList));

  //     if (Array.isArray(result.data.plMainVMList)) {
  //       // Format the date in each item before setting it in the state
  //       const formattedData = result.data.plMainVMList.map(item => ({
  //         ...item,
  //         // formattedPlaceTime: <Moment format='ddd D MMM h:mm a'>{item.placeTime}</Moment>,
  //       }));

  //       // Filter by date and Status
  //       const filteredData = formattedData.filter(item =>
  //         new Date(item.settledTime) >= new Date(fromDate) &&
  //         new Date(item.settledTime) <= new Date(toDate).setHours(23, 59, 59, 999) && (searchTerm === '' || item.event.toLowerCase().includes(searchTerm.toLowerCase())) && (selectedSport === 'all' || item.sportName.toLowerCase() === selectedSport.toLowerCase())
  //       );

  //       // Sort the data by the ISO date string
  //       filteredData.sort((a, b) => new Date(b.settledTime) - new Date(a.settledTime));

  //       //console.log("Formatted and Sorted Bet History data:", filteredData);

  //       setData(filteredData)
  //     } else {
  //       console.error('Invalid data format:', result);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };


  const fetchData = async () => {
    const id = decryptCookie('id');
    let sport;
    if (selectedSport == 'all') {
      sport = 'all'
    } else if (selectedSport == 'cricket') {
      sport = 4
    } else if (selectedSport == 'soccer') {
      sport = 1
    } else if (selectedSport == 'tennis') {
      sport = 2
    } else if (selectedSport == 'fancy') {
      sport = 'Fancy'
    }
    try {
      setIsLoading(true);
      const response = await fetch(`https://api.s2bet.in/GetFilteredProfitAndLoss/${id}?fromDate=${fromDate} 00:00:00&toDate=${toDate} 23:59:59&selectedSport=${sport}&page=${currentPage}&totalPage=${perPage}&searchTerm=${encodeURIComponent(searchTerm)}`);
      const result = await response.json();

      if (Array.isArray(result.data.plMainVMList)) {
        // Format the date in each item before setting it in the state
        const formattedData = result.data.plMainVMList.map(item => ({
          ...item,
        }));

        setData(formattedData);
      } else {
        console.error('Invalid data format:', result);
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  function getDefaultFromDate() {
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
    return twoDaysAgo.toISOString().split('T')[0];
  }

  function getDefaultToDate() {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today.toISOString().split('T')[0];
  }

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleFilter = () => {
    fetchData(); // Trigger refetching with the updated filter criteria
    setCurrentPage(1);
    setFilterClicked(true);
  };


  const handleEvent = (marketId) => {
    navigate(`/userLedgerInside/${marketId}`);
  }


  // Pagination Logic
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  return (
    <>
    <style>{`
    .betTable {
      height: 600px;
      overflow-y: scroll;
    }
    @media (max-width: 767px) {
      .betTable {
        height: 400px;
        overflow-y: scroll;
      }
    }
  `}</style>
      {isLoading && <div className="spinner" id="loader-1" style={{ display: 'block' }}></div>}
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="col-md-12">
            <div className="title_new_at">
              Profit Loss Listing
              <select id="pages" style={{ color: "black" }} onChange={handlePerPageChange}
                value={perPage}>
                <option value={10} selected="selected">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <div className="pull-right">
                <button className="btn_common">
                  Back
                </button>{" "}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="filter_page data-background">
              <form
                method="get"
                id="formSubmit"
                className="form-horizontal form-label-left input_mask"
              >
                <div className="col-md-3 col-xs-6">
                  <select className="form-control" name="sportid" id="sportid" value={selectedSport}
                    onChange={(e) => setSelectedSport(e.target.value)}>
                    <option value="all" selected="selected">
                      All
                    </option>
                    <option value="cricket">Cricket</option>
                    <option value="soccer">Soccer</option>
                    <option value="tennis">Tennis</option>
                    <option value="fancy">Fancy</option>
                    {/* <option value="Live Casino">Live Casino</option> */}
                  </select>
                </div>
                <div className="col-md-2 col-xs-6">
                  <input
                    type="date"
                    name="from_date"
                    id="betsstartDate"
                    className="form-control col-md-7 col-xs-12 has-feedback-left"
                    placeholder="From date" onChange={handleFromDateChange}
                    value={fromDate}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-2 col-xs-6">
                  <input
                    type="date"
                    name="to_date"
                    id="betsendDate"
                    className="form-control col-md-7 col-xs-12 has-feedback-left"
                    placeholder="To date" onChange={handleToDateChange}
                    value={toDate}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-2 col-xs-6">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Via event name"
                    id="matchname"
                    name="searchTerm"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    maxLength={100}
                    size={50}
                  />
                </div>
                <div className="col-md-3 col-xs-12">
                  <button
                    type="button"
                    className="blue_button"
                    value="filter"
                    onClick={handleFilter}
                    data-attr="submit"
                  >
                    Filter
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div id="divLoading" />
            {/*Loading class */}
            <div className={data.length > 10 ? `table-responsive appendAjaxTbl data-background betTable` : `table-responsive appendAjaxTbl data-background`}>
              <table className="table table-striped jambo_table bulk_action">
                <thead>
                  <tr className="headings">
                    <th className="darkpurplecolor">S.No.</th>
                    <th className="lightgreencolor">Event Name</th>
                    <th className="darkpurplecolor">Market</th>
                    <th className="lightgreencolor">P_L</th>
                    <th className="darkpurplecolor">Commission</th>
                    <th className="lightgreencolor">Created On</th>
                  </tr>
                </thead>
                <tbody id="betlistdiv">
                  {currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * perPage + index + 1}</td>
                      <td>
                        {" "}
                        {/* <a href="#" onClick={(e) => {
                          e.preventDefault();
                          handleEvent(item.marketId);
                        }}>
                          {item.event}
                        </a> */}

                        <Link onClick={(e) => {
                          e.preventDefault();
                          handleEvent(item.marketId);
                        }}> {item.event} </Link>
                      </td>
                      <td>{item.oddsType}</td>
                      <td className={item.amount >= 0 ? "green" : "red"}>{item.amount}</td>
                      <td className="red">-0</td>
                      <td>{format(parseISO(item.settledTime), "dd MMM HH:mm:ss")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p id="items">Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, data.length)} of Entries {data.length}</p>
            <div className="pagination-row dataTables_paginate paging_simple_numbers">
              {Array.from({ length: Math.ceil(data.length / perPage) }, (_, i) => (
                <button
                  key={i + 1}
                  className={`paginate_button ${currentPage === i + 1 ? 'current' : ''}`}
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
          {/*footerlinks*/}
          {/*commanpopup*/}
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>


    </>
  )
}

export default ProfitLoss