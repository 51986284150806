import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom'
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer'

function UserLedgerInside() {

  const { marketId } = useParams();
  //console.log('Market ID from URL:', marketId);

  const [data, setData] = useState([]); //Filtered MatchOdds data
  const [filterFancy, setFilterFancy] = useState([]); //Filtered Fancy data
  const [calcAmount, setCalcAmount] = useState(''); //Calc MatchOdds Amount
  const [fancyCalcAmount, setFancyAmount] = useState(''); //Calc Fancy Amount
  const [combinedCalc, setCombinedCalc] = useState(''); //Calculate both amounts


  // Decrypt the encrypted cookie value
  const decryptCookie = (cookieName) => {
    const encryptedCookieValue = Cookies.get(cookieName);
    if (encryptedCookieValue) {
      const bytes = CryptoJS.AES.decrypt(encryptedCookieValue, 'secretKey');
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    return '';
  };


  useEffect(() => {
    const fetchData = async () => {
      const id = decryptCookie('id');

      try {
        const response = await fetch(`https://api.s2bet.in/GetProfitAndLoss/${id}`);
        const result = await response.json();
        // //console.log('In LossPRofit api response is : ' + JSON.stringify(result.data.plMainDetailVMList));

        if (Array.isArray(result.data.plMainDetailVMList)) {
          // Filter data by marketId
          const filterMarketId = result.data.plMainDetailVMList.filter(item => item.marketId === marketId);
          // Filter data by MatchOdds
          const filterMatchOdds = filterMarketId.filter(item => item.oddsType !== "Fancy");
          // Filter data by Fancy
          const filterFancy = filterMarketId.filter(item => item.oddsType === "Fancy");

          //console.log('Filtered market id data:', filterMarketId);
          //console.log('Filtered MatchOdds data:', filterMatchOdds);
          //console.log('Filter Fancy : ' + JSON.stringify(filterFancy));

          //Calc MatchOdds Amount
          const totalAmount = filterMatchOdds.reduce((accumulator, item) => accumulator + item.plAmount, 0);
          const roundedTotalAmount = totalAmount.toFixed(2);

          //Calc Fancy Amount
          const FancytotalAmount = filterFancy.reduce((accumulator, item) => accumulator + item.plAmount, 0);
          const FancyroundedAmount = FancytotalAmount.toFixed(2);
          //console.log("Fancy Rounded Amount : " + FancyroundedAmount)

          //Calculate both amounts
          const combinedTotalAmount = totalAmount + FancytotalAmount;
          const roundedCombinedTotalAmount = combinedTotalAmount.toFixed(2);
          //console.log("Combined Total Amount: " + roundedCombinedTotalAmount);

          setData(filterMatchOdds);
          setCalcAmount(roundedTotalAmount);
          setFilterFancy(filterFancy);
          setFancyAmount(FancyroundedAmount);
          setCombinedCalc(roundedCombinedTotalAmount);
        } else {
          console.error('Invalid data format:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up an interval to fetch data every 5 seconds (adjust as needed)
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // No empty dependency array, effect will run continuously

  return (
    <>
      <div className="main_container" id="sticky">
        <div className="right_col" role="main" style={{}}>
          <div className="row">
            <div className="col-md-12">
              <div className="title_new_at">
                Match Winner Market Bets
                <span id="matchpnl" style={{ float: "right" }}>
                  You {calcAmount >= 0 ? "Won" : "Lost"} {calcAmount}/- Coins
                </span>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div id="divLoading" />
              {/*Loading class */}
              <div className="table-responsive" id="filterdata" style={{ margin: 0 }}>
                <table
                  className="table table-striped jambo_table bulk_action"
                  style={{ margin: 0 }}
                >
                  <thead>
                    <tr className="headings">
                      <th style={{ background: "#654062", color: "#fff" }}>Rate</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>Amt.</th>
                      <th style={{ background: "#654062", color: "#fff" }}>Mode</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>Team</th>
                      <th style={{ background: "#654062", color: "#fff" }}>Result</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>
                        P&amp;L
                      </th>
                    </tr>
                  </thead>
                  <tbody id="matchBets">
                    {data.map((item) => (
                      <tr key={item.id} style={{ background: "#fff" }}>
                        <td>{item.odds}</td>
                        <td>{item.stake}</td>
                        <td>{item.type === "lay" ? "KHAI" : "LGAI"}</td>
                        <td>{item.selection}</td>
                        <td>{item.result}</td>
                        <td className={item.plAmount >= 0 ? "green" : "red"}>{item.plAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="title_new_at">
                Fancy Bets
                <span id="fancypnl" style={{ float: "right" }}>
                  You {fancyCalcAmount >= 0 ? "Won" : "Lost"} {fancyCalcAmount}/- Coins
                </span>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div id="divLoading" />
              {/*Loading class */}
              <div className="table-responsive" id="filterdata" style={{ margin: 0 }}>
                <table
                  className="table table-striped jambo_table bulk_action"
                  style={{ margin: 0 }}
                >
                  <thead>
                    <tr className="headings">
                      <th style={{ background: "#654062", color: "#fff" }}>Fancy</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>Runs</th>
                      <th style={{ background: "#654062", color: "#fff" }}>Rate</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>Amt.</th>
                      <th style={{ background: "#654062", color: "#fff" }}>Mode</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>Result</th>
                      <th style={{ background: "#83a95c", color: "#000" }}>
                        P&amp;L
                      </th>
                    </tr>
                  </thead>
                  <tbody id="fancyBets">
                    {filterFancy.map((item) => (
                      <tr key={item.id} style={{ background: "#fff" }}>
                        <td>{item.selection}</td>
                        <td>{item.odds}</td>
                        <td></td>
                        <td>{item.stake}</td>
                        <td>{item.type}</td>
                        <td>{item.result}</td>
                        <td className={item.plAmount >= 0 ? "green" : "red"}>{item.plAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div
                className="title_new_at"
                style={{ background: "#654062", color: "#fff" }}
              >
                Match Plus Minus
                <span id="totalpnl" style={{ float: "right" }}>
                  You {combinedCalc >= 0 ? "Won" : "Lost"} {combinedCalc}/- Coins
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div
                className="title_new_at"
                style={{ background: "#83a95c", color: "#000" }}
              >
                My Commission
                <span id="commpnl" style={{ float: "right" }}>
                  You Lost -0/- Coins
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div
                className="title_new_at"
                style={{ background: "#654062", color: "#fff" }}
              >
                Net Plus Minus
                <span id="netpnl" style={{ float: "right" }}>
                  You {combinedCalc >= 0 ? "Won" : "Lost"} {combinedCalc}/- Coins
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* /page content */}
        {/*footerlinks*/}
        <Footer />
        {/*commanpopup*/}
        {/*commanpopup*/}
      </div>


    </>
  )
}

export default UserLedgerInside