import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Header from './Header'
import LeftPanel from './LeftPanel'
import Footer from './Footer';

export default function Dashboard() {

  const navigate = useNavigate();
  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.s2bet.in/GetInPlaySportEvents');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();

        let combinedData = [];

        if (Array.isArray(result.data.sportsEventModelToday)) {
          combinedData.push(...result.data.sportsEventModelToday);
        }

        if (Array.isArray(result.data.sportsEventModelTomorrow)) {
          combinedData.push(...result.data.sportsEventModelTomorrow);
        }

        // console.log("Enter in function ....", combinedData.flat())
        const flattenedArray = combinedData.flat()
        setData(flattenedArray);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Optionally, you can set some default data or show a message to the user
      }
    };


    fetchData();

    // Fetch data every 1 minute (60000 milliseconds)
    // const intervalId = setInterval(fetchData, 5000);

    // return () => clearInterval(intervalId);
  }, []);

  const handleEvent = (eid, gameId) => {
    navigate(`/event/${eid}/${gameId}`);
  }

  const sendLock = (eid, gameId, marketId, isLock, isMO, isBM, isFancy) => {
  //  console.log("is lock is : ", isLock)
    // setIsBetlock(isLock)
    navigate(`/EVENT/${eid}/${gameId}/${marketId}`, { state: { isBetlock: isLock, isMO, isBM, isFancy } });
  }

  return (
    <>
      <div className="container body">
        <input type="hidden" id="cricketCount" />
        <input type="hidden" id="tennisCount" />
        <input type="hidden" id="soccerCount" />
        <div className="main_container" id="sticky">
          <div className="right_col" role="main">
            <div className="fullrow tile_count">
              <div className="col-md-7">
                <div id="UpCommingData">
                  <div
                    className="sport-highlight-content tabs"
                    id="accountView"
                    role="main"
                  >
                    <div className="casinobg">
                      <span>
                        <Link className="blinking">
                          {" "}
                          Live games
                        </Link>
                      </span>
                    </div>
                    <ul className="nav nav-tabs match-buttons">
                      <li
                        className="active tabcricket hidden-tabs"
                        data-tabname="cricket"
                      >
                        <a data-toggle="tab" href="#cricket" className="tabid4">
                          <span className="game_label"> Cricket</span>
                        </a>
                      </li>
                      <li data-tabname="tennis" className="hidden-tabs">
                        <a data-toggle="tab" href="#tennis" className="tabid2">
                          <span className="game_label"> Tennis</span>
                        </a>
                      </li>
                      <li data-tabname="soccer" className="hidden-tabs">
                        <a data-toggle="tab" href="#soccer" className="tabid1">
                          <span className="game_label"> Soccer</span>
                        </a>
                      </li>
                      <li className="hidden-xs">
                        <Link to={"/inPlay"} className="blinking">
                          {/* <a className="blinking" href="/inPlay"> */}
                          <span className="game_label"> Inplay</span>
                          {/* </a> */}
                        </Link>
                      </li>
                      <li data-tabname="casino" className="hidden-tabs">
                        <a href="#" className="tabid1 blinking">
                          <span className="game_label"> Casino</span>
                        </a>
                      </li>
                    </ul>
                    <div className="modal-body tab-content matches-all">
                      <span id="msg_error" />
                      <span id="errmsg" />
                      <div
                        className="table table-striped jambo_table bulk_action"
                        id=""
                      >
                        <div className="clearfix" />
                        <div id="cricket" className="tab-pane fade in active">
                          <div id="user_row_" className="lotus-title sportrow-4">
                            <div className="head-matchname">
                              <div className="match-head">Cricket</div>
                              <div className="match-odds-right">
                                <div className="items-up-odds">1</div>
                                <div className="items-up-odds">x</div>
                                <div className="items-up-odds">2</div>
                              </div>
                            </div>
                          </div>
                          {data.length > 0 && data.map((item) => {
                           //console.log('Jsx enters.....', data)
                            return (
                              item.eid == '4' && (
                                <div
                                  className="sport_row "
                                  onclick="navigate('32882829','4');"
                                >
                                  <div className="sport_name">
                                    <Link onClick={(e) => {
                                      e.preventDefault();
                                      sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                    }}>
                                      {item.eventName}
                                    </Link>
                                    <time>
                                      <i className="fa fa-clock-o" />
                                      {format(parseISO(item.eventDate), "dd MMM HH:mm:ss")}
                                    </time>
                                    <span>
                                      <i className="fa fa-star-o" aria-hidden="true" />
                                    </span>
                                  </div>
                                  <div className="match_status">
                                    <span className="going_inplay">Going In-play</span>
                                  </div>
                                  <div className="match_odds_front">
                                    <span
                                      className="back-cell odds32882829"
                                      id="back3288282916606"
                                    >
                                      {item.back11}
                                    </span>
                                    <span
                                      className="lay-cell odds32882829"
                                      id="lay3288282916606"
                                    >
                                      {item.lay11}
                                    </span>
                                    <span
                                      className="back-cell odds32882829"
                                      id="back328828297461"
                                    >
                                      {item.back1}
                                    </span>
                                    <span
                                      className="lay-cell odds32882829"
                                      id="lay328828297461"
                                    >
                                      {item.lay1}
                                    </span>
                                    <span
                                      className="back-cell odds32882829"
                                      id="back3288282960443"
                                    >
                                      {item.back12}
                                    </span>
                                    <span
                                      className="lay-cell odds32882829"
                                      id="lay3288282960443"
                                    >
                                      {item.lay12}
                                    </span>
                                  </div>
                                </div>
                              ))
                          })}
                        </div>
                        {/* Tennis */}
                        <div className="clearfix" />
                        <div id="tennis" className="tab-pane fade">
                          <div id="user_row_" className="lotus-title sportrow-4">
                            <div className="head-matchname">
                              <div className="match-head">Tennis</div>
                              <div className="match-odds-right">
                                <div className="items-up-odds">1</div>
                                <div className="items-up-odds">x</div>
                                <div className="items-up-odds">2</div>
                              </div>
                            </div>
                          </div>

                          {data.map((item) => (
                            item.eid === '2' && (
                              <div
                                className="sport_row "
                              >
                                <div className="sport_name">
                                  <Link onClick={(e) => {
                                    e.preventDefault();
                                    sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                  }}>
                                    {item.eventName}
                                  </Link>
                                  <time>
                                    <i className="fa fa-clock-o" />
                                    {format(parseISO(item.eventDate), "dd MMM HH:mm:ss")}
                                  </time>
                                  <span>
                                    <i className="fa fa-star-o" aria-hidden="true" />
                                  </span>
                                </div>
                                <div className="match_status">
                                  <span className="going_inplay">Going In-play</span>
                                </div>
                                <div className="match_odds_front">
                                  <span
                                    className="back-cell odds32882829"
                                    id="back3288282916606"
                                  >
                                    {item.back11}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay3288282916606"
                                  >
                                    {item.lay11}
                                  </span>
                                  <span
                                    className="back-cell odds32882829"
                                    id="back328828297461"
                                  >
                                    {item.back1}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay328828297461"
                                  >
                                    {item.lay1}
                                  </span>
                                  <span
                                    className="back-cell odds32882829"
                                    id="back3288282960443"
                                  >
                                    {item.back12}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay3288282960443"
                                  >
                                    {item.lay12}
                                  </span>
                                </div>
                              </div>
                            )))}
                        </div>
                        {/* Soccer */}
                        <div className="clearfix" />
                        <div id="soccer" className="tab-pane fade">
                          <div id="user_row_" className="lotus-title sportrow-4">
                            <div className="head-matchname">
                              <div className="match-head">Soccer</div>
                              <div className="match-odds-right">
                                <div className="items-up-odds">1</div>
                                <div className="items-up-odds">x</div>
                                <div className="items-up-odds">2</div>
                              </div>
                            </div>
                          </div>
                          {data.map((item) => (
                            item.eid === '1' && (
                              <div
                                className="sport_row "
                              >
                                <div className="sport_name">
                                  <Link onClick={(e) => {
                                    e.preventDefault();
                                    sendLock(item.eid, item.gameId, item.marketId, item.IsBetLock, item.Matchodds, item.BookMaker, item.Fancy)
                                  }}>
                                    {item.eventName}
                                  </Link>
                                  <time>
                                    <i className="fa fa-clock-o" />
                                    {format(parseISO(item.eventDate), "dd MMM HH:mm:ss")}
                                  </time>
                                  <span>
                                    <i className="fa fa-star-o" aria-hidden="true" />
                                  </span>
                                </div>
                                <div className="match_status">
                                  <span className="going_inplay">Going In-play</span>
                                </div>
                                <div className="match_odds_front">
                                  <span
                                    className="back-cell odds32882829"
                                    id="back3288282916606"
                                  >
                                    {item.back11}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay3288282916606"
                                  >
                                    {item.lay11}
                                  </span>
                                  <span
                                    className="back-cell odds32882829"
                                    id="back328828297461"
                                  >
                                    {item.back1}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay328828297461"
                                  >
                                    {item.lay1}
                                  </span>
                                  <span
                                    className="back-cell odds32882829"
                                    id="back3288282960443"
                                  >
                                    {item.back12}
                                  </span>
                                  <span
                                    className="lay-cell odds32882829"
                                    id="lay3288282960443"
                                  >
                                    {item.lay12}
                                  </span>
                                </div>
                              </div>
                            )))}

                        </div>
                        <div id="casino" className="tab-pane fade">
                          <div id="user_row_" className="lotus-title sportrow-4">
                            <div className="head-matchname">
                              <div className="match-head">Live Casino</div>
                            </div>
                          </div>
                          <div
                            className="col-lg-6 col-xs-6"
                            style={{
                              padding: 0,
                              border: "5px solid #fff",
                              cursor: "pointer"
                            }}
                          >
                            <Link to={"/Casino"}>
                              <img src="img/cas1.webp" style={{ width: "100%" }} />
                            </Link>
                          </div>
                          <div
                            className="col-lg-6 col-xs-6"
                            style={{ padding: 0, border: "5px solid #fff" }}
                          >
                            <Link to={"/casino2user"}>
                              <img src="img/cas2.webp" style={{ width: "100%" }} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="MatchOddInfo" />
              </div>
              <div className="col-md-5 col-xs-12 matchBox">
                <div className="other-items">
                  <div className="balance-box">
                    <div className="panel-heading">
                      <h3 className="bal-tittle">Mini Games</h3>
                      <span className="pull-right clickable">
                        <i className="fa fa-chevron-down" />
                      </span>
                    </div>
                    <div className="balance-panel-body" />
                  </div>
                </div>
                {/*- Match UnMatch data -*/}
              </div>
            </div>
          </div>
          {/* /page content */}
          {/*footerlinks*/}
          <Footer />
          {/*commanpopup*/}
          {/*commanpopup*/}
        </div>
      </div>
    </>
  )
}
